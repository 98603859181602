import {
  Box,
  Container,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  Button,
} from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FacebookIcon, TwitterIcon, YoutubeIcon } from "../../assets/SVG";
import { useSelector } from "react-redux";

const Footer = () => {
  const { company } = useSelector((state) => state.isCompany);
  const navigate = useNavigate();
  // console.log(company);
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,

        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
            mb: 1,
          }}
        >
          <Typography variant="body1" color="primary">
            Click Here Discover{" "}
            <Typography
              color="primary"
              variant="body1"
              component={"span"}
              sx={{ cursor: "pointer" }}
              fontWeight={700}
              onClick={() => window.open("https://apnathali.com/")}
            >
              Apna Thali
            </Typography>
            &nbsp; App and more...
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Tooltip title="Facebook" arrow>
              <IconButton
                aria-label=""
                onClick={() => {
                  let url = company?.facebook;
                  if (url && !url.startsWith("https://")) {
                    url = `https://${url}`;
                  }

                  window.open(url || "https://apnathali.com/");
                }}
              >
                {FacebookIcon}
              </IconButton>
            </Tooltip>{" "}
            <Tooltip title="Twitter" arrow>
              <IconButton
                aria-label=""
                onClick={() => {
                  let url = company?.twitter;
                  if (url && !url.startsWith("https://")) {
                    url = `https://${url}`;
                  }

                  window.open(url || "https://apnathali.com/");
                }}
              >
                {" "}
                {TwitterIcon}
              </IconButton>{" "}
            </Tooltip>{" "}
            <Tooltip title="Youtube" arrow>
              <IconButton
                aria-label=""
                onClick={() => {
                  let url = company?.youtube;
                  if (url && !url.startsWith("https://")) {
                    url = `https://${url}`;
                  }

                  window.open(url || "https://apnathali.com/");
                }}
              >
                {" "}
                {YoutubeIcon}
              </IconButton>{" "}
            </Tooltip>
          </Box>
        </Box>
        <Divider
          sx={{ width: "100%", color: "primary.main", bgcolor: "primary.main" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: 2,
            mt: 4,
          }}
        >
          <Copyright />
          <Box
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              component="img"
              src={
                company?.footer_logo?.startsWith("https")
                  ? company?.footer_logo
                  : `${process.env.REACT_APP_URI}/${company?.footer_logo}`
              }
              alt="Brand"
              height="6rem"
              borderRadius="50%"
              onError={(e) => {
                e.onError = null;
                e.target.src = require("../../assets/images/LOGO APNA THALI 2 conflict-02 1.png");
              }}
            />

            {/* <Box width={"100%"} mt={1}>
              <Typography
                component="h5"
                fontWeight={800}
                color={"primary.main"}
              >
                {`Apna Thali`}
              </Typography>
            </Box> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                color="primary"
                sx={{ textTransform: "capitalize" }}
                // component={Link}
                // to="/policy"
                onClick={() => {
                  navigate("/policy", { state: company?.term_condition });
                }}
              >
                Terms of Service
              </Button>
              <Button
                variant="text"
                color="primary"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  navigate("/policy", { state: company?.privacy_policy });
                }}
              >
                Privacy Policy
              </Button>
            </Box>
            <Tooltip title="Play Store Link" arrow>
              <Box
                onClick={() => {
                  let url = company?.play_store;
                  if (url && !url.startsWith("https://")) {
                    url = `https://${url}`;
                  }

                  window.open(url || "https://apnathali.com/");
                }}
                component="img"
                src={require("../../assets/images/playStore.png")}
                alt="play-Stor-Log"
                width="7.5rem"
                sx={{ cursor: "pointer" }}
                onError={(e) => {
                  e.onError = null;
                  e.target.src = require("../../assets/images/playStore.png");
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {" © "}
      {new Date().getFullYear()}
      <a
        href="https://apnathali.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;Apna Thali.
      </a>{" "}
      All rights reserved {"."}
    </Typography>
  );
}
