import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Member", "Attendance", "Notification"],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getMemberByAdminId: builder.query({
      query: (adminId) => `get/all/Member/${adminId}`,
      providesTags: ["Member"],
    }),
    getMemberPhone: builder.query({
      query: ({ phoneNumber, restroId }) =>
        `check/member/by/phone/${restroId}?phone=${phoneNumber}`,
      // providesTags: ["Member"],
    }),

    getMemberByRestId: builder.query({
      query: ({ restroId, status, sort, plan, search, page }) =>
        `get/all/ShallowMember/${restroId}?status=${status}&sort=${
          sort || ""
        }&plan=${plan || ""}&search=${search || ""}&page=${page || ""}`,
      providesTags: ["Member", "Attendance"],
      keepUnusedDataFor: 5,
    }),
    getMemberByMemberId: builder.query({
      query: ({ memberId }) => `getShallowMember/${memberId}`,
      providesTags: ["Attendance", "Member"],
      // keepUnusedDataFor: 5,
    }),
    getMemberCountByRestId: builder.query({
      query: ({ restroId }) => `get/Member/count/${restroId}`,
      providesTags: ["Member", "Attendance"],
      // keepUnusedDataFor: 5,
    }),
    getFilterByRestId: builder.query({
      query: ({ restroId }) => `get/sort/or/plan/names/${restroId}`,
    }),
    getAttendanceByMemberId: builder.query({
      query: ({ memberId, filter }) =>
        `get/all/Attendance/${memberId}?filter=${filter || ""}`,
      providesTags: ["Member", "Attendance"],
    }),

    updateMarkAttendance: builder.mutation({
      query: ({ mark, memberId, restId, adminId }) => ({
        url: `mark/UnMark/Attendance/by/user/${restId}/${adminId}/${memberId}?mark=${mark}`,
        method: "POST",
      }),
      invalidatesTags: ["Member", "Attendance"],
      // add there singleMember muitaions
      // async onQueryStarted(args, { queryFulfilled, dispatch }) {
      //   try {
      //     const { data: updatedAttendace } = await queryFulfilled;

      //     dispatch(
      //       memberApi.util.updateQueryData(
      //         "getMemberByRestId",
      //         undefined,
      //         (draft) => {
      //           let attendance = draft?.find((item) => item?._id === args?._id);

      //           attendance.attendaceMarkOne =
      //             updatedAttendace?.attendaceMarkOne;
      //           attendance.attendaceMarkTwo =
      //             updatedAttendace?.attendaceMarkTwo;
      //         }
      //       )
      //     );

      //     // dispatch(
      //     //     apiSlice.util.updateQueryData('getSingleProject', args.id, (draft) => {

      //     //         let project = draft;

      //     //         Object.assign(project, {
      //     //             id: updatedProject?.id,
      //     //             thumbnail: updatedProject?.thumbnail,
      //     //             title: updatedProject?.title,
      //     //             category: updatedProject?.category,
      //     //             description: updatedProject?.description,
      //     //             demo: updatedProject?.demo

      //     //         })

      //     //     })
      //     // )
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),
    createMember: builder.mutation({
      query: ({ data, adminId, restroId }) => ({
        url: `create/restaurant/member/${restroId}/${adminId}`,
        // url: `create/shallowMember/${restroId}/${adminId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Member"],
    }),

    updateMember: builder.mutation({
      query: ({ restroId, memberId, data }) => ({
        url: `update/shallowMember/${memberId}/${restroId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Member"],
    }),
    updateMemberRenewalPlan: builder.mutation({
      query: ({ restroId, memberId, data }) => ({
        url: `renewal/plan/by/user/${restroId}/${memberId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Member"],
    }),
    addAmount: builder.mutation({
      query: ({ amount, memberId }) => ({
        url: `add/payment/${memberId}?amount=${amount}`,
        method: "POST",
      }),
      invalidatesTags: ["Member"],
    }),
    addHoliday: builder.mutation({
      query: ({ memberId, data }) => ({
        url: `extend/days/${memberId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Member"],
    }),
    deleteMember: builder.mutation({
      query: ({ memberId }) => ({
        url: `disable/shallowMember/${memberId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Member"],
    }),
  }),
});

export const {
  useGetMemberByAdminIdQuery,
  useGetMemberByRestIdQuery,
  useUpdateMarkAttendanceMutation,
  useGetMemberPhoneQuery,
  useCreateMemberMutation,
  useGetMemberCountByRestIdQuery,
  useGetFilterByRestIdQuery,
  useGetMemberByMemberIdQuery,
  useGetAttendanceByMemberIdQuery,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useUpdateMemberRenewalPlanMutation,
  useAddAmountMutation,
  useAddHolidayMutation,
} = memberApi;
