import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUpdateMarkAttendanceMutation } from "../../Apis/reduxRTK/memberApi";
import { handleAlert } from "../../Apis/features/alertSlice";
import { useDispatch } from "react-redux";
import { handleMemberModelDetails } from "../../Apis/features/memberSlice";
import {
  handleIsMemberPlanData,
  handleRenewalPlanModel,
} from "../../Apis/features/planSlice";
import { handleUpdateModelDetails } from "../../Apis/features/modalSlice";
// import { formatDate } from "../../extras/dataFunction";

const MemberCard = ({ value, rate, isFetching }) => {
  // console.log(value);
  const [isChecked, setIsChecked] = useState({
    one: value?.attendaceMarkOne || false,
    two: value?.attendaceMarkTwo || false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setIsChecked((prev) => ({
      ...prev,
      one: value?.attendaceMarkOne,
    }));
  }, [value?.attendaceMarkOne]);

  useEffect(() => {
    setIsChecked((prev) => ({
      ...prev,
      two: value?.attendaceMarkTwo,
    }));
  }, [value?.attendaceMarkTwo]);
  const [markAttendance] = useUpdateMarkAttendanceMutation();
  const handleChangeCheck = async (e) => {
    // console.log(e.target.checked);
    const mark = e.target.checked;
    console.log(value,'value')
    try {
      const res = await markAttendance({
        mark: `${mark}`,
        memberId: value?.currentPlanId?.shallowMemberId,
        restId: value?.currentPlanId?.restaurantId,
        adminId: localStorage.getItem("authId"),
      });
      // console.log(res);
      if (!res?.data?.success) {
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: res?.data?.message,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        p: 0.5,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        overflow: "visible",
        cursor: "pointer",
        border: 0.2,
        borderColor: "gray.gray2",
        flexDirection: { sm: "column", md: "row", xl: "row" },
        borderRadius: 3,
        "&:hover": {
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(
          handleMemberModelDetails({ show: true, memberId: value?._id })
        );
      }}
    >
      <Box
        sx={{
          display: "flex",
          // alignItems: "center",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 1,
          width: { sm: "100%", md: "auto", xl: "auto" },
          flex: 1,
        }}
      >
        <img
          src={`${process.env.REACT_APP_URI}/${value?.photo}`}
          onError={(e) => {
            e.onerror = null;
            e.target.src = require("../../assets/images/profile.png");
          }}
          style={{
            width: "5.5rem",
            // width:,
            height: "6.5rem",
            borderRadius: "12px",
            objectFit: "cover",
          }}
          alt="......"
        />

        <CardContent
          style={{ padding: 0 }}
          sx={{
            width: "auto",
          }}
        >
          {rate ? (
            <Typography
              gutterBottom
              fontSize={18}
              variant="body1"
              component="div"
              sx={{
                color: "black.main",
                fontWeight: 600,

                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.name}
            </Typography>
          ) : (
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                color: "black.main",
                fontWeight: 600,

                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.name}
            </Typography>
          )}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <img
              src={require("../../assets/images/tahliSmall.png")}
              style={{
                width: "1.8rem",
                height: "1.8rem",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt="......"
            />
            {console.log(value, "value")}
            {value?.anyExpiredPlan ? (
              <Typography variant="body2" color="red" fontWeight={500}>
                Plan Expired
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                {value?.currentPlanId?.thaliCount || 0} Remaining
              </Typography>
            )}

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: 0.1,
              }}
            >
              {" "}
              {!value?.daysRemaining ? (
                <Typography variant="caption" color="red" fontWeight={500}>
                  Plan Expired
                </Typography>
              ) : value?.daysRemaining <= 5 ? (
                <Typography variant="body1" color="red">
                  {" "}
                  {` Plan expire in ${value?.daysRemaining} days`}{" "}
                </Typography>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  {" "}
                  {` Plan expire in ${formatDate(
                    value?.currentPlanId?.endDate
                  )}`}{" "}
                </Typography>
              )}
              {value?.currentPlanId?.thaliCount && (
                <Typography variant="body1" color="text.secondary">
                  {value?.currentPlanId?.thaliCount || 0} Thali Remaining
                </Typography>
              )}
            </Box> */}
          {/* </Box> */}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "0.8rem" }}
          >
            Id: {value?.id}
          </Typography>
          {value?.currentPlanId?.thaliCount ? (
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              sx={{
                display: "flex",
                fontSize: "0.8rem",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {" "}
              {/* <img
                src={require("../../assets/images/tahliSmall.png")}
                style={{
                  width: "1.8rem",
                  height: "1.8rem",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                alt="......"
              /> */}
              Thali Count: {value?.currentPlanId?.thaliCount}
            </Typography>
          ) : (
            (value?.anyExpiredPlan || value?.currentPlanId?.thaliCount < 5) && (
              <Typography variant="body2" color="red" fontWeight={500}>
                Plan Expired
              </Typography>
            )
          )}
          {value?.dueAmount ? (
            <Typography variant="body2" color="red" sx={{ fontSize: "0.8rem" }}>
              Due Amount: {value?.dueAmount}
            </Typography>
          ) : null}
        </CardContent>
      </Box>

      {value?.anyExpiredPlan || value?.currentPlanId?.thaliCount < 5 ? (
        <CardActions style={{ padding: 5 }}>
          <Button
            variant="contained"
            sx={{
              color: "white.main",
              bgcolor: "primary.main",
              "&:hover": {
                color: "white.main",
                bgcolor: "primary.main",
              },
            }}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(handleRenewalPlanModel(true));
              dispatch(handleUpdateModelDetails(value));
              dispatch(handleIsMemberPlanData(value));
            }}
          >
            Renewal Plan
          </Button>
        </CardActions>
      ) : value?.currentPlanId ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant={rate ? "body1" : "h6"}
            color="text.secondary"
            fontWeight={300}
            px={rate ? 1 : 2}
            textAlign={"center"}
            fontSize={12.5}
          >
            Mark Attendance
          </Typography>
          <CardActions style={{ padding: 0 }}>
            <Checkbox
              checked={isChecked.one}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 }, p: 0 }}
              onChange={(e) => {
                handleChangeCheck(e);
                const mark = e.target.checked;
                setIsChecked((prev) => ({ ...prev, one: mark }));
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <Checkbox
              checked={isChecked.two}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 }, p: 0 }}
              onChange={(e) => {
                handleChangeCheck(e);
                const mark = e.target.checked;
                setIsChecked((prev) => ({ ...prev, two: mark }));
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </CardActions>
        </Box>
      ) : null}
    </Card>
  );
};

export default MemberCard;
