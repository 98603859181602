import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  // tagTypes: ["Admin"],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getAdmin: builder.query({
      query: () => `getUser/${localStorage.getItem("authId")}`,
      // providesTags: ["Admin"],
      // keepUnusedDataFor: 5,
    }),

    updateAdmin: builder.mutation({
      query: ({ data }) => ({
        url: `update/user/${localStorage.getItem("authId")}`,
        method: "PUT",
        body: data,
      }),
      // invalidatesTags: ["Admin"],
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedData } = await queryFulfilled;

          dispatch(
            adminApi.util.updateQueryData("getAdmin", undefined, (draft) => {
              if (draft) {
                draft.data = updatedData?.data;
              }
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useGetAdminQuery, useUpdateAdminMutation } = adminApi;
