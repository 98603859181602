import React from "react";
import SkLoading from "../../components/SkError/SkLoading";
import { Box,  Paper, Typography } from "@mui/material";
import {
  useDeletePlanMutation,
  useGetPlansQuery,
} from "../../Apis/reduxRTK/planApi";
import { useDispatch, useSelector } from "react-redux";
import PlanCard from "../../components/Cards/PlanCard";
// import BasicModal from "../../components/Modal";
import { handlePlanModel } from "../../Apis/features/planSlice";
import SkConfirm from "../../components/SkConfirm";
// import NewPlan from "./NewPlan";
import { handleAlert } from "../../Apis/features/alertSlice";

const Plans = () => {
  const { singleRestaurant } = useSelector((state) => state.isRestaurant);
  const { planId, isPlanDelete } = useSelector((state) => state.isPlan);
  const dispatch = useDispatch();
  // console.log(isPlanShow, "isPlanShow");

  const [deletePlan] = useDeletePlanMutation();
  const {
    data: plans,
    isLoading,
    isSuccess,
    error,
  } = useGetPlansQuery({ restroId: singleRestaurant?._id });

  if (error) {
    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="primary" fontWeight={600}>
            {" "}
            something went wrong
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (isLoading) {
    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <SkLoading />
      </Paper>
    );
  }

  // console.log(plans, "plans");

  return (
    isSuccess && (
      <Paper
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          alignContent: "flex-start",
          // height: "100%",
          // flexDirection: "column",

          height: "100vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // minHeight: "80vh",
        }}
      >
        {plans && plans?.data && plans?.data?.length > 0 ? (
          plans?.data?.map((value, index) => (
            <PlanCard value={value} key={index} />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              p: 1,
              height: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={require("../../assets/images/planNotFound.png")}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt="..."
            />
          </Box>
        )}
        {/* <Box sx={{ display: "flex", }} /> */}
        <SkConfirm
          dialogTitle={"Plan"}
          dialogContent={"Are you sure you want to delete plan ?"}
          dialogAction={async () => {
            try {
              const res = await deletePlan({
                planId: planId,
              });
              dispatch(
                handleAlert({
                  isOpen: true,
                  type: res?.data?.success ? "success" : "error",
                  msg: res?.data?.message,
                })
              );
              // console.log(res, "Delete");
            } catch (error) {
              dispatch(
                handleAlert({
                  isOpen: true,
                  type: "error",
                  msg: "something went wrong !",
                })
              );
            } finally {
              dispatch(handlePlanModel({ delete: false }));
            }
          }}
          open={isPlanDelete}
          setOpen={() => dispatch(handlePlanModel({ delete: false }))}
        />
      </Paper>
    )
  );
};

export default Plans;
