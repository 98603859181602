import { createSlice } from "@reduxjs/toolkit";

const memberSlice = createSlice({
  name: "member",
  initialState: {
    isMemberShow: false,
    isMemberDelete: false,
    memberId: false,
    title: false,
    members: null,
    member: null,
    isMemberId: null,
    isDetails: false,
  },
  reducers: {
    handleMemberModel: (state, action) => {
      state.isMemberShow = action.payload.show;
      state.title = action.payload.title;
      state.isMemberDelete = action.payload.delete;
      state.memberId = action.payload.memberId;
    },
    getSingleMember: (state, action) => {
      state.member = action.payload;
    },

    handleMemberModelDetails: (state, action) => {
      state.isDetails = action.payload.show;
      state.isMemberId = action.payload.memberId;
    },
  },
});

export const {
  handleMemberModel,
  getSingleMember,

  handleMemberModelDetails,
} = memberSlice.actions;
export default memberSlice.reducer;
