import React from "react";
import { AppBar, Container, Toolbar, Box, Skeleton } from "@mui/material";

const SkeletonAppBar = () => {
  return (
    <AppBar
      elevation={1}
      position="sticky"
      sx={{
        "&.MuiAppBar-root": {
          backgroundColor: "transparent",
          color: "primary.main",
        },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
              py: 0.5,
            }}
          >
            <Skeleton variant="circular" width={55} height={55} />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 0.5,
                flexDirection: "column",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Skeleton variant="circular" width={24} height={24} />
                &nbsp;
                <Skeleton variant="text" width={100} height={20} />
              </Box>
              <Skeleton variant="text" width={100} height={20} />
            </Box>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flexGrow: 0, gap: 1.5, display: "flex" }}>
            <Skeleton variant="rectangular" width={120} height={36} />
            <Skeleton variant="circular" width={36} height={36} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default SkeletonAppBar;
