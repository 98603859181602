import {
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { PlansIcon2 } from "../../assets/SVG";
import { handlePlanModel, removeData } from "../../Apis/features/planSlice";
import { useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SkMenu = ({
  handleChange,
  values,
  data,
  handleBlur,
  touched,
  errors,
  apiError,
  name,
  disabled,
}) => {
  const dispatch = useDispatch();
  return (
    <Select
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      value={values}
      onChange={handleChange}
      input={
        <TextField
          size="small"
          select
          required
          autoComplete="off"
          variant="outlined"
          onBlur={handleBlur}
          disabled={disabled}
          value={values}
          name={name}
          error={(!!touched && !!errors) || !!apiError}
          helperText={(touched && errors) || apiError}
          label="Select Plan"
          sx={{
            "& .MuiInputBase-root": {
              //   height: 40,
              border: "none",
              outline: "none",
              borderColor: "GrayText",
              bgcolor: "rgba(221, 218, 218, 0.15)",
              color: "black.main",
            },
            "& .MuiFormHelperText-root": {
              mx: 0,
            },
            "& .css-tlelie-MuiListItemText-root": {
              my: 0,
            },
            // "& .MuiOutlinedInput-notchedOutline": {
            //   outline: "none",
            //   border: "none",
            // },
          }}
          InputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
        />
      }
      //   renderValue={(selected) => selected.join(", ")}
      MenuProps={MenuProps}
    >
      <MenuItem
        value={""}
        onClick={() => {
          dispatch(removeData(null));
        }}
      >
        none
      </MenuItem>
      {data && data.length > 0 ? (
        data.map((name, index) => (
          <MenuItem
            key={index}
            value={name?._id}
            onClick={() => {
              dispatch(handlePlanModel({ data: name }));
            }}
          >
            <ListItemText primary={name?.planName} />
          </MenuItem>
        ))
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 0.5,
            p: 1.5,
          }}
        >
          <Typography
            variant="button"
            color="initial"
            textAlign={"center"}
            textTransform={"capitalize"}
            fontWeight={600}
          >
            No plans? Let's add a new Plan!
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={PlansIcon2}
            onClick={() => {
              dispatch(handlePlanModel({ show: true, title: false }));
            }}
          >
            add new plan
          </Button>
        </Box>
      )}
    </Select>
  );
};

export default SkMenu;
