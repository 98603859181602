import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../examples/Heading";
import { useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  useDeleteMemberMutation,
  useUpdateMemberMutation,
} from "../../Apis/reduxRTK/memberApi";
import { handleAlert } from "../../Apis/features/alertSlice";
import axios from "axios";
import { handleUpdateModel } from "../../Apis/features/modalSlice";
import { handleMemberModelDetails } from "../../Apis/features/memberSlice";

const UpdateMember = ({ member }) => {
  const { isMemberId } = useSelector((state) => state.isMember);
  const { isUpdateDetails } = useSelector((state) => state.isModal);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [apiError, setApiError] = useState(null);
  // const [phoneNumber, setPhoneNumber] = useState(null);
  // console.log(isUpdateDetails, "isUpdateDetailsup");
  const dispatch = useDispatch();

  const [updateMember] = useUpdateMemberMutation();
  const [deleteMenber] = useDeleteMemberMutation();

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),

    name: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )

      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),

    email: yup
      .string()

      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
  });
  const initialValues = {
    phone: isUpdateDetails?.phone || "",
    name: isUpdateDetails?.name || "",
    email: isUpdateDetails?.email || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("Values", values);

      setIsSubmitting(true);
      try {
        const formData = new FormData();

        formData.append("phone", values?.phone.toString());
        formData.append("name", values?.name);
        formData.append("email", values?.email);
        formData.append("photo", images);

        const res = await updateMember({
          data: formData,
          adminId: localStorage.getItem("authId"),
          restroId: isUpdateDetails?.restaurantId,
          memberId: isUpdateDetails?._id,
        });
        // console.log(res, "ress");
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(handleUpdateModel(false));
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    },
  });

  const handleMobleSearch = async (e) => {
    const phone = e.target.value;
    if (phone?.length === 10) {
      // getMobileDetails({
      //   id: singleRestaurant?._id,
      //   phone: phone,
      //   dispatch: dispatch,
      // });
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API}/check/member/by/phone/${isUpdateDetails?.restaurantId}?phone=${phone}`,
          config
        );

        if (res?.data?.success) {
          formik.setFieldError("phone", "Phone number is already use");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log(error, "planByID");
      }
    }
  };

  const handleMemberDelete = async () => {
    setIsSubmitting2(true);
    try {
      const res = await deleteMenber({
        memberId: isUpdateDetails?._id,
      });
      // console.log(res, "ress");
      setIsSubmitting2(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: res?.data?.success ? "success" : "error",
          msg: res?.data?.message,
        })
      );
      if (res?.data?.success) {
        dispatch(handleUpdateModel(false));
        dispatch(handleMemberModelDetails({ show: false }));
      }
      if (res?.error) {
        setApiError(res?.error?.data?.message);
        setTimeout(() => {
          setApiError(null);
        }, 5000);
      } else {
        setApiError(null);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting2(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          color: "primary.main",
          p: 2.5,
          gap: 1,
          overflowY: "scroll",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Heading
          text={"Update Member"}
          color="primary.main"
          fontSize={"1.5rem"}
        />
        <Box
          sx={{
            mt: 0.6,
            height: "5.5rem",
            width: "5.5rem",
            // border: 2,
            // borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            my: 1.5,
          }}
        >
          {isUploading ? (
            <Avatar
              sx={{
                height: "5.5rem",
                width: "5.5rem",
                border: 2,
                borderColor: "primary.main",
                background: "transparent",
              }}
            >
              <CircularProgress color="primary" />
            </Avatar>
          ) : (
            <>
              <label htmlFor="imageuploader">
                {" "}
                <img
                  src={require("../../assets/images/edit2.png")}
                  alt="edit button"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    position: "absolute",
                    bottom: "0",
                    right: "0 ",
                    cursor: "pointer",
                    borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              </label>
              <input
                // required
                type="file"
                accept="image/*"
                hidden
                onChange={handleSelectImage}
                id={"imageuploader"}
              />
              <Avatar
                sx={{
                  height: "5.5rem",
                  width: "5.5rem",
                  border: 2,
                  borderColor: "primary.main",
                }}
                src={
                  previewImage
                    ? previewImage
                    : isUpdateDetails
                    ? `${process.env.REACT_APP_URI}/${isUpdateDetails?.photo}`
                    : null
                }
              />
            </>
          )}
        </Box>
        <input
          // required
          type="file"
          accept="image/*"
          hidden
          onChange={handleSelectImage}
          id={"imageuploader"}
        />

        <Box
          sx={{
            width: "90%",
            gap: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "100%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"number"}
              required
              autoComplete="off"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={(e) => {
                handleMobleSearch(e);
                formik.handleBlur(e);
              }}
              value={formik.values.phone}
              name="phone"
              error={
                (!!formik.touched.phone && !!formik.errors.phone) || !!apiError
              }
              helperText={
                (formik.touched.phone && formik.errors.phone) || apiError
              }
              label="Phone"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },

                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"text"}
              required
              disabled={Loading}
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
              error={
                (!!formik.touched.name && !!formik.errors.name) || !!apiError
              }
              helperText={
                (formik.touched.name && formik.errors.name) || apiError
              }
              label="Full Name"
              sx={{
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"email"}
              required
              disabled={Loading}
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              error={
                (!!formik.touched.email && !!formik.errors.email) || !!apiError
              }
              helperText={
                (formik.touched.email && formik.errors.email) || apiError
              }
              label="Email"
              sx={{
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },

                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: "90%",
            textTransform: "capitalize",
            mt: 2,
            height: "2.8rem",
            borderRadius: 10,
            bgcolor: "primary.main",
            color: "white.main",
            boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              bgcolor: "primary.main",
              color: "white.main",
            },
          }}
          disabled={
            isSubmitting ||
            formik.errors.phone ||
            formik.errors.name ||
            formik.errors.email
          }
        >
          {isSubmitting ? "Submitting....." : "Submit"}
        </Button>
        <Button
          variant="outlined"
          sx={{
            width: "90%",
            textTransform: "capitalize",
            mt: 2,
            height: "2.8rem",
            borderRadius: 10,
            "&:hover": {
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
            },
          }}
          onClick={handleMemberDelete}
        >
          {isSubmitting2 ? "Deleting....." : "Delete Account"}
        </Button>
      </Box>
    </form>
  );
};

export default UpdateMember;
