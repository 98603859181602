import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { RemainingDay, formatTimeByDate } from "../extras/dataFunction";

const AttendanceTable = ({ data, rate }) => {
  // console.log(data);
  return (
    <TableContainer
      component={Paper}
      sx={{
        "& .MuiTableCell-root": {
          p: 1,
        },
        width: "100%",
        height: "100%",
      }}
    >
      <Table
        aria-label="customized table"
        sx={{ width: "100%", height: "100%" }}
      >
        <TableHead
          sx={{ bgcolor: "rgba(238, 238, 242, 1)", color: "black.main" }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                // borderColor: "white.main",
                // color: "white.main",
                fontSize: 15,
              }}
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: 15,
              }}
            >
              Status
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: 15,
              }}
            >
              Mark By
            </TableCell>
            <TableCell
              align="center"
              sx={{
                fontSize: 15,
              }}
            >
              Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.length > 0
            ? rate
              ? data?.map((value, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {RemainingDay(value?.createdAt)}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        color:
                          value?.log === "unMark" ? "primary.main" : "#15E01D",
                        textTransform: "capitalize",
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      {value?.log}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {value?.markBy}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        // textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {formatTimeByDate(value?.createdAt)}
                    </TableCell>
                  </TableRow>
                ))
              : data?.slice(0, 5)?.map((value, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {RemainingDay(value?.createdAt)}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        color:
                          value?.log === "unMark" ? "primary.main" : "#15E01D",
                        textTransform: "capitalize",
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      {value?.log}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {value?.markBy}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor: "#F9F9FC",
                        // textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {formatTimeByDate(value?.createdAt)}
                    </TableCell>
                  </TableRow>
                ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttendanceTable;
