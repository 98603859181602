import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getHomeData: builder.query({
      query: ({ restroId, status }) =>
        `get/all/ShallowMember/${restroId}?status=${status}`,
      // keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetHomeDataQuery } = homeApi;
