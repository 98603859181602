import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  // IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleAlert } from "../../Apis/features/alertSlice";
import { useUpdateMarkAttendanceMutation } from "../../Apis/reduxRTK/memberApi";
import { useDispatch } from "react-redux";
// import dayjs from "dayjs";
// import { RemainingDays, SkPrice } from "../../extras/dataFunction";
import {
  handleUpdateModel,
  // handleUpdateModelDetails,
} from "../../Apis/features/modalSlice";
import {
  handleIsMemberPlanData,
  handleRenewalPlanModel,
} from "../../Apis/features/planSlice";
import { EditMemberIcon } from "../../assets/SVG";
// import { DriveFileRenameOutline } from "@mui/icons-material";

const MemberDetailsCard = ({ member }) => {
  const dispatch = useDispatch();
  const [markAttendance] = useUpdateMarkAttendanceMutation();
  // console.log(member, "memberdata");
  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const [isChecked, setIsChecked] = useState({
    one: false,
    two: false,
  });

  useEffect(() => {
    if (member) {
      setIsChecked((prev) => ({
        ...prev,
        one: member?.attendaceMarkOne,
        two: member?.attendaceMarkTwo,
      }));
    }
  }, [member?.attendaceMarkOne, member?.attendaceMarkTwo, dispatch]);

  const handleChangeCheck = async (e) => {
    // console.log(e.target.checked);
    const mark = e.target.checked;
    try {
      const res = await markAttendance({
        mark: mark,
        memberId: member?.data?._id,
        restId: member?.data?.restaurantId,
        adminId: localStorage.getItem("authId"),
      });
      // console.log(res);
      if (!res?.data?.success) {
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: res?.data?.message,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };

  return (
    <Card
      sx={{
        height: "8rem",
        display: "flex",
        width: "100%",
        p: 0.5,
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
        borderRadius: 3,
        "&:hover": {
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
        position: "relative",
      }}
      // onClick={(e) => {
      //   e.stopPropagation();

      // }}
    >
      <Tooltip arrow title="Edit">
        <IconButton
          aria-label="editButton"
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            border: 1,
            borderColor: "primary.main",
            // borderColor: "#E8E8E8",
            borderRadius: 2,
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(handleUpdateModel(true));
          }}
        >
          {EditMemberIcon}
        </IconButton>
      </Tooltip>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,

          height: "100%",
        }}
      >
        <Box sx={{ position: "relative", height: "100%" }}>
          <Tooltip arrow title="click to edit">
            <img
              src={`${process.env.REACT_APP_URI}/${member?.data?.photo}`}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/images/profile.png");
              }}
              style={{
                width: "9.5rem",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                cursor: "pointer",
                border: "1px solid #232323",
              }}
              alt="......"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(handleUpdateModel(true));
              }}
            />
          </Tooltip>
          {/* <IconButton
            aria-label="edit"
            size="small"
            sx={{
              bottom: 0,
              // right: "50%",
              left: "40%",
              position: "absolute",
              zIndex: 10,
              color: "white.main",
              bgcolor: "primary.main",
              borderRadius: "50%",
              border: 1.5,
              borderColor: "white.main",
              p: 0.5,
              "&:hover": {
                color: "white.main",
                bgcolor: "primary.main",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(handleUpdateModel(true));
            }}
          >
            <DriveFileRenameOutline height={18} width={18} />
          </IconButton> */}
          {/* <img
            src={require("../../assets/images/edit2.png")}
            alt="edit button"
            style={{
              height: "1.2rem",
              width: "1.2rem",
              position: "absolute",
              bottom: "-2px",
              right: "-15px ",
              cursor: "pointer",
              borderRadius: "50%",
              zIndex: 10,
            }}
            
          /> */}
        </Box>

        <CardContent
          sx={{
            width: "auto",
            p: 0.5,
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              color: "black.main",
              fontWeight: 600,

              maxWidth: 300,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {member?.data?.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            color="text.secondary"
          >
            Mobile : {member?.data?.phone}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Id: {member?.data?.id}
          </Typography>
        </CardContent>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {member?.data?.currentPlanId && (
              <React.Fragment>
                <CardActions style={{ padding: 0 }}>
                  <Typography
                    variant={"caption"}
                    color="text.secondary"
                    fontWeight={400}
                    fontSize={15}
                    textAlign={"center"}
                  >
                    Mark Attendance
                  </Typography>
                  <Checkbox
                    checked={isChecked.one}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 30 }, p: 0 }}
                    onChange={(e) => {
                      handleChangeCheck(e);
                      const mark = e.target.checked;
                      setIsChecked((prev) => ({ ...prev, one: mark }));
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <Checkbox
                    checked={isChecked.two}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 30 }, p: 0 }}
                    onChange={(e) => {
                      handleChangeCheck(e);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const mark = e.target.checked;
                      setIsChecked((prev) => ({ ...prev, two: mark }));
                    }}
                  />
                </CardActions>
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            )}
            <CardContent
              style={{
                padding: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                <img
                  src={require("../../assets/images/tahliSmall.png")}
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt="......"
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: 0.1,
                  }}
                >
                  {" "}
                  {!member?.daysRemaining ? (
                    <Typography variant="caption" color="red" fontWeight={500}>
                      Plan Expired
                    </Typography>
                  ) : member?.daysRemaining <= 5 ? (
                    <Typography variant="body1" color="red">
                      {" "}
                      {` Plan expire in ${member?.daysRemaining} days`}{" "}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="text.secondary">
                      {" "}
                      {` Plan expire in ${formatDate(
                        member?.data?.currentPlanId?.endDate
                      )}`}{" "}
                    </Typography>
                  )}
                  {member?.data?.currentPlanId?.thaliCount && (
                    <Typography variant="body1" color="text.secondary">
                      {member?.data?.currentPlanId?.thaliCount || 0} Thali
                      Remaining
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Box>
        </CardContent>
      </Box>

      <CardActions
        style={{
          padding: 5,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        {/* <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(handleUpdateModel(true));
          }}
        >
          Edit
        </Button> */}
        {(!member?.data?.currentPlanId ||
          member?.data?.currentPlanId?.thaliCount < 5 ||
          (member?.daysRemaining <= 5 && !member?.upcomingPlans?.at(0)) ||
          (!member?.upcomingPlans?.at(0) && !member?.data?.currentPlanId)) && (
          <Button
            variant="contained"
            // color="primary"
            sx={{
              color: "white.main",
              bgcolor: "primary.main",
              "&:hover": {
                color: "white.main",
                bgcolor: "primary.main",
              },
            }}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(handleRenewalPlanModel(true));
              dispatch(handleIsMemberPlanData(member));
            }}
          >
            Renewal Plan
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default MemberDetailsCard;
