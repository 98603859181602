import { Paper, Container, Typography } from "@mui/material";
import { sanitize } from "dompurify";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Policy = () => {
  const { state } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state]);
  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <Container
        maxWidth="lg"
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html: sanitize(state),
          }}
        />
      </Container>
    </Paper>
  );
};

export default Policy;
