import { createSlice } from "@reduxjs/toolkit";

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    restaurantData: null,
    singleRestaurant: null,
    isRestaurantShow: false,
    updatedRestaurant: null,
    searchName: "",
    title: false,
    isRestroLoading: false,
  },
  reducers: {
    getRestaurantData: (state, action) => {
      state.restaurantData = action.payload;
    },
    getSingleRestaurantAdminId: (state, action) => {
      state.singleRestaurant = action.payload;
    },
    handleRestaurantModel: (state, action) => {
      state.isRestaurantShow = action.payload?.show;
      state.title = action.payload?.title;
    },
    getSingleRestaurant: (state, action) => {
      state.updatedRestaurant = action.payload;
    },
    getSearchName: (state, action) => {
      state.searchName = action.payload;
    },
    isRestroLoading: (state, action) => {
      state.isRestroLoading = action.payload;
    },
  },
});

export const {
  getRestaurantData,
  getSingleRestaurantAdminId,
  handleRestaurantModel,
  getSingleRestaurant,
  getSearchName,
  isRestroLoading,
} = restaurantSlice.actions;
export default restaurantSlice.reducer;
