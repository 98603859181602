import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modals",
  initialState: {
    isUpdateDetails: null,
    isUpdateMemberShow: false,
    isOpen: false,
    isMemberId: null,
    isTitle: false,
    helpModalOpen: false,
    helpModalState: null,
    isNotificationOpen: false,
    isNotificationData: null,
    isMemberData: null,
  },
  reducers: {
    handleUpdateModel: (state, action) => {
      state.isUpdateMemberShow = action.payload;
    },
    handleUpdateModelDetails: (state, action) => {
      state.isUpdateDetails = action.payload;
    },
    isHandleModalOpen: (state, action) => {
      state.isOpen = action.payload.show;
      state.isMemberId = action.payload.isMemberId;
      state.isTitle = action.payload.title;
      state.isMemberData = action.payload.data;
    },
    isHandleHelpModalOpen: (state, action) => {
      state.helpModalOpen = action.payload.show;
      state.helpModalState = action.payload.state;
    },
    handleNotification: (state, action) => {
      state.isNotificationOpen = action.payload.show;
      state.isNotificationData = action.payload.data;
    },
  },
});
export const {
  handleUpdateModel,
  handleUpdateModelDetails,
  isHandleModalOpen,
  isHandleHelpModalOpen,
  handleNotification,
} = modalSlice.actions;
export default modalSlice.reducer;
