import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: "companySlice",
  initialState: {
    company: null,
    isLoading: false,
    error: null,
    isSuccess: false,
  },
  reducers: {
    getLocalCompany: (state, action) => {
      state.company = action.payload.data;
      state.isSuccess = action.payload.isLoading;
      state.error = action.payload.error;
      state.isSuccess = action.payload.isSuccess;
    },
  },
});
export const { getLocalCompany } = companySlice.actions;
export default companySlice.reducer;
