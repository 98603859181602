import React, { useEffect, useState } from "react";
// import Base from ".";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAlert } from "../../Apis/features/alertSlice";
import Base from "../User Authentication";

import { useCreateRestaurantMutation } from "../../Apis/reduxRTK/restaurantApi";
import { getRestaurantData } from "../../Apis/features/restaurantSlice";
import {
  addAuth,
  setAuthId,
  setAuthToken,
  setUserType,
} from "../../Apis/features/authSlice";
import SKTimePicker from "../../components/SkTimePicker";
import Heading from "../../examples/Heading";

const AddRestaurant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // console.log(state, "state");

  const [createRestaurant] = useCreateRestaurantMutation();
  const [apiError, setApiError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [coords, setCoords] = useState({ lat: "", lng: "" });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );
    return setCoords({ lat: "", lng: "" });
  }, []);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) localStorage.setItem("token", state?.token);
  }, [token]);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")

      .required(" name is required"),
    contact: yup
      .string()
      .min(10)
      .max(10)
      .matches(phoneRegex, "Invalid phone")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .required("Phone is required"),

    email: yup
      .string()
      .required("Email is required")

      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
    upiId: yup
      .string()
      .required("UPI ID is required")

      .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID"),
    openTime: yup.string().required("Open Time is required"),
    closeTime: yup.string().required("Close Time is required"),
    // contact: yup.string().required("Close Time is required"),
  });
  const initialValues = {
    name: "",
    contact: "",
    openTime: "",
    closeTime: "",
    email: "",
    upiId: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: isMobleSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("logo", images);
      formData.append("coordinates", JSON.stringify(coords));

      setIsSubmitting(true);
      try {
        const createRest = await createRestaurant({
          data: formData,
          adminId: state?.data?._id || state?._id,
        });
        setIsSubmitting(false);
        // console.log(createRest, "rest");
        if (createRest?.data?.success) {
          dispatch(
            handleAlert({
              isOpen: true,
              type: "success",
              msg: "Login Successfully",
            })
          );
          dispatch(getRestaurantData(createRest?.data?.data));
          dispatch(addAuth(true));
          dispatch(setAuthToken(state?.token));
          dispatch(setAuthId(state.data?._id));
          dispatch(setUserType(state?.data?.userType));
          navigate("/");
        }
        if (createRest?.error) {
          setApiError(createRest?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
        // if (data?.data?.success) {
        //   const res = await checkSendOtp({
        //     phone: values?.contact,
        //   });
        //   console.log(res);
        //   if (res?.data?.success) {
        //     navigate("/verify", { state: { data: data?.data, res: res?.data } });
        //   }
        //   dispatch(
        //     handleAlert({
        //       isOpen: data?.data?.success,
        //       type: data?.data?.success ? "success" : "error",
        //       msg: data?.data?.message,
        //     })
        //   );
        // }
        // console.log(data, "data");

        // if (data?.error) {
        //   setApiError(data?.error?.data?.message);
        //   setTimeout(() => {
        //     setApiError(null);
        //   }, 5000);
        // } else {
        //   setApiError(null);
        // }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    },
  });
  return (
    <Base>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          color: "white.main",
          p: 2.5,
          gap: 2,
          overflowY: "scroll",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        component="form"
        role="form"
        onSubmit={formik.handleSubmit}
      >
        <Heading
          text={"Add Restaurant"}
          color="white.main"
          isColor="#fff"
          fontSize={"1.5rem"}
        />
        <Box
          sx={{
            width: "85%",
            gap: 1.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <label htmlFor="imageuploader">
            {" "}
            <Box
              sx={{
                height: "5rem",
                width: "7rem",

                borderRadius: 4,
                border: 2,
                borderColor: "white.main",
                background: "rgba(217, 217, 217, 0.52)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {isUploading ? (
                <CircularProgress color="white" />
              ) : previewImage ? (
                <img
                  src={previewImage}
                  alt="edit button"
                  style={{
                    height: "100%",
                    width: "100%",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : (
                // : userData?.image
                // ? `${process.env.REACT_APP_URI}/${userData?.image}`
                <img
                  src={require("../../assets/images/cameraupload.png")}
                  alt="edit button"
                  style={{
                    height: "2rem",
                    width: "2rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              )}
            </Box>
          </label>
          <input
            // required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader"}
          />
          <TextField
            size="small"
            fullWidth
            type={"text"}
            required
            autoComplete="off"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            error={
              (!!formik.touched.name && !!formik.errors.name) || !!apiError
            }
            helperText={(formik.touched.name && formik.errors.name) || apiError}
            label="Full Name "
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                color: "white.main",
              },

              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "white.main",
                bgcolor: "rgba(0, 0, 0, 0.2)",
                color: "white.main",
              },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "white.main",
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <TextField
            size="small"
            fullWidth
            type={"number"}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            required
            autoComplete="off"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contact}
            name="contact"
            error={
              (!!formik.touched.contact && !!formik.errors.contact) ||
              !!apiError
            }
            helperText={
              (formik.touched.contact && formik.errors.contact) || apiError
            }
            label="Phone Number"
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                color: "white.main",
              },
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "textfield",
                },
              },
              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "white.main",
                bgcolor: "rgba(0, 0, 0, 0.2)",
                color: "white.main",
              },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "white.main",
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
          />
          <TextField
            size="small"
            fullWidth
            type={"email"}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            required
            autoComplete="off"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            error={
              (!!formik.touched.email && !!formik.errors.email) || !!apiError
            }
            helperText={
              (formik.touched.email && formik.errors.email) || apiError
            }
            label="E-mail "
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                color: "white.main",
              },
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "textfield",
                },
              },
              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "white.main",
                bgcolor: "rgba(0, 0, 0, 0.2)",
                color: "white.main",
              },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "white.main",
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
              gap: 0.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              // flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "50%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <SKTimePicker
                handleBlur={formik.handleBlur}
                handleChange={(value) =>
                  formik.setFieldValue("openTime", value)
                }
                values={formik.values.openTime}
                touched={formik.touched.openTime}
                errors={formik.errors.openTime}
                apiError={apiError}
                isName="openTime"
                rate={true}
                label={"Open"}
              />
            </Box>
            <Box
              sx={{
                width: "50%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <SKTimePicker
                handleBlur={formik.handleBlur}
                handleChange={(value) =>
                  formik.setFieldValue("closeTime", value)
                }
                values={formik.values.closeTime}
                touched={formik.touched.closeTime}
                errors={formik.errors.closeTime}
                apiError={apiError}
                isName="closeTime"
                rate={true}
                label="Close"
              />
            </Box>
          </Box>

          <TextField
            size="small"
            fullWidth
            type={"text"}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            required
            autoComplete="off"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.upiId}
            name="upiId"
            error={
              (!!formik.touched.upiId && !!formik.errors.upiId) || !!apiError
            }
            helperText={
              (formik.touched.upiId && formik.errors.upiId) || apiError
            }
            label="UPI Id"
            sx={{
              "& .MuiFormHelperText-root.Mui-error": {
                color: "white.main",
              },
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "textfield",
                },
              },
              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "white.main",
                bgcolor: "rgba(0, 0, 0, 0.2)",
                color: "white.main",
              },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                borderColor: "white.main",
              },
              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "white.main",
                },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end"></InputAdornment>,
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              textTransform: "capitalize",
              height: "2.8rem",
              borderRadius: 10,
              color: "primary.main",
              bgcolor: "white.main",
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                color: "primary.main",
                bgcolor: "white.main",
              },
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting....." : "Submit"}
          </Button>
        </Box>
      </Box>
    </Base>
  );
};

export default AddRestaurant;
