import {
  Paper,
  Typography,
  TextField,
  Avatar,
  CircularProgress,
  Box,
  Divider,
  Button,
} from "@mui/material";
import React from "react";
import {
  useGetAdminQuery,
  useUpdateAdminMutation,
} from "../../Apis/reduxRTK/adminApi";
import SkLoading from "../../components/SkError/SkLoading";
import BasicModal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { handleProfileModel } from "../../Apis/features/authSlice";
import { handleAlert } from "../../Apis/features/alertSlice";

import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Heading from "../../examples/Heading";

const Profile = () => {
  const { isProfileOpen } = useSelector((state) => state.isAuth);
  const dispatch = useDispatch();
  const { data: admin, isLoading, isSuccess, error } = useGetAdminQuery();
  if (isLoading) {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: "100vh",
          p: 2,
          gap: 4,
        }}
      >
        <SkLoading />
      </Paper>
    );
  }
  // console.log(admin);
  if (error) {
    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: "100vh",
          p: 2,
          gap: 4,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="primary" fontWeight={600}>
            {" "}
            something went wrong
          </Typography>
        </Box>
      </Paper>
    );
  }
  if (isSuccess)
    return (
      <>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            height: "100vh",
            p: 2,
            gap: 4,
          }}
        >
          <Heading
            text={"Personal details"}
            color="primary.main"
            fontSize={"2rem"}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "80%",
              borderRadius: 4,
              boxShadow: "0px 4px 32px 0px rgba(0, 0, 0, 0.04)",
              p: 1,
              gap: 2,
            }}
          >
            <Box
              sx={{
                height: 200,
                width: 190,
                borderRadius: 2.2,
              }}
            >
              <img
                // src={require("../../assets/images/0000-shraddha-kapoor.jpg")}
                src={`${process.env.REACT_APP_URI}/${admin?.data?.userImage}`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../assets/images/profile.png");
                }}
                alt="..."
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                height: "100%",
              }}
            >
              <Typography variant="h4" color="primary" fontWeight={600} mb="1">
                {admin?.data?.fullName}
              </Typography>
              <Typography
                variant="body1"
                color="initial"
                fontWeight={500}
                mb="0.5"
              >
                {admin?.data?.email}
              </Typography>
              <Divider sx={{ width: "100%" }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="body2" color="initial" fontWeight={300}>
                  {admin?.data?.phone}
                </Typography>
                <Button
                  variant="contained"
                  // color="primary"
                  sx={{
                    textTransform: "capitalize",
                    color: "white.main",
                    bgcolor: "primary.main",
                    "&:hover": {
                      color: "white.main",
                      bgcolor: "primary.main",
                    },
                  }}
                  onClick={() =>
                    dispatch(
                      handleProfileModel({ show: true, data: admin?.data })
                    )
                  }
                >
                  Edit
                </Button>
              </Box>
            </Box>
          </Box>
          <BasicModal
            show={isProfileOpen}
            unShow={() => dispatch(handleProfileModel({ show: false }))}
            width={500}
            height={"auto"}
            overflowY={true}
            // padding={4}
          >
            <NewProfile />
          </BasicModal>
        </Paper>
      </>
    );
};

export default Profile;

const NewProfile = () => {
  const { authData } = useSelector((state) => state.isAuth);
  const dispatch = useDispatch();
  // console.log(authData);
  const [updateProfile] = useUpdateAdminMutation();

  const [apiError, setApiError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };

  const handleSubmitMobile = async (values) => {
    // console.log(values);

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      Object.entries(values)?.map(([key, value]) =>
        formData.append(key, value)
      );
      formData.append("image", images);
      const data = await updateProfile({
        data: formData,
      });

      setIsSubmitting(false);
      if (data?.data?.success) {
        dispatch(handleProfileModel({ show: false }));
      }
      dispatch(
        handleAlert({
          isOpen: data?.data?.success,
          type: data?.data?.success ? "success" : "error",
          msg: data?.data?.message,
        })
      );
      // }
      // console.log(data, "data");

      if (data?.error) {
        setApiError(data?.error?.data?.message);
        setTimeout(() => {
          setApiError(null);
        }, 5000);
      } else {
        setApiError(null);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    phone: yup
      .string()
      .min(10)
      .max(10)
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),

    fullName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )

      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),

    email: yup
      .string()

      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
  });
  const initialValues = {
    phone: authData ? authData?.phone : "",
    fullName: authData ? authData?.fullName : "",
    email: authData ? authData?.email : "",
  };
  return (
    <Formik
      onSubmit={handleSubmitMobile}
      initialValues={initialValues}
      validationSchema={isMobleSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            color: "primary.main",
            // p: 2.5,
            gap: 1.5,
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <Heading
            text={"Complete Profile"}
            color="primary.main"
            fontSize={"2rem"}
          />
          {/* <Box
            sx={{
              mt: 0.6,
              height: "6rem",
              width: "6rem", 
              // border: 2,
              // borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {isUploading ? (
              <Avatar
                sx={{
                  height: "6rem",
                  width: "6rem",
                  border: 2,
                  borderColor: "primary.main",
                  background: "transparent",
                }}
              >
                <CircularProgress color="primary" />
              </Avatar>
            ) : (
              <Box>
                <label htmlFor="imageuploader">
                  {" "}
                  <img
                    src={require("../../assets/images/edit2.png")}
                    alt="edit button"
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      position: "absolute",
                      bottom: "0",
                      right: "0 ",
                      cursor: "pointer",
                      borderRadius: "50%",
                      zIndex: 10,
                    }}
                  />
                </label>
                <input
                  // required
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleSelectImage}
                  id={"imageuploader"}
                />
                <Avatar
                  sx={{
                    height: "6rem",
                    width: "6rem",
                    border: 2,
                    borderColor: "primary.main",
                  }}
                  src={
                    previewImage
                      ? previewImage
                      : authData?.userImage
                      ? `${process.env.REACT_APP_URI}/${authData?.userImage}`
                      : null
                  }
                />
              </Box>
            )}
          </Box> */}
          <label htmlFor="imageuploader">
            {" "}
            <Box
              sx={{
                height: "6.5rem",
                width: "8.5rem",
                mt: 2,
                borderRadius: 4,
                border: 2,
                borderColor: "white.main",
                background: "rgba(217, 217, 217, 0.52)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {isUploading ? (
                <CircularProgress color="white" />
              ) : previewImage ? (
                <img
                  src={previewImage}
                  alt="edit button"
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/images/profile.png");
                  }}
                  style={{
                    height: "6.5rem",
                    width: "8.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : authData?.userImage ? (
                <img
                  src={`${process.env.REACT_APP_URI}/${authData?.userImage}`}
                  alt="edit button"
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/images/profile.png");
                  }}
                  style={{
                    height: "6.5rem",
                    width: "8.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : (
                <img
                  src={require("../../assets/images/cameraupload.png")}
                  alt="edit button"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              )}
            </Box>
          </label>
          <input
            // required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader"}
          />
          <Box
            sx={{
              width: "95%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              // InputLabelProps={{
              //   style: { color: "#fff" },
              // }}
              fullWidth
              type={"text"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.fullName}
              name="fullName"
              error={(!!touched.fullName && !!errors.fullName) || !!apiError}
              helperText={(touched.fullName && errors.fullName) || apiError}
              label="Full Name"
              sx={{
                // "& .MuiFormHelperText-root.Mui-error": {
                //   color: "white.main",
                // },

                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "95%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              // InputLabelProps={{
              //   style: { color: "#fff" },
              // }}
              type={"email"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={(!!touched.email && !!errors.email) || !!apiError}
              helperText={(touched.email && errors.email) || apiError}
              label="E-mail"
              sx={{
                // "& .MuiFormHelperText-root.Mui-error": {
                //   color: "white.main",
                // },
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "95%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              // InputLabelProps={{
              //   style: { color: "#fff" },
              // }}
              type={"number"}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              disabled
              autoComplete="off"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone}
              name="phone"
              error={(!!touched.phone && !!errors.phone) || !!apiError}
              helperText={(touched.phone && errors.phone) || apiError}
              label="Phone Number"
              sx={{
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "white.main",
                },
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>

          <Button
            type="submit"
            // // onClick={() => setClick(false)}
            // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
            variant="contained"
            sx={{
              width: "85%",
              textTransform: "capitalize",
              mt: 2,
              borderRadius: 10,
              bgcolor: "primary.main",
              color: "white.main",
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white.main",
              },
            }}
          >
            {isSubmitting ? "Updating....." : "update"}
          </Button>
        </Box>
      )}
    </Formik>
  );
};
