import {
  Box,
  Card,
  Divider,
  Paper,
  Typography,
  Button,
  CardActions,
  CardContent,
} from "@mui/material";
import React from "react";

import {
  // useGetAttendanceByMemberIdQuery,
  useGetMemberByMemberIdQuery,
} from "../../Apis/reduxRTK/memberApi";

import MemberDetailsCard from "../../components/Cards/MemberDetailsCard";
import SkLoading from "../../components/SkError/SkLoading";
import Heading from "../../examples/Heading";
import Calendar from "../../examples/Calendar";
// import Table from "../../examples/Table";
import AttendanceTable from "../../examples/Table";
// import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Attendance from "../Attendance";
import { useState } from "react";
import MemberPlanCard from "../../components/Cards/MemberPlanCard";
import { SkPrice } from "../../extras/dataFunction";
// import BasicModal from "../../components/Modal";
// import UpdateMember from "./UpdateMember";
import {
  handleUpdateModelDetails,
  isHandleModalOpen,
} from "../../Apis/features/modalSlice";

const Member = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { isMemberId } = useSelector((state) => state.isMember);
  const {
    data: member,
    isLoading,
    isSuccess,
    error,
  } = useGetMemberByMemberIdQuery({ memberId: isMemberId });
  if (isSuccess && member) {
    dispatch(handleUpdateModelDetails(member?.data));
  }
  // console.log(member, "member", isMemberId);

  return isLoading ? (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // flexWrap: "wrap",
        flexDirection: "column",
        gap: 2,
        p: 2,
        width: "100%",
        minHeight: "80vh",
      }}
    >
      <SkLoading />{" "}
    </Paper>
  ) : isSuccess ? (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",

        flexDirection: "column",
        gap: 2,
        p: 2,
        width: "100%",
        minHeight: "80vh",
      }}
    >
      <MemberDetailsCard member={member} />
      {member?.data?.currentPlanId && (
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "center",
            width: "100%",
            p: 0.5,
            gap: 2.5,

            "&:hover": {
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            },
          }}
        >
          {" "}
          <CardContent>
            {member?.data?.dueAmount > 0 && (
              <Typography variant="body1" color="red" fontWeight={500}>
                {`Due Amount : Rs ${SkPrice(member?.data?.dueAmount)}`}
              </Typography>
            )}
          </CardContent>
          <CardActions
            style={{
              padding: 5,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {member?.data?.dueAmount > 0 && (
              <Button
                variant="contained"
                // color="primary"
                sx={{
                  color: "white.main",
                  bgcolor: "primary.main",
                  "&:hover": {
                    color: "white.main",
                    bgcolor: "primary.main",
                  },
                }}
                size="small"
                onClick={() =>
                  dispatch(
                    isHandleModalOpen({
                      show: true,
                      title: false,
                      isMemberId: member?.data?.currentPlanId?._id,
                      data: member,
                    })
                  )
                }
              >
                Add Amount
              </Button>
            )}
            {member?.data?.currentPlanId && (
              <Button
                variant="contained"
                // color="primary"
                sx={{
                  color: "white.main",
                  bgcolor: "primary.main",
                  "&:hover": {
                    color: "white.main",
                    bgcolor: "primary.main",
                  },
                }}
                size="small"
                onClick={() =>
                  dispatch(
                    isHandleModalOpen({
                      show: true,
                      title: true,
                      isMemberId: member?.data?.currentPlanId?._id,
                    })
                  )
                }
              >
                Add holiday
              </Button>
            )}
          </CardActions>
        </Card>
      )}
      {!isOpen && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            gap: 1,
          }}
        >
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "49%",
              p: 0.5,
              gap: 0.5,

              "&:hover": {
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              },
            }}
          >
            <Heading text="Attendance" />
            <Box
              sx={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.9,
                }}
              >
                <Box
                  sx={{
                    height: 22,
                    width: 22,
                    borderRadius: 1,
                    backgroundColor: "#FFB297",
                    // display: "inline-block",
                  }}
                />{" "}
                <Typography variant="caption" color="initial" fontSize={15}>
                  1 Time Present{" "}
                </Typography>
              </Box>{" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.9,
                }}
              >
                <Box
                  sx={{
                    height: 22,
                    width: 22,
                    borderRadius: 1,
                    backgroundColor: "primary.main",
                    // display: "inline-block",
                  }}
                />{" "}
                <Typography variant="caption" color="initial" fontSize={15}>
                  2 Time Present
                </Typography>
              </Box>
            </Box>
            {/* calender */}

            <Calendar data={member?.calender} />

            {/* Attendance */}
            {member?.recentAttendance &&
              member?.recentAttendance?.length > 0 && (
                <React.Fragment>
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      my: 1.5,
                    }}
                  >
                    <Heading
                      text="Recent Mark Attendance"
                      color="primary.main"
                    />
                    <Button
                      variant="text"
                      color="primary"
                      sx={{
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setIsOpen(true);
                        // navigate(`/recentAttendance/${member?.data?._id}`);
                        // dispatch(handleMemberModelDetails({ show: false }));
                      }}
                    >
                      view More
                    </Button>
                  </Box>

                  <AttendanceTable data={member?.recentAttendance} />
                </React.Fragment>
              )}
          </Card>
          <Divider orientation="vertical" flexItem />
          <Card
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "49%",
              p: 0.5,
              gap: 0.5,
              "&:hover": {
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              },
            }}
          >
            <Heading text="Plans" />
            {/* Plan active */}

            {member?.data?.currentPlanId && (
              <Box
                sx={{
                  my: 2.5,
                  width: "100%",
                }}
              >
                <MemberPlanCard
                  rate={true}
                  value={member?.data?.currentPlanId}
                />
              </Box>
            )}
            {member?.upcomingPlans && member?.upcomingPlans?.at(0) && (
              <Box
                sx={{
                  my: 2.5,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Heading text="Up Coming Plans" color="primary.main" />
                </Box>
                {member?.upcomingPlans &&
                  member?.upcomingPlans?.length > 0 &&
                  member?.upcomingPlans?.map((value, i) => (
                    <MemberPlanCard kry={i} value={value} />
                  ))}
              </Box>
            )}
            {member?.expiredPlans && member?.expiredPlans?.at(0) && (
              <Box
                sx={{
                  my: 2.5,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                  flexDirection: "column",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Heading text="Expired Plans" color="primary.main" />
                </Box>
                {member?.expiredPlans &&
                  member?.expiredPlans?.length > 0 &&
                  member?.expiredPlans?.map((value, i) => (
                    <MemberPlanCard kry={i} value={value} />
                  ))}
              </Box>
            )}
            {/* Plan history */}
          </Card>
        </Box>
      )}
      {isOpen && (
        <Attendance
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          memberId={member?.data?._id}
        />
      )}
    </Paper>
  ) : (
    error && (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // flexWrap: "wrap",
          flexDirection: "column",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <Box
          sx={{
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="primary" fontWeight={600}>
            {" "}
            something went wrong
          </Typography>
        </Box>
      </Paper>
    )
  );
};

export default Member;
