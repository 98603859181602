import React from "react";

const SkLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "5rem",
          height: "5rem",
          borderRadius: "50%",
        }}
      >
        <img
          src={require("../../assets/images/logogif.gif")}
          alt="loading...."
          style={{
            width: "5rem",
            height: "5rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </div>
      loading......
    </div>
  );
};

export default SkLoading;
