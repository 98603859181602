// ProtectedRoute.js
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ allowedRoles }) => {
  const { userType } = useSelector((state) => state.isAuth);
  // const auth = false;
  // const user = "admin";
  if (!localStorage.getItem("auth")) {
    return <Navigate to="/signin" replace />;
  }

  // if (
  //   !allowedRoles ||
  //   allowedRoles.includes(userType || localStorage.getItem("userType"))
  // ) {
  //   return <Outlet />;
  // } else {
  //   return <Navigate to="/unauthorized" replace />;
  // }
  if (allowedRoles && !allowedRoles.includes(userType)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // If the user is authenticated and has the required role, render the route content
  return <Outlet />;
};

export default ProtectedRoute;
