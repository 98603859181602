import React from "react";
import Container from "@mui/material/Container";
import { Box, Card } from "@mui/material";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { useLocation, useNavigate, } from 'react-router-dom';
import { useEffect, useState } from "react";
  import Loading from "../../components/loading/index";

const Base = ({ children }) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])

  const location = useLocation();
  const navigate = useNavigate();
  if (loading) {
    return <Loading />
  }
  return (
    <>
     
     
          <img style={{position:"relative",left:"400px"}} src={require("../../assets/images/thali2.png")} alt="thali"
           width={520} height={750}/>
          <Box
            sx={{
              width: "50%",
              height: `100vh`,
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 60px 40px -7px",
              // height: `calc(100vh - 60px)`,
              backgroundColor: "primary.main",
              color: "white.main",
              position: "absolute",
              right: 0,
              top: 0,
              p: 1.5,
            }}
          >

            {location.pathname !='/signin'&&
            <Box
            pl={8}
            pt={2}
            >
              <ArrowCircleLeftOutlined onClick={() => navigate(-1)}  sx={{fontSize:"40px",cursor:"pointer"}} /> 
            </Box>}
            {children}
          </Box>
    </>
  );
};

export default Base;
