import React from "react";
import "./style.css"
const index = () => {
  return (
    <div style={{width:"100%",height:"100vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
      <img
        class='rotating'
        src={require("../../assets/images/thali2.png")}
        alt="Next.js Logo"
        width={420} height={630}
        priority
      />
      <img
       class='ddf'
        src={require("../../assets/images/LOGO APNA THALI 2 conflict-02 1.png")}
        alt="Next.js Logo"
        width={200}
        height={200}
        style={{ borderRadius:"10%",marginTop:"-100px" }}
        priority
      />
    </div>
  );
};

export default index;
