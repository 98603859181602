import {
  AppBar,
  Avatar,
  Box,
  Container,
  Typography,
  Button,
  Toolbar,
} from "@mui/material";
import React from "react";
import { LocationIcon } from "../../assets/SVG";

import { useDispatch } from "react-redux";
import { useGetRestaurantByAdminIdQuery } from "../../Apis/reduxRTK/restaurantApi";
import {
  getSingleRestaurantAdminId,
  isRestroLoading,
} from "../../Apis/features/restaurantSlice";
import SkeletonAppBar from "../Skeleton/Appbar";
import { useLocation, useNavigate } from "react-router-dom";
import { handlePlanModel } from "../../Apis/features/planSlice";
import { handleMemberModel } from "../../Apis/features/memberSlice";
import { useGetCompanyQuery } from "../../Apis/reduxRTK/companyApi";
import { getLocalCompany } from "../../Apis/features/companySlice";
// import { handleNotification } from "../../Apis/features/modalSlice";
// import ScrollDialog from "../ScrollebleDialog";
// import NotificationDialog from "./Componets";
import FadeMenu from "../ScrollebleDialog";

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [isShow, setIsShow] = useState(false);
  const adminId = localStorage.getItem("authId");
  const dispatch = useDispatch();

  const {
    data: company,
    isLoading: myLoading,
    error: myError,
    isSuccess: mySuccess,
  } = useGetCompanyQuery();

  if (mySuccess && company) {
    dispatch(
      getLocalCompany({
        data: company?.data,
        myLoading: myLoading,
        myError: myError,
        mySuccess: mySuccess,
      })
    );
  } else if (myError) {
    dispatch(
      getLocalCompany({
        data: company?.data,
        myLoading: myLoading,
        myError: myError,
        mySuccess: mySuccess,
      })
    );
  }

  const {
    data: restaurant,
    isLoading,
    isSuccess,
  } = useGetRestaurantByAdminIdQuery(adminId);

  if (isSuccess) {
    dispatch(getSingleRestaurantAdminId(restaurant?.data));
  }
  if (isLoading || isSuccess) {
    dispatch(isRestroLoading(isLoading));
  }
  return isLoading ? (
    <SkeletonAppBar />
  ) : (
    isSuccess && (
      <>
        <AppBar
          elevation={1}
          position="sticky"
          sx={{
            "&.MuiAppBar-root": {
              backgroundColor: "white.main",
              color: "primary.main",
            },
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                  py: 0.5,
                  cursor: "pointer",
                  // flexGrow: 1,
                }}
                onClick={() => navigate("/restaurant")}
              >
                <Avatar
                  src={
                    restaurant?.data
                      ? `${process.env.REACT_APP_URI}/${restaurant?.data?.logo}`
                      : require("../../assets/images/cameraupload.png")
                  }
                  sx={{
                    height: 55,
                    width: 55,
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 0.5,
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="primary"
                    fontWeight={700}
                    fontSize={22}
                    sx={{
                      maxWidth: "70%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {restaurant?.data?.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      ml: 1,
                    }}
                  >
                    {LocationIcon} &nbsp;
                    <Typography
                      variant="body1"
                      sx={{
                        color: "text.main",
                      }}
                      fontWeight={400}
                      fontSize={12}
                    >
                      Bhopal, MP
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "info.main",
                      ml: 1,
                    }}
                    fontWeight={400}
                    fontSize={12}
                  >
                    Id : {restaurant?.data?.id}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ flexGrow: 0, gap: 1.5, display: "flex" }}>
                <Button
                  variant="contained"
                  sx={{
                    color: "white.main",
                    bgcolor: "primary.main",
                    "&:hover": {
                      color: "white.main",
                      bgcolor: "primary.main",
                    },
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    (pathname === "/plans" &&
                      dispatch(
                        handlePlanModel({ show: true, title: false })
                      )) ||
                      (pathname === "/members" &&
                        dispatch(
                          handleMemberModel({ show: true, title: false })
                        )) ||
                      // (pathname === "/restaurant" &&
                      //   dispatch(
                      //     handleRestaurantModel({ show: true, title: false })
                      //   )) ||
                      dispatch(handleMemberModel({ show: true, title: false }));
                  }}
                >
                  {(pathname === "/plans" && "Add Plan") ||
                    (pathname === "/members" && "add Member") ||
                    // (pathname === "/restaurant" && "add Restaurant") ||
                    "Add Member"}
                </Button>
                {/* <NotificationDialog /> */}
                <FadeMenu />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </>
    )
  );
};

export default Navbar;
