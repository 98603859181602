import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import DownloadIcon from "@mui/icons-material/Download";

const QRScannerSkeleton = () => {
  return (
    <Box sx={{ width: "265px", position: "sticky", top: "88px" }}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          gap: 1,
          p: 1.5,
          height: "100%",
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ color: "black.main", fontWeight: 500 }}>
          <Skeleton variant="text" width={120} height={24} animation="wave" />
        </Typography>
        <Box
          sx={{
            width: "100%",
            // border: 0.5,
            // borderColor: "primary.main",
            borderRadius: 1,
          }}
        >
          <Skeleton
            variant="rectangular"
            width={240}
            height={240}
            animation="wave"
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: "primary.main",
            fontWeight: 400,
          }}
        >
          <Skeleton variant="text" width={180} height={16} animation="wave" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          //   startIcon={<DownloadIcon />}
          disabled
        >
          {/* <Skeleton variant="rectangular" width={100} height={36} animation="wave" /> */}
        </Button>
      </Paper>
    </Box>
  );
};

export default QRScannerSkeleton;
