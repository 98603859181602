import { Paper } from "@mui/material";
import React from "react";
import Heading from "../../examples/Heading";

const ErrorPage = () => {
  return (
    <Paper
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        p: 1,
        minHeight: "100vh",
      }}
    >
      <Heading text={"404 - Page Not Found"} color="primary.main" mt={3} />

      <p>Sorry, the page you are looking for does not exist.</p>
    </Paper>
  );
};

export default ErrorPage;
