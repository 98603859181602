import { Paper, Typography, Box, Button } from "@mui/material";
import React from "react";
import {
  DownloadIcon,
  GreenCircle,
  RedCircle,
  TooltipBar,
  YellowCircle,
} from "../../assets/SVG";
import SearchInput from "../../components/Search";
import MemberCard from "../../components/Cards/MemberCard";

import SkBadge from "../../components/SkBadge/index";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import SkLoading from "../../components/SkError/SkLoading";
import { useGetMemberByRestIdQuery } from "../../Apis/reduxRTK/memberApi";

import { handleMemberModel } from "../../Apis/features/memberSlice";
import SkPagination from "../../components/SkPagination";
import axios from "axios";

import { useDebounce } from "use-debounce";
import { skipToken } from "@reduxjs/toolkit/query";
import QRScannerSkeleton from "../../components/Skeleton/QrScan";

const Home = () => {
  const { singleRestaurant, searchName, isRestroLoading } = useSelector(
    (state) => state.isRestaurant
  );
  const [status, setStatus] = useState("live");
  const [qrLoading, setQrLoading] = useState(false);
  // console.log(searchName, "searchName");
  const [isPage, setIsPage] = useState(1);
  const dispatch = useDispatch();
  const [searchTerm] = useDebounce(searchName, 500);
  const {
    data: liveHomeData,
    isLoading: liveIsLoading,
    // isFetching: liveIsFetching,
    isSuccess: liveIsSuccess,
    error: liveError,
  } = useGetMemberByRestIdQuery(
    {
      status: "live",
      restroId: singleRestaurant?._id,
      search: searchName,
      page: isPage,
    },
    searchTerm || skipToken
  );
  const {
    data: expiringHomeData,
    isLoading: expiringIsLoading,
    // isFetching: expiringIsFetching,
    isSuccess: expiringIsSuccess,
    error: expiringError,
  } = useGetMemberByRestIdQuery(
    {
      status: "expiring",
      restroId: singleRestaurant?._id,
      search: searchName,
      page: isPage,
    },
    searchTerm || skipToken
  );
  const {
    data: expiredHomeData,
    isLoading: expiredIsLoading,
    // isFetching: expiredIsFetching,
    isSuccess: expiredIsSuccess,
    error: expiredError,
  } = useGetMemberByRestIdQuery(
    {
      status: "expired",
      restroId: singleRestaurant?._id,
      search: searchName,
      page: isPage,
    },
    searchTerm || skipToken
  );

  // console.log(singleRestaurant, "singleRestaurant");
  const handleGetPdf = async () => {
    setQrLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/get/Restaurant/qrCode/${singleRestaurant?._id}`
      );
      const url = `${process.env.REACT_APP_SERVER_APP2}/${res.data?.data?.pdf}`;
      console.log(url);
      window.open(url, "_blank");
      setQrLoading(false);
    } catch (error) {
      setQrLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 0.5,
          minHeight: "100vh",
        }}
      >
        <Box sx={{ width: "calc(100% -  265px)", position: "relative" }}>
          {" "}
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: 2,
              p: 0,
              position: "sticky",
              top: "88px",
              zIndex: 12,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
                flexDirection: "column",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setStatus("live");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  p: 1,
                }}
              >
                <SkBadge badgeContent={liveHomeData?.count?.live || 0}>
                  {GreenCircle}
                </SkBadge>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  textTransform={"capitalize"}
                  color="initial"
                  ml={1}
                >
                  live
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 1,
                    borderTop: 0,
                    borderColor: "gray.border",
                    borderRadius: "0px 0px 10px 10px",
                    width: 25,
                    height: 25,
                    padding: "0px 10px",
                  }}
                >
                  <Typography variant="body1" color="initial" fontWeight={500}>
                    {homeData?.count?.live || 0}
                  </Typography>
                </Box> */}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  p: 0,
                  opacity: status === "live" ? 1 : 0,
                }}
              >
                {TooltipBar}
              </Box>
            </Box>
            <Box
              sx={{
                width: "1.5px",
                height: "40px",
                backgroundColor: "gray.border",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
                flexDirection: "column",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setStatus("expiring");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  color: "white.main",
                  p: 1,
                }}
              >
                <SkBadge badgeContent={liveHomeData?.count?.expiring || 0}>
                  {YellowCircle}
                </SkBadge>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  // fontSize={25}
                  textTransform={"capitalize"}
                  color="initial"
                  ml={1}
                >
                  expiring
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 1,
                    // borderTop: 0,
                    borderColor: "gray.border",
                    borderRadius: "0px 0px 10px 10px",
                    width: 25,
                    height: 25,
                    padding: "0px 10px",
                    // position: "absolute",
                    // top: -1,
                  }}
                >
                  <Typography variant="body1" color="initial" fontWeight={500}>
                    {homeData?.count?.expiring || 0}
                  </Typography>
                </Box> */}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  p: 0,
                  opacity: status === "expiring" ? 1 : 0,
                }}
              >
                {TooltipBar}
              </Box>
            </Box>
            <Box
              sx={{
                width: "1.5px",
                height: "40px",
                backgroundColor: "gray.border",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
                flexDirection: "column",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                setStatus("expired");
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  p: 1,

                  // mt: 4.5,
                }}
              >
                <SkBadge badgeContent={liveHomeData?.count?.expired || 0}>
                  {RedCircle}
                </SkBadge>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  // fontSize={25}
                  textTransform={"capitalize"}
                  ml={1}
                >
                  expired
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 1,
                    borderTop: 0,
                    borderColor: "gray.border",
                    borderRadius: "0px 0px 10px 10px",
                    width: 25,
                    height: 25,
                    padding: "0px 10px",
                    // position: "absolute",
                    // top: -1,
                  }}
                >
                  <Typography variant="body1" color="initial" fontWeight={500}>
                    {homeData?.count?.expired || 0}
                  </Typography>
                </Box> */}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  p: 0,
                  opacity: status === "expired" ? 1 : 0,
                }}
              >
                {TooltipBar}
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              minHeight: "80vh",
            }}
          >
            <Box
              sx={{
                width: "100%",
                p: 1,
                pb: 0,

                alignItems: "flex-start",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: 1.5,
                position: "sticky",
                top: "145px",
                zIndex: 11,
                backgroundColor: "white.main",
                borderRadius: 3,
              }}
            >
              {" "}
              <SearchInput />
            </Box>
            <Box
              sx={{
                width: "100%",

                p: 1,
                gap: 1.5,
                flexWrap: "wrap",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // overflowY: "scroll",
              }}
            >
              {liveIsLoading || expiringIsLoading || expiredIsLoading ? (
                <SkLoading />
              ) : (
                <Box
                  sx={{
                    width: "100%",

                    p: 1,
                    gap: 1.5,
                    flexWrap: "wrap",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    // overflowY: "scroll",
                  }}
                >
                  {status === "expired" &&
                    expiredIsSuccess &&
                    expiredHomeData?.data &&
                    expiredHomeData?.data.length > 0 &&
                    expiredHomeData.data.map((value, i) => (
                      <Box
                        sx={{ width: { sm: "47.5%", md: "49%", xl: "49%" } }}
                        key={i}
                      >
                        <MemberCard
                          value={value}
                          rate={true}
                          isFetching={expiredIsSuccess}
                        />
                      </Box>
                    ))}
                  {status === "expiring" &&
                    expiringIsSuccess &&
                    expiringHomeData?.data &&
                    expiringHomeData?.data.length > 0 &&
                    expiringHomeData.data.map((value, i) => (
                      <Box
                        sx={{ width: { sm: "47.5%", md: "49%", xl: "49%" } }}
                        key={i}
                      >
                        <MemberCard
                          value={value}
                          rate={true}
                          isFetching={expiringIsSuccess}
                        />
                      </Box>
                    ))}
                  {status === "live" &&
                    liveIsSuccess &&
                    liveHomeData?.data &&
                    liveHomeData?.data.length > 0 &&
                    liveHomeData.data.map((value, i) => (
                      <Box
                        sx={{ width: { sm: "47.5%", md: "49%", xl: "49%" } }}
                        key={i}
                      >
                        <MemberCard
                          value={value}
                          rate={true}
                          isFetching={liveIsSuccess}
                        />
                      </Box>
                    ))}
                  {/* error when data is [] empty array */}
                  {status !== "expired" &&
                    status !== "expiring" &&
                    status === "live" &&
                    liveHomeData?.data &&
                    !liveHomeData?.data.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          p: 1,
                          height: 250,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={require("../../assets/images/memberNotFound.png")}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt="..."
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ mt: 2, textTransform: "capitalize" }}
                          onClick={() => {
                            dispatch(
                              handleMemberModel({ show: true, title: false })
                            );
                          }}
                        >
                          Add Member
                        </Button>
                      </Box>
                    )}
                  {status !== "live" &&
                    status !== "expiring" &&
                    status === "expired" &&
                    expiredHomeData?.data &&
                    !expiredHomeData?.data.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          p: 1,
                          height: 250,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={require("../../assets/images/memberNotFound.png")}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt="..."
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ mt: 2, textTransform: "capitalize" }}
                          onClick={() => {
                            dispatch(
                              handleMemberModel({ show: true, title: false })
                            );
                          }}
                        >
                          Add Member
                        </Button>
                      </Box>
                    )}
                  {status !== "expired" &&
                    status !== "live" &&
                    status === "expiring" &&
                    expiringHomeData?.data &&
                    !expiringHomeData?.data.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          p: 1,
                          height: 250,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={require("../../assets/images/memberNotFound.png")}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt="..."
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ mt: 2, textTransform: "capitalize" }}
                          onClick={() => {
                            dispatch(
                              handleMemberModel({ show: true, title: false })
                            );
                          }}
                        >
                          Add Member
                        </Button>
                      </Box>
                    )}
                </Box>
              )}
              {/* <Box
                sx={{
                  width: "100%",
                  p: 1,
                  height: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={require("../../assets/images/memberNotFound.png")}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="..."
                />
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                  onClick={() => {
                    dispatch(handleMemberModel({ show: true, title: false }));
                  }}
                >
                  Add Member
                </Button>
              </Box> */}

              {(liveError || expiringError || expiredError) && (
                <Box
                  sx={{
                    mt: 1,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" color="primary" fontWeight={600}>
                    {" "}
                    Something went wrong
                  </Typography>
                </Box>
              )}
            </Box>
            {(expiredIsSuccess || expiringIsSuccess || liveIsSuccess) && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  p: 1,
                  flexGrow: 1,
                }}
              >
                <SkPagination
                  count={
                    (status === "expired" && expiredIsSuccess?.totalPage) ||
                    (status === "live" &&
                      liveIsSuccess &&
                      liveHomeData?.totalPage) ||
                    (status === "expiring" &&
                      expiringIsSuccess &&
                      expiringHomeData?.totalPage)
                  }
                  state={isPage}
                  setState={setIsPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
        <Box
          component={"section"}
          sx={{ minHeight: "100vh", width: "265px", position: "relative" }}
        >
          <Box
            sx={{
              position: "sticky",
              top: "88px",
              zIndex: 12,
            }}
          >
            {isRestroLoading ? (
              <QRScannerSkeleton />
            ) : (
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1.5,
                  p: 1.5,
                  height: "100%",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "black.main",
                    fontWeight: 500,
                  }}
                >
                  Scan Here
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    border: 0.5,
                    borderColor: "primary.main",
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={singleRestaurant?.qrCode}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    alt="QRCode"
                  />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    color: "primary.main",
                    fontWeight: 400,
                  }}
                >
                  Scan And Mark Attendance
                </Typography>
                <Button
                  variant="contained"
                  // color="primary"
                  disabled={qrLoading}
                  sx={{
                    color: "white.main",
                    bgcolor: "primary.main",
                    textTransform: "capitalize",
                    "&:hover": {
                      color: "white.main",
                      bgcolor: "primary.main",
                    },
                  }}
                  startIcon={DownloadIcon}
                  onClick={handleGetPdf}
                >
                  {qrLoading ? "Downloading........" : "Download"}
                </Button>
              </Paper>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
