// store.js
import { configureStore } from "@reduxjs/toolkit";
// import persistedReducer from "../rootReducer"; // Import your rootReducer
import thunk from "redux-thunk";
// import { persistStore, persistReducer } from "redux-persist";
import { setupListeners } from "@reduxjs/toolkit/query/react"; // Import setupListeners
import { api } from "../reduxRTK/api";
import rootReducer from "../rootReducer";
// import storage from "redux-persist/lib/storage"; // You can choose different storage options based on your needs
import { restaurantApi } from "../reduxRTK/restaurantApi";
import { homeApi } from "../reduxRTK/homeApi";
import { memberApi } from "../reduxRTK/memberApi";
import { planApi } from "../reduxRTK/planApi";
import { adminApi } from "../reduxRTK/adminApi";
import { companyApi } from "../reduxRTK/companyApi";
import { notificationAPI } from "../reduxRTK/notificationApi";

// const persistConfig = {
//   key: "root", // Key in storage to store the data
//   storage, // Storage engine you are using (imported from redux-persist)
//   // whitelist: ["users", "isProject", ], // List the names of slices you want to persist
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: rootReducer,
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware().concat(api.middleware), // Add RTK Query middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore specific action types from serialization check
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
      .concat(
        api.middleware,
        restaurantApi.middleware,
        homeApi.middleware,
        memberApi.middleware,
        planApi.middleware,
        adminApi.middleware,
        companyApi.middleware,
        notificationAPI.middleware
      )
      .concat(thunk), // Add RTK Query middleware
  devTools: process.env.NODE_ENV !== "production",
});

// const persistStores = persistStore(Store);

setupListeners(Store.dispatch); // Set up listeners for RTK Query

export { Store };
// export { Store, persistStores };
