import React, { useEffect, useState } from "react";
// import Base from ".";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { handleAlert } from "../../Apis/features/alertSlice";
import { useUpdateRestaurantMutation } from "../../Apis/reduxRTK/restaurantApi";
import { handleRestaurantModel } from "../../Apis/features/restaurantSlice";

import SKTimePicker from "../../components/SkTimePicker";
import Heading from "../../examples/Heading";
// import { formatTime } from "../../extras/dataFunction";

const NewRestaurant = () => {
  const dispatch = useDispatch();
  const [updateRestaurant] = useUpdateRestaurantMutation();
  const [apiError, setApiError] = useState(null);

  const { updatedRestaurant } = useSelector((state) => state.isRestaurant);
  // console.log(updatedRestaurant);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     ({ coords: { latitude, longitude } }) => {
  //       setCoords({ lat: latitude, lng: longitude });
  //     }
  //   );
  //   return setCoords({ lat: "", lng: "" });
  // }, []);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )
      .min(2, "Too Short!")
      .max(256, "Too Long!")

      .required(" name is required"),

    email: yup
      .string()
      .required("Email is required")

      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
    upiId: yup
      .string()
      .required("UPI ID is required")

      .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID"),
    openTime: yup.string().required("Open Time is required"),
    closeTime: yup.string().required("Close Time is required"),
  });

  const initialValues = {
    name: updatedRestaurant?.name || "",
    openTime: updatedRestaurant?.openTime || "",
    closeTime: updatedRestaurant?.closeTime || "",
    email: updatedRestaurant?.email || "",
    upiId: updatedRestaurant?.upiId || "",
    contact: updatedRestaurant?.contact || "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("onSubmit function called");
      // console.log("Form values:", values);

      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("logo", images);
      // formData.append(
      //   "coordinates",
      //   JSON.stringify(updatedRestaurant?.coordinates)
      // );
      setIsSubmitting(true);
      try {
        const createRest = await updateRestaurant({
          data: formData,
          restroId: updatedRestaurant?._id,
          // adminId: state?.data?._id || state?._id,
        });
        setIsSubmitting(false);
        // console.log(createRest, "rest");
        if (createRest?.data?.success) {
          dispatch(handleRestaurantModel({ show: false }));
          dispatch(
            handleAlert({
              isOpen: true,
              type: createRest?.data?.success ? "success" : "error",
              msg: createRest?.data?.message,
            })
          );
        }
        if (createRest?.error) {
          setApiError(createRest?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            color: "primary.main",
            p: 2.5,
            gap: 2.5,
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Heading
            text={" Update Restaurant"}
            color="primary.main"
            fontSize={"1.5rem"}
          />
          <label htmlFor="imageuploader">
            {" "}
            <Box
              sx={{
                height: "7rem",
                width: "9rem",
                mt: 2,
                borderRadius: 4,
                border: 2,
                borderColor: "white.main",
                background: "rgba(217, 217, 217, 0.52)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {isUploading ? (
                <CircularProgress color="white" />
              ) : previewImage ? (
                <img
                  src={previewImage}
                  alt="edit button"
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/images/profile.png");
                  }}
                  style={{
                    height: "7rem",
                    width: "9rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : updatedRestaurant?.logo ? (
                <img
                  src={`${process.env.REACT_APP_URI}/${updatedRestaurant?.logo}`}
                  alt="edit button"
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/images/profile.png");
                  }}
                  style={{
                    height: "7rem",
                    width: "9rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : (
                <img
                  src={require("../../assets/images/cameraupload.png")}
                  alt="edit button"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              )}
            </Box>
          </label>
          <input
            // required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader"}
          />

          <Box
            sx={{
              width: "90%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"text"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
              error={
                (!!formik.touched.name && !!formik.errors.name) || !!apiError
              }
              helperText={
                (formik.touched.name && formik.errors.name) || apiError
              }
              label="Full Name"
              sx={{
                // "& .MuiFormHelperText-root.Mui-error": {
                //   color: "white.main",
                // },

                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "90%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"number"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contact}
              name="contact"
              error={
                (!!formik.touched.contact && !!formik.errors.contact) ||
                !!apiError
              }
              helperText={
                (formik.touched.contact && formik.errors.contact) || apiError
              }
              label="Phone Number"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </Box>
          <Box
            sx={{
              width: "90%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"email"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              error={
                (!!formik.touched.email && !!formik.errors.email) || !!apiError
              }
              helperText={
                (formik.touched.email && formik.errors.email) || apiError
              }
              label="E-mail"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "90%",
              gap: 0.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              // flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "48%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <TimePicker
                defaultValue={nineAM}
                maxTime={fiveAM}
                label="Choose Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                // 5 minutes
                inputProps={{
                  step: 300,
                }}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.openTime}
                name="openTime"
                error={(!!touched.openTime && !!errors.openTime) || !!apiError}
                helperText={(touched.openTime && errors.openTime) || apiError}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                  //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
              /> */}
              <SKTimePicker
                handleBlur={formik.handleBlur}
                handleChange={(value) =>
                  formik.setFieldValue("openTime", value)
                }
                values={formik.values.openTime}
                touched={formik.touched.openTime}
                errors={formik.errors.openTime}
                apiError={apiError}
                isName="openTime"
                label="Open"
                isRate={true}
              />
            </Box>
            <Box
              sx={{
                width: "48%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <SKTimePicker
                handleBlur={formik.handleBlur}
                handleChange={(value) =>
                  formik.setFieldValue("closeTime", value)
                }
                values={formik.values.closeTime}
                touched={formik.touched.closeTime}
                errors={formik.errors.closeTime}
                apiError={apiError}
                isName="closeTime"
                label="Close"
                isRate={true}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",
              gap: 0.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"text"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.upiId}
              name="upiId"
              error={
                (!!formik.touched.upiId && !!formik.errors.upiId) || !!apiError
              }
              helperText={
                (formik.touched.upiId && formik.errors.upiId) || apiError
              }
              label="UPI ID"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                  // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"></InputAdornment>,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "90%",
              textTransform: "capitalize",
              mt: 2,
              height: "2.8rem",
              borderRadius: 10,
              bgcolor: "primary.main",
              color: "white.main",
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white.main",
              },
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting....." : "Submit"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default NewRestaurant;
