import { Pagination, Stack } from "@mui/material";
import React from "react";

const SkPagination = ({ count, state, setState }) => {
  return (
    <>
      <Stack spacing={2} direction={"row"}>
        {/* <Typography>Page: {state}</Typography> */}
        <Pagination
          sx={{
            "&.MuiPaginationItem-text": {
              color: "primary.main",
            },
            "&	.MuiPaginationItem-icon": {
              color: "primary.main",
            },
            "&		.MuiPaginationItem-textInfo": {
              color: "primary.main",
            },
          }}
          color="primary"
          variant="text"
          count={count}
          page={state}
          onChange={(e, value) => setState(value)}
        />
        {/* <Pagination count={10} variant="outlined" color="primary" /> */}
      </Stack>
    </>
  );
};

export default SkPagination;
