import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const planApi = createApi({
  reducerPath: "planApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Plans"],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getPlans: builder.query({
      query: ({ restroId }) => `get/all/Plan/${restroId}`,
      providesTags: ["Plans"],
      // keepUnusedDataFor: 5,
    }),
    getPlanById: builder.query({
      query: ({ planId }) => `getPlan/${planId}`,
      providesTags: ["Plans"],
      // keepUnusedDataFor: 5,
    }),

    createPlan: builder.mutation({
      query: ({ data, adminId, restroId }) => ({
        url: `create/plan/${restroId}/${adminId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Plans"],
    }),
    updatePlan: builder.mutation({
      query: ({ data, planId, restroId }) => ({
        url: `update/plan/${planId}/${restroId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Plans"],
    }),
    deletePlan: builder.mutation({
      query: ({ data, planId }) => ({
        url: `disable/plan/${planId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Plans"],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useDeletePlanMutation,
  useGetPlanByIdQuery,
} = planApi;
