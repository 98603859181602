import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#ff6d5f",
    },
    info: {
      main: "#333333",
    },
    text: {
      main: "#07143B",
    },
    black: {
      main: "#000000",
    },
    white: {
      main: "#ffffff",
    },
    gray: {
      main: "rgba(68, 68, 68, 1)",
      border: "#9E9E9E",
      gray2: "#E3E1E1"
    },
  },
  typography: {
    fontFamily: "Lexend",
    // fontFamily: 'Poppins',
  },
});
