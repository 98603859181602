import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SkSelector = ({
  handleChange,
  values,
  data,
  name,
  multiple,
  label,
  disabled,
  icons,
  width,
}) => {
  //   const dispatch = useDispatch();
  return (
    <Select
      displayEmpty
      disabled={disabled}
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      value={values}
      label={label}
      onChange={handleChange}
      input={
        <TextField
          size="small"
          select
          autoComplete="off"
          variant="outlined"
          value={values}
          name={name}
          label={label}
          sx={{
            "& .MuiInputBase-root": {
              //   height: 40,
              width: width ? width : "100%",
              border: "none",
              outline: "none",
              borderColor: "GrayText",
              bgcolor: "rgba(221, 218, 218, 0.15)",
              color: "black.main",
            },
            "& .MuiFormHelperText-root": {
              mx: 0,
            },
            // "& .MuiOutlinedInput-notchedOutline": {
            //   outline: "none",
            //   border: "none",
            // },
          }}
          InputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
            startAdornment: (
              <InputAdornment position="start">{icons}</InputAdornment>
            ),
          }}
        />
      }
      //   renderValue={(selected) => selected.join(", ")}
      MenuProps={MenuProps}
    >
      <MenuItem value="">{label}</MenuItem>

      {multiple
        ? data &&
          data.length > 0 &&
          data.map((name, index) => (
            <MenuItem key={index} value={name?._id}>
              <ListItemText primary={name?.planName} />
            </MenuItem>
          ))
        : data &&
          data.length > 0 &&
          data.map((name, index) => (
            <MenuItem key={index} value={name}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
    </Select>
  );
};

export default SkSelector;
