import React, { useState } from "react";
import Base from ".";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import { handleAlert } from "../../Apis/features/alertSlice";
import { setOtp } from "../../Apis/features/authSlice";
import { useCheckSendOtpMutation } from "../../Apis/reduxRTK/api";
import Heading from "../../examples/Heading";
import { useGetCompanyQuery } from "../../Apis/reduxRTK/companyApi";
import { ArrowCircleLeftOutlined, ArrowLeft, ArrowLeftOutlined } from "@mui/icons-material";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendOtpMutation] = useCheckSendOtpMutation();
  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { state } = useLocation();
  // console.log(state);
  const handleSubmitMobile = async (values) => {
    setIsSubmitting(true);
    try {
      const sendOtpUsers = await sendOtpMutation({
        phone: values?.phoneNumber?.toString(),
        check: true,
      });
      setIsSubmitting(false);
      if (sendOtpUsers?.data?.success) {
        navigate("/verify", {
          state: {
            data: sendOtpUsers?.data?.otp,
            phone: sendOtpUsers?.data?.data?.phone,
            isLogin: true,
          },
        });
        dispatch(setOtp(sendOtpUsers?.data?.otp));
      } else {
        navigate("/singup", { state: { data: values?.phoneNumber } });
      }
      // console.log(sendOtpUsers);
      dispatch(
        handleAlert({
          isOpen: sendOtpUsers?.data?.success,
          type: sendOtpUsers?.data?.success ? "success" : "error",
          msg: sendOtpUsers?.data?.message,
        })
      );
      if (sendOtpUsers?.error) {
        setApiError(sendOtpUsers?.error?.data?.message);
        setTimeout(() => {
          setApiError(null);
        }, 5000);
        dispatch(
          handleAlert({
            isOpen: sendOtpUsers?.error?.data?.success,
            type: sendOtpUsers?.error?.data?.success ? "success" : "error",
            msg: sendOtpUsers?.error?.data?.message,
          })
        );
      } else {
        setApiError(null);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
    // dispatch(
    //   registerUsers({
    //     url: `${process.env.REACT_APP_API}/login/customer`,
    //     data: {
    //       ...values,
    //       hashKey: process.env.REACT_APP_HASHKEY,
    //       customerId: localStorage.getItem("isUserIdDummy") || "",
    //       customerFcmToken: localStorage.getItem("customerFcmToken"),
    //     },
    //   })
    // ).then((data) => {
    //   // console.log(data, "data");
    //   if (!data?.payload?.success) {
    //     dispatch(
    //       handleAlert({
    //         isOpen: data?.payload?.message === "User Not Found" ? false : true,
    //         type: data?.payload?.success ? "success" : "error",
    //         msg: data?.payload?.message,
    //       })
    //     );
    //     setMobileNumber(values);
    //     setClick(false);
    //   }
    //   if (data?.payload?.success) {
    //     dispatch(
    //       registerUsers({
    //         url: `${process.env.REACT_APP_API}/sendOtp`,
    //         data: {
    //           ...values,
    //           check: check,
    //         },
    //       })
    //     ).then((isData) => {
    //       // console.log(isData, "isData");

    //       dispatch(
    //         handleAlert({
    //           isOpen: true,
    //           type: isData?.payload?.success ? "success" : "error",
    //           msg: isData?.payload?.message,
    //         })
    //       );
    //       if (isData?.payload?.success) {
    //         localStorage.setItem("isOtp", isData?.payload?.otp);
    //         setIsShow(false);
    //         setOtpVerify(true);
    //         sendOTP();
    //         localStorage.setItem("isUserId", data?.payload?.data?._id);
    //         localStorage.setItem("isToken", data?.payload?.data?.token);
    //         localStorage.setItem(
    //           "credential",
    //           JSON.stringify({
    //             phoneNumber: data?.payload?.data?.phoneNumber,
    //             check: check,
    //           })
    //         );
    //       }
    //     });
    //   }
    // });
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .min(10)
      .max(10)
      .matches(phoneRegex, "Invalid phone")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .required("Phone is required"),
  });
  const initialValues = {
    phoneNumber: state?.phone || "",
  };

  const { data: company } = useGetCompanyQuery();

  return (
    <Base>
      <Formik
        onSubmit={handleSubmitMobile}
        initialValues={initialValues}
        validationSchema={isMobleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              color: "white.main",
              p: 2.5,
              gap: 2,
              mt: 15,
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
           
            
            <Heading
              text={"Login & Register"}
              color="white.main"
              isColor="#fff"
              fontSize={"1.5rem"}
            />
           
            <Box
              sx={{
                mt: 1,
                width: "85%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="large"
                fullWidth
                type={"number"}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={
                  (!!touched.phoneNumber && !!errors.phoneNumber) || !!apiError
                }
                helperText={
                  (touched.phoneNumber && errors.phoneNumber) || apiError
                }
                label="Phone Number"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "white.main",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "white.main",
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                 
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Typography variant="body1" color="inherit" width="85%">
              By Continuing. you agree to Thali App&nbsp;
              <strong
                style={{ cursor: "pointer", "&:hover": { color: "yellow" } }}
                onClick={() => {
                  navigate("/policy", { state: company?.data?.term_condition });
                }}
              >
                {" "}
                Terms of use
              </strong>
              &nbsp; and &nbsp;
              <strong
                style={{ cursor: "pointer", "&:hover": { color: "yellow" } }}
                onClick={() => {
                  navigate("/policy", { state: company?.data?.privacy_policy });
                }}
              >
                privacy policy
              </strong>
            </Typography>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "85%",
                textTransform: "capitalize",
                mt: 4,
                height: "2.8rem",
                borderRadius: 3,
                color: "primary.main",
                bgcolor: "white.main",
                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: "white.main",
                },
              }}
            >
              {isSubmitting ? "Submitting....." : "Submit"}
            </Button>
          </Box>
        )}
      </Formik>
    </Base>
  );
};

export default SignIn;
