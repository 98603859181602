import { InputBase } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getSearchName } from "../../Apis/features/restaurantSlice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #E3E1E1",

  borderRadius: 12,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
}));
const SearchInput = ({ state, setState }) => {
  const { searchName } = useSelector((state) => state.isRestaurant);
  const dispatch = useDispatch();
  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          type="search"
          value={searchName}
          onChange={(e) => dispatch(getSearchName(e.target.value))}
          sx={{
            width: "100%",
          }}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </>
  );
};

export default SearchInput;
