import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = ({ text, isColor, ...rest }) => {
  return (
    <Box
      sx={{
        width: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: 0.5,
        mb: 1,
      }}
    >
      <Typography variant="h5" color="initial" fontWeight={700} {...rest}>
        {text}
      </Typography>
      <Box
        sx={{
          width: " calc(100% + 30px)",
          // backgroundColor: "primary.main",
          borderRadius: "50%",
          background: isColor ? isColor : "#FF9976",
          height: "3px",
          boxShadow: "0px 11px 19px rgba(0, 0, 0, 0.25)",
        }}
      />
    </Box>
  );
};

export default Heading;
