import React from "react";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Typography, TextField } from "@mui/material";

const SkDatePicker = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  apiError,
  isName,
  disabled,
}) => {
  const disablePastAndFutureDates = (date) => {
    const today = moment().startOf("day");
    const oneMonthFromNow = today.clone().add(6, "month");
    return date.isBefore(today) || date.isAfter(oneMonthFromNow);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        value={values ? moment(values, "YYYY-MM-DD") : null}
        onChange={(date) => {
          const formattedDate = moment(date);
          handleChange({
            target: {
              name: isName,
              value: formattedDate.format("YYYY-MM-DD HH:mm:ss"),
            },
          });
        }}
        format="YYYY-MM-DD"
        disabled={disabled}
        label="Start Date"
        inputProps={{
          min: moment().format("YYYY-MM-DD"),
        }}
        shouldDisableDate={disablePastAndFutureDates}
        variant="outlined"
        // slotProps={{
        //   textField: {
        //     InputProps: {
        //       endAdornment: (
        //         <InputAdornment position="end" sx={{ mr: 2 }}>
        //           {CalenderIcon}
        //         </InputAdornment>
        //       ),
        //     },
        //   },
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            className="datepicker-input"
            variant="outlined"
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            name={isName}
            onBlur={handleBlur}
            error={(!!touched && !!errors) || !!apiError}
            helperText={(touched && errors) || apiError}
          />
        )}
        sx={{
          input: { cursor: "pointer", padding: "8.5px 14px" },

          "& .MuiOutlinedInput-root": {
            width: "100%",
            p: 0,
            border: "none",
            outline: "none",
            borderColor: "GrayText",
            bgcolor: "rgba(221, 218, 218, 0.15)",
            color: "black.main",
          },
          "& .MuiFormHelperText-root": {
            mx: 0,
          },
          // "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
          //   padding: "8.5px 14px",
          // },
          // "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
          //   padding: "8.5px 14px",
          // },
          "& .MuiInputLabel-outlined": {
            top: "-20%",
          },
          // "& .MuiOutlinedInput-notchedOutline": {
          //   outline: "none",
          //   border: "none",
          // },
        }}
      />
      <Typography
        variant="caption"
        className="MuiFormHelperText-root"
        sx={{ color: "primary.main" }}
      >
        {(touched && errors) || apiError}
      </Typography>
    </LocalizationProvider>
  );
};

export default SkDatePicker;
