import { useFormik } from "formik";
// import * as yup from 'yup';

// import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { handleAlert } from "../../Apis/features/alertSlice";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "../../examples/Heading";
import SkDatePicker from "../../components/SkDatepicker";
import { useGetPlansQuery } from "../../Apis/reduxRTK/planApi";
import SkMenu from "../../components/SkMenu";
import { useUpdateMemberRenewalPlanMutation } from "../../Apis/reduxRTK/memberApi";
import {
  handlePlanModel,
  handleRenewalPlanModel,
} from "../../Apis/features/planSlice";
import moment from "moment";
import { SkPrice } from "../../extras/dataFunction";
import { CurrencyRupee } from "@mui/icons-material";

// const validationSchema = yup.object().shape({
//   name: yup
//     .string()
//     .matches(
//       /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
//       "Name can only contain Latin letters."
//     )

//     .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
//     .min(2, "Too Short!")
//     .max(256, "Too Long!")
//     .required("Full name is required"),

//   startDate: yup.string().required("Start Date is required"),
//   endDate: yup.string().required("End Date is required"),
//   dueAmount: yup.required("Due Amount is required"),
// });
const RenewalPlan = () => {
  const { singleRestaurant } = useSelector((state) => state.isRestaurant);
  const { isPlanData, isMemberPlanData } = useSelector((state) => state.isPlan);
  const { isUpdateDetails } = useSelector((state) => state.isModal);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(null);
  // const [paymentStatus, setPaymentStatus] = useState(false);

  // console.log(isMemberPlanData, "isMemberPlanData");
  // console.log(isPlanData, "isUpdateDetails", singleRestaurant);
  const dispatch = useDispatch();
  const { data: plans, isSuccess } = useGetPlansQuery({
    restroId: singleRestaurant?._id,
  });
  // console.log(plans, "plans");
  const [updateRenewalPlan] = useUpdateMemberRenewalPlanMutation();
  const initialValues = {
    plan: "",
    startDate: "",
    endDate: "",
    isEndDate: "",
    paymentStatus: "",
    dueAmount: "",
    startDate2: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("Values", values); // moment(values?.startDate).format("YYYY-MM-DD HH:mm:ss")
      setIsSubmitting(true);
      const ammount = calculatePrice(isPlanData?.amount, values.dueAmount);
      try {
        const data = {
          startDate: values?.startDate2,
          endDate: values?.isEndDate,
          plan: values?.plan,
          dueAmount: ammount,
          // paymentStatus: false,
        };

        const res = await updateRenewalPlan({
          data: data,
          memberId: isUpdateDetails?._id,
          restroId: singleRestaurant?._id,
        });
        // console.log(res, "ress");
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message || res?.error?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(handleRenewalPlanModel(false));
          dispatch(handlePlanModel({ data: null }));
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          dispatch(handlePlanModel({ data: null }));
          formik.setFieldValue("plan", "");
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    },
  });
  const calculatePrice = (isPlanData, value) => {
    if (isPlanData >= value) {
      return isPlanData - value;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    const isEndDate = () => {
      if (isMemberPlanData) {
        const expiry = isMemberPlanData?.daysRemaining || 0;
        const today = moment();
        const currentDate = moment(today, "YYYY-MM-DD");
        const currentDate2 = moment(today, "YYYY-MM-DD HH:mm:ss");

        if (!isNaN(expiry)) {
          const endDate = currentDate.add(expiry, "days").format("YYYY-MM-DD");
          const endDate2 = currentDate2
            .add(expiry, "days")
            .format("YYYY-MM-DD HH:mm:ss");

          formik.setFieldValue("startDate2", endDate2);
          return endDate;
        }
      }
      return "";
    };
    const startDate = isEndDate();
    // console.log(startDate, isMemberPlanData?.daysRemaining);
    if (isPlanData) {
      formik.setFieldValue("startDate", startDate);
      formik.setFieldValue("dueAmount", isPlanData?.amount);
      // console.log(today, "today");
    } else {
      formik.setFieldValue("startDate", "");
      formik.setFieldValue("dueAmount", "");
    }
  }, [isPlanData]);

  useEffect(() => {
    const isEndDate = () => {
      if (isPlanData && formik.values.startDate) {
        const expiry = isPlanData?.expiry;
        const currentDate = moment(formik.values.startDate, "YYYY-MM-DD");
        const currentDate2 = moment(
          formik.values.startDate,
          "YYYY-MM-DD HH:mm:ss"
        );

        if (!isNaN(expiry)) {
          const endDate = currentDate.add(expiry, "days").format("YYYY-MM-DD");
          const endDate2 = currentDate2
            .add(expiry, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          formik.setFieldValue("isEndDate", endDate2);
          return endDate;
        }
      }
      return "";
    };

    const endDate = isEndDate();
    if (isPlanData) {
      formik.setFieldValue("endDate", endDate);
    } else {
      formik.setFieldValue("endDate", "");
    }
  }, [formik.values.startDate, isPlanData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          color: "primary.main",
          p: 2.5,
          gap: 2,
          overflowY: "scroll",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Heading
          text={"Renewal Plan"}
          color="primary.main"
          fontSize={"1.5rem"}
        />

        <Box
          sx={{
            width: "90%",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            size="small"
            fullWidth
            type={"text"}
            disabled
            required
            autoComplete="off"
            variant="outlined"
            // onBlur={formik.handleBlur}
            // onChange={formik.handleChange}
            value={isUpdateDetails?.name || ""}
            name="name"
            // error={
            //   (!!formik.touched.name && !!formik.errors.name) || !!apiError
            // }
            // helperText={(formik.touched.name && formik.errors.name) || apiError}
            label="Full Name"
            sx={{
              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "GrayText",
                bgcolor: "rgba(221, 218, 218, 0.15)",
                color: "black.main",
              },
              "& .MuiFormHelperText-root": {
                mx: 0,
              },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "90%",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SkMenu
            data={isSuccess && plans?.data}
            values={formik.values.plan}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name={"plan"}
            touched={formik.touched.plan}
            errors={formik.errors.plan}
            simplySelect={true}
            apiError={apiError}
          />
        </Box>

        <Box
          sx={{
            width: "90%",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SkDatePicker
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            values={formik.values.startDate}
            touched={formik.touched.startDate}
            errors={formik.errors.startDate}
            apiError={apiError}
            isName="startDate"
            disabled={!isPlanData}
          />
        </Box>
        <Box
          sx={{
            width: "90%",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {" "}
          <TextField
            size="small"
            fullWidth
            disabled
            type={"text"}
            required
            autoComplete="off"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.endDate}
            name="endDate"
            error={
              (!!formik.touched.endDate && !!formik.errors.endDate) ||
              !!apiError
            }
            helperText={
              (formik.touched.endDate && formik.errors.endDate) || apiError
            }
            label="End Date"
            sx={{
              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "GrayText",
                bgcolor: "rgba(221, 218, 218, 0.15)",
                color: "black.main",
              },

              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "90%",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <TextField
            size="small"
            fullWidth
            type={"number"}
            required
            disabled
            autoComplete="off"
            variant="outlined"
            // onBlur={formik.handleBlur}
            // onChange={formik.handleChange}
            value={isPlanData?.amount || ""}
            name="dueAmount"
            // error={
            //   (!!formik.touched.dueAmount && !!formik.errors.dueAmount) ||
            //   !!apiError
            // }
            // helperText={
            //   (formik.touched.dueAmount && formik.errors.dueAmount) || apiError
            // }
            label="amount"
            sx={{
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "textfield",
                },
              },

              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "GrayText",
                bgcolor: "rgba(221, 218, 218, 0.15)",
                color: "black.main",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                border: "none",
              },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          /> */}

          {/* <FormControlLabel
            // required
            control={
              <Checkbox
                checked={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Money Already Paid"
          /> */}

          <TextField
            size="small"
            fullWidth
            type={"number"}
            required
            autoComplete="off"
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            // value={isPlanData?.amount || ""}
            value={formik.values.dueAmount}
            name="dueAmount"
            error={
              (!!formik.touched.dueAmount && !!formik.errors.dueAmount) ||
              !!apiError
            }
            helperText={
              (formik.touched.dueAmount && formik.errors.dueAmount) || apiError
            }
            label="Enter amount"
            sx={{
              "& .MuiOutlinedInput-input": {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  "-moz-appearance": "textfield",
                },
              },

              "& .MuiInputBase-root": {
                //   height: 40,
                border: "none",
                outline: "none",
                borderColor: "GrayText",
                bgcolor: "rgba(221, 218, 218, 0.15)",
                color: "black.main",
              },

              // "& .MuiOutlinedInput-notchedOutline": {
              //   outline: "none",
              //   border: "none",
              // },
            }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee />
                </InputAdornment>
              ),
            }}
          />
          <Typography
            variant="caption"
            color="error"
            fontSize={12}
            fontWeight={600}
          >
            {isPlanData?.amount >= formik.values.dueAmount
              ? null
              : `Due Amount cannot exceed ${isPlanData?.amount || 0}`}
          </Typography>
          <Box
            sx={{
              width: "100%",

              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="caption"
              color="initial"
              fontSize={12}
              fontWeight={600}
            >
              Total Amount :
              <Typography
                variant="caption"
                color="rgba(21, 224, 29, 1)"
                fontSize={12}
                fontWeight={600}
                mx={1}
              >
                {SkPrice(isPlanData?.amount || 0)}
              </Typography>
            </Typography>{" "}
            <Typography
              variant="caption"
              color="initial"
              fontSize={12}
              fontWeight={600}
            >
              Due Amount :
              <Typography
                variant="caption"
                color="rgba(250, 74, 12, 1)"
                fontSize={12}
                fontWeight={600}
                mx={1}
              >
                {SkPrice(
                  calculatePrice(isPlanData?.amount, formik.values.dueAmount)
                )}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: "90%",
            textTransform: "capitalize",
            mt: 1,
            height: "2.8rem",
            borderRadius: 10,
            bgcolor: "primary.main",
            color: "white.main",
            boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              bgcolor: "primary.main",
              color: "white.main",
            },
          }}
          disabled={
            isSubmitting || isPlanData?.amount <= formik.values.dueAmount === 0
              ? true
              : isPlanData?.amount < formik.values.dueAmount
          }
        >
          {isSubmitting ? "Submitting....." : "Submit"}
        </Button>
      </Box>
    </form>
  );
};

export default RenewalPlan;
