// api.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;
export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getUsers: builder.query({
      query: () => "users",
    }),
    logInUser: builder.mutation({
      query: ({ data }) => ({
        url: "login/user",
        method: "POST",
        body: data,
      }),
    }),
    createUser: builder.mutation({
      query: ({ data }) => ({
        url: "register",
        method: "POST",
        body: data,
      }),
    }),
    checkSendOtp: builder.mutation({
      query: ({ data, phone, check }) => ({
        url: `check&sendOtp?check=${check}&phone=${phone}&hashKey=${process.env.REACT_APP_HASHKEY}`,
        method: "POST",
        body: data,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ userId, updatedUser }) => ({
        url: `users/${userId}`,
        method: "PUT", // or 'PATCH' depending on your API
        body: updatedUser,
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `users/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useLogInUserMutation,
  useCheckSendOtpMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = api;
