import React from "react";
// import BasicModal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { handleMemberModel } from "../../Apis/features/memberSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
// import SKTimePicker from "../../components/SkTimePicker";
import SkDatePicker from "../../components/SkDatepicker";
import SkMenu from "../../components/SkMenu";
import { useGetPlansQuery } from "../../Apis/reduxRTK/planApi";
import { useEffect } from "react";
import {
  useCreateMemberMutation,
  useGetMemberCountByRestIdQuery,
  // useGetMemberPhoneQuery,
} from "../../Apis/reduxRTK/memberApi";
// import dayjs from "dayjs";

// import http from "../../extras/Api";
import { handleAlert } from "../../Apis/features/alertSlice";
import {
  // getMobileDetails,
  handlePlanModel,
} from "../../Apis/features/planSlice";
import { SkPrice } from "../../extras/dataFunction";
import axios from "axios";
import Heading from "../../examples/Heading";
import moment from "moment/moment";
import { CurrencyRupee } from "@mui/icons-material";
import { CalenderIcon } from "../../assets/SVG";

const NewMemeber = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [apiError, setApiError] = useState(null);
  // const [phoneNumber, setPhoneNumber] = useState(null);

  const dispatch = useDispatch();

  const { title, isMemberShow } = useSelector((state) => state.isMember);
  const { singleRestaurant } = useSelector((state) => state.isRestaurant);
  const { isPlanData } = useSelector((state) => state.isPlan);
  // console.log("isPhoneData", new Date("07-10-2023"));
  // console.log("isPlanData", isPlanData);
  const { data: plans, isSuccess } = useGetPlansQuery({
    restroId: singleRestaurant?._id,
  });
  useEffect(() => {
    if (isMemberShow && isSuccess && plans?.data && plans?.data?.length === 0) {
      dispatch(handlePlanModel({ show: true, title: false }));
    }
  }, [isMemberShow, isSuccess]);
  const { data: totalCount } = useGetMemberCountByRestIdQuery({
    restroId: singleRestaurant?._id,
  });

  const [createMember] = useCreateMemberMutation();

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),

    name: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )

      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),

    email: yup
      .string()

      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
    plan: yup.string().required("Plan is required"),
    // dueAmount: yup.string().required("Plan is required"),
    startDate: yup.string().required("Start Date is required"),
    endDate: yup.string().required("End Date is required"),
    dueAmount: yup
      .number()
      .min(0, "Due Amount cannot be negative")
      .max(
        isPlanData?.amount || 0,
        `Due Amount cannot exceed ${isPlanData?.amount || 0}`
      )
      .test("isValidDueAmount", "Invalid due amount", function (value) {
        // Use the provided value from the event (e.target.value)
        const enteredValue = parseFloat(value);
        const calculatedDueAmount =
          isPlanData?.amount >= enteredValue
            ? isPlanData?.amount - enteredValue
            : 0;

        // Only return true if the condition is met
        return enteredValue === calculatedDueAmount || true;
      })
      .required("Due Amount is required"),
  });
  const initialValues = {
    phone: "",
    name: "",
    email: "",
    plan: "",
    startDate: "",
    endDate: "",
    dueAmount: "",
    isEndDate: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("Values", values);

      setIsSubmitting(true);
      try {
        // const startDate = new Date(parseInt(values?.startDate)).toISOString();
        // const endDate = new Date().toISOString();

        const formData = new FormData();
        const ammount = calculatePrice(isPlanData?.amount, values?.dueAmount);
        formData.append("phone", values?.phone.toString());
        formData.append("name", values?.name);
        formData.append("email", values?.email);
        formData.append("plan", values?.plan);
        formData.append(
          "startDate",
          moment(values?.startDate).format("YYYY-MM-DD HH:mm:ss")
        );
        // formData.append("startDate", dayjs(values?.startDate).toISOString());
        formData.append("endDate", values?.isEndDate);
        // formData.append("endDate", dayjs(values?.endDate).toISOString());
        formData.append("dueAmount", ammount);
        formData.append("photo", images);
        // console.log(ammount, "fomrnik", values?.dueAmount);
        const res = await createMember({
          data: formData,
          adminId: localStorage.getItem("authId"),
          restroId: singleRestaurant?._id,
        });
        // console.log(res, "ress");
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(handleMemberModel({ show: false }));
          dispatch(handlePlanModel({ data: null }));
          // dispatch(handlePlanModel({ data: name }));
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    },
  });
  // console.log("date" , formik.values.startDate)
  const handleMobleSearch = async (e) => {
    const phone = e.target.value;
    if (phone?.length === 10) {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API}/check/member/by/phone/${singleRestaurant?._id}?phone=${phone}`,
          config
        );
        const data = res?.data?.data;
        // console.log(res, "rescheck/member/by/phone");
        // console.log(singleRestaurant?._id, "singleRestaurant?._id");
        if (res?.data?.success) {
          if (res?.data?.data?.restaurantId === singleRestaurant?._id) {
            dispatch(
              handleAlert({
                isOpen: true,
                type: "error",
                msg: res?.data?.message,
              })
            );
            formik.setFieldError("phone", res?.data?.message);
            setLoading(true); // Set loading to false on error
            formik.setFieldValue("name", "");
            formik.setFieldValue("email", "");
          } else {
            formik.setFieldValue("name", data?.name);
            formik.setFieldValue("email", data?.email);
            setLoading(false); // Set loading to false on success
          }
        }
        // console.log(res);
      } catch (error) {
        console.error("Error in handleMobleSearch:", error);
        setLoading(false);
        formik.setFieldValue("name", "");
        formik.setFieldValue("email", "");
      }
    }
  };

  useEffect(() => {
    if (isPlanData) {
      const today = moment(); //.format("YYYY-MM-DD HH:mm:ss");
      formik.setFieldValue("startDate", today);
      formik.setFieldValue("dueAmount", isPlanData?.amount);
      // console.log(today, "today");
    } else {
      formik.setFieldValue("startDate", "");
      formik.setFieldValue("dueAmount", "");
    }
  }, [isPlanData]);

  useEffect(() => {
    // const isEndDate = () => {
    //   if (isPlanData && formik.values.startDate) {
    //     const expiry = isPlanData?.expiry;
    //     const currentDate = dayjs(formik.values.startDate, "YYYY-MM-DD");

    //     if (!isNaN(expiry)) {
    //       const endDate = currentDate.add(expiry, "days").format("YYYY-MM-DD");
    //       // console.log("endDatebyfun", endDate);
    //       return endDate;
    //     }
    //   }
    //   return "";
    // };

    // const endDate = isEndDate();
    // formik.setFieldValue("endDate", endDate);
    // console.log(formik.values.startDate, "formik.values.startDate");
    const isEndDate = () => {
      if (isPlanData && formik.values.startDate) {
        const expiry = isPlanData?.expiry;
        const currentDate = moment(formik.values.startDate, "YYYY-MM-DD");
        const currentDate2 = moment(
          formik.values.startDate,
          "YYYY-MM-DD HH:mm:ss"
        );

        if (!isNaN(expiry)) {
          const endDate = currentDate.add(expiry, "days").format("YYYY-MM-DD");
          const endDate2 = currentDate2
            .add(expiry, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          // console.log("endDatebyfun", endDate);
          formik.setFieldValue("isEndDate", endDate2);
          return endDate;
        }
      }
      return "";
    };
    // console.log(isPlanData, "isPlanData");
    const endDate = isEndDate();
    if (isPlanData) {
      formik.setFieldValue("endDate", endDate);
    } else {
      formik.setFieldValue("endDate", "");
    }
  }, [formik.values.startDate, isPlanData]);

  const calculatePrice = (isPlanData, value) => {
    if (isPlanData >= value) {
      return isPlanData - value;
    } else {
      return 0;
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            color: "primary.main",
            p: 2.5,
            gap: 1.8,
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography
            variant="body1"
            fontWeight={600}
            color="initial"
            component={"span"}
            sx={{
              position: "absolute",
              top: 0,
              left: 12,
              backgroundColor: "primary.main",
              borderRadius: "  0 0 30%  30% ",
              p: 1.5,
              color: "white.main",
            }}
          >
            id : {totalCount?.data || 0}
          </Typography>

          <Heading
            text={title ? "Update Member" : "Add Member"}
            color="primary.main"
            fontSize={"1.5rem"}
          />
          <Box
            sx={{
              mt: 0.6,
              height: "5.5rem",
              width: "5.5rem",
              // border: 2,
              // borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              my: 1.5,
            }}
          >
            {isUploading ? (
              <Avatar
                sx={{
                  height: "5.5rem",
                  width: "5.5rem",
                  border: 2,
                  borderColor: "primary.main",
                  background: "transparent",
                }}
              >
                <CircularProgress color="primary" />
              </Avatar>
            ) : (
              <>
                <label htmlFor="imageuploader">
                  {" "}
                  <img
                    src={require("../../assets/images/edit2.png")}
                    alt="edit button"
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      position: "absolute",
                      bottom: "0",
                      right: "0 ",
                      cursor: "pointer",
                      borderRadius: "50%",
                      zIndex: 10,
                    }}
                  />
                </label>
                <input
                  // required
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleSelectImage}
                  id={"imageuploader"}
                />
                <Avatar
                  sx={{
                    height: "5.5rem",
                    width: "5.5rem",
                    border: 2,
                    borderColor: "primary.main",
                  }}
                  src={
                    previewImage
                      ? previewImage
                      : // : isUpdateDetails
                        // ? `${process.env.REACT_APP_URI}/${isUpdateDetails?.photo}`
                        null
                  }
                />
              </>
            )}
          </Box>
          <input
            // required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader"}
          />

          {/* <label htmlFor="imageuploader">
            {" "}
            <Box
              sx={{
                height: "5rem",
                width: "7.5rem",
                my: 0.5,
                borderRadius: 4,
                border: 2,
                borderColor: "white.main",
                background: "rgba(217, 217, 217, 0.52)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {isUploading ? (
                <CircularProgress color="white" />
              ) : previewImage ? (
                <img
                  src={previewImage}
                  alt="edit button"
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = require("../../assets/images/profile.png");
                  }}
                  style={{
                    height: "5rem",
                    width: "7.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              ) : (
                <img
                  src={require("../../assets/images/cameraupload.png")}
                  alt="edit button"
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    // position: "absolute",
                    // bottom: "0",
                    // right: "0 ",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    zIndex: 10,
                  }}
                />
              )}
            </Box>
          </label>
          <input
            // required
            type="file"
            accept="image/*"
            hidden
            onChange={handleSelectImage}
            id={"imageuploader"}
          /> */}
          <Box
            sx={{
              width: "90%",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",

              // flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "48%",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                fullWidth
                type={"number"}
                required
                autoComplete="off"
                variant="outlined"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleMobleSearch(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                value={formik.values.phone}
                name="phone"
                error={
                  (!!formik.touched.phone && !!formik.errors.phone) ||
                  !!apiError
                }
                helperText={
                  (formik.touched.phone && formik.errors.phone) || apiError
                }
                label="Enter Phone"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },

                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "48%",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                fullWidth
                type={"text"}
                required
                disabled={Loading}
                autoComplete="off"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                error={
                  (!!formik.touched.name && !!formik.errors.name) || !!apiError
                }
                helperText={
                  (formik.touched.name && formik.errors.name) || apiError
                }
                label="Full Name"
                sx={{
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              // flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "48%",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                fullWidth
                type={"email"}
                required
                disabled={Loading}
                autoComplete="off"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                error={
                  (!!formik.touched.email && !!formik.errors.email) ||
                  !!apiError
                }
                helperText={
                  (formik.touched.email && formik.errors.email) || apiError
                }
                label="Email"
                sx={{
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },

                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "48%",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <SkMenu
                data={isSuccess && plans?.data}
                values={formik.values.plan}
                disabled={Loading}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                name={"plan"}
                touched={formik.touched.plan}
                errors={formik.errors.plan}
                simplySelect={true}
                apiError={apiError}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              // flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "48%",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <SkDatePicker
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values.startDate}
                touched={formik.touched.startDate}
                errors={formik.errors.startDate}
                apiError={apiError}
                isName="startDate"
                disabled={!isPlanData || Loading}
              />
              <span style={{ position: "absolute", right: "5%", top: "25%" }}>
                {CalenderIcon}
              </span>
            </Box>
            <Box
              sx={{
                width: "48%",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: 1,
                borderColor: "primary.main",
                backgroundColor: "rgba(221, 218, 218, 0.15)",
                color: "primary.main",
                height: "2.5rem",
                borderRadius: 1,
              }}
            >
              {formik.values.endDate || "End Date"}
              {/* <TextField
                size="small"
                fullWidth
                type={"text"}
                required
                disabled={!formik.values.endDate || Loading}
                autoComplete="off"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.endDate}
                name="endDate"
                error={
                  (!!formik.touched.endDate && !!formik.errors.endDate) ||
                  !!apiError
                }
                helperText={
                  (formik.touched.endDate && formik.errors.endDate) || apiError
                }
                label="End Date"
                sx={{
                  "& .MuiInputBase-root": {
                    //   height: 40,

                    // borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "primary.main",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#ff6d5f",
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  readOnly: true,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              /> */}
            </Box>
          </Box>
          <Box
            sx={{
              width: "90%",

              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <TextField
              size="small"
              fullWidth
              type={"number"}
              required
              disabled={Loading}
              autoComplete="off"
              variant="outlined"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.dueAmount}
              name="dueAmount"
              error={
                (!!formik.touched.dueAmount && !!formik.errors.dueAmount) ||
                !!apiError
              }
              helperText={
                (formik.touched.dueAmount && formik.errors.dueAmount) ||
                apiError
              }
              label="Enter amount"
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },

                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },

                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupee />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                width: "100%",

                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="caption"
                color="initial"
                fontSize={12}
                fontWeight={600}
              >
                Total Amount :
                <Typography
                  variant="caption"
                  color="rgba(21, 224, 29, 1)"
                  fontSize={12}
                  fontWeight={600}
                  mx={1}
                >
                  {SkPrice(isPlanData?.amount || 0)}
                </Typography>
              </Typography>{" "}
              <Typography
                variant="caption"
                color="initial"
                fontSize={12}
                fontWeight={600}
              >
                Due Amount :
                <Typography
                  variant="caption"
                  color="rgba(250, 74, 12, 1)"
                  fontSize={12}
                  fontWeight={600}
                  mx={1}
                >
                  {SkPrice(
                    calculatePrice(isPlanData?.amount, formik.values.dueAmount)
                  )}
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "90%",
              height: "2.8rem",
              textTransform: "capitalize",
              mt: 2,
              borderRadius: 10,
              bgcolor: "primary.main",
              color: "white.main",
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white.main",
              },
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting....." : "Submit"}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default NewMemeber;
