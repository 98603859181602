import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const notificationAPI = createApi({
  reducerPath: "notificationAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Notification"],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getNotification: builder.query({
      query: ({ adminId }) => `get/all/notification/user/${adminId}`,
      providesTags: ["Notification"],
    }),
    deleteNotification: builder.mutation({
      query: ({ adminId }) => ({
        url: `delete/users/notification/${adminId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notification"],
    }),
    updateNotification: builder.mutation({
      query: ({ adminId }) => ({
        url: `update/users/seen/notification/${adminId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const {
  useDeleteNotificationMutation,
  useGetNotificationQuery,
  useUpdateNotificationMutation,
} = notificationAPI;
