import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { SkPrice } from "../../extras/dataFunction";
import { ArrowForwardIcon, BoxTriangle } from "../../assets/SVG";

const MemberPlanCard = ({ value, rate }) => {
//   console.log(value, "value");
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  return (
    <Card
      sx={{
        width: "100%",
        overflow: "visible",

        border: 0.2,
        borderColor: "gray.gray2",
        borderRadius: 3,
        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          backgroundColor: "primary.main",
          color: "white.main",
          px: 2.5,
          py: 1,
          position: "relative",
        }}
      >
        {rate && (
          <React.Fragment>
            <Box
              sx={{
                position: "absolute",
                top: "-49%",
                left: "4.5%",
              }}
            >
              {BoxTriangle}
            </Box>
            <Typography
              sx={{
                position: "absolute",
                top: "-39%",
                left: "14.5%",
              }}
              variant="caption"
              component="span"
              fontWeight={500}
              color="white"
            >
              Active Plan
            </Typography>
          </React.Fragment>
        )}
        <Typography
          variant="h6"
          sx={{
            maxWidth: "70%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value?.plan?.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            maxWidth: "70%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {SkPrice(value?.plan?.amount)}
        </Typography>
        {/* <CardActions style={{ padding: 0 }}></CardActions> */}
      </Box>
      <CardContent
        sx={{
          width: "100%",
          p: 0,
          px: 1.5,
          pt: 1,
          my: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          //   flexDirection: "column",
          gap: 1,
          "&:last-child": { pb: 1 },
        }}
      >
        <Typography variant="h6" fontWeight={600} color="initial">
          Plan Thali’s :{" "}
          <Typography
            variant="h6"
            component="span"
            fontWeight={500}
            color="primary"
          >
            {value?.thaliCount}/{value?.plan?.thaliCount}
          </Typography>
        </Typography>
        <Typography variant="h6" fontWeight={600} color="initial">
          Plan Days :{" "}
          <Typography
            variant="h6"
            component="span"
            fontWeight={500}
            color="primary"
          >
            {value?.plan?.expiry}
          </Typography>
        </Typography>
      </CardContent>
      <CardContent
        sx={{
          width: "100%",
          p: 0,
          px: 1.5,
          pt: 1,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          //   flexDirection: "column",
          gap: 1,
          "&:last-child": { pb: 0.9 },
        }}
      >
        <Box
          sx={{
            border: 0.5,
            borderColor: "primary.main",
            borderRadius: 1,
            py: 0.5,
            px: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            // component="span"
            fontWeight={500}
            color="primary"
          >
            {formatDate(value?.startDate)}
          </Typography>
        </Box>
        {ArrowForwardIcon}
        <Box
          sx={{
            border: 0.5,
            borderColor: "primary.main",
            borderRadius: 1,
            py: 0.5,
            px: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            // component="span"
            fontWeight={500}
            color="primary"
          >
            {formatDate(value?.endDate)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MemberPlanCard;
