import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Grid, useMediaQuery } from "@mui/material";
import Sidebar from "../examples/Sidebar";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const isMobile2 = useMediaQuery("(max-width:1100px)");
  const { auth } = useSelector((state) => state.isAuth);
  return auth || localStorage.getItem("auth") ? (
    <Box sx={{ minHeight: "100vh", display: "flex", width: "100%" }}>
      <Sidebar />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          background: "#FBF6F3",
          width: isMobile2 ? "100%" : "calc(100% -  265px)",
          pl: 0.5,
        }}
      >
        <Navbar />
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ flex: 1, my: 0.5 }}
        >
          <Grid item xs={12}>
            <main>{children}</main>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </Box>
  ) : (
    <main>{children}</main>
  );
};

export default Layout;
