// rootReducer.js
import { combineReducers } from "redux";
import { setupListeners } from "@reduxjs/toolkit/query/react"; // Import setupListeners
import { api } from "../reduxRTK/api"; // Import your RTK Query API Slice
import projectsSlice from "../features/projectsSlice";
import alertSlice from "../features/alertSlice";
import LoginSlice from "../features/LoginSlice";
import authSlice from "../features/authSlice";
import { restaurantApi } from "../reduxRTK/restaurantApi";
import restaurantSlice from "../features/restaurantSlice";
import { homeApi } from "../reduxRTK/homeApi";
import { memberApi } from "../reduxRTK/memberApi";
import { planApi } from "../reduxRTK/planApi";
import planSlice from "../features/planSlice";
import { adminApi } from "../reduxRTK/adminApi";
import memberSlice from "../features/memberSlice";
import { companyApi } from "../reduxRTK/companyApi";
import companySlice from "../features/companySlice";
import modalSlice from "../features/modalSlice";
import { notificationAPI } from "../reduxRTK/notificationApi";

const rootReducer = combineReducers({
  isProject: projectsSlice,
  isAlert: alertSlice,
  isLoginModal: LoginSlice,
  isAuth: authSlice,
  isRestaurant: restaurantSlice,
  isPlan: planSlice,
  isMember: memberSlice,
  isCompany: companySlice,
  isModal: modalSlice,
  [api.reducerPath]: api.reducer,
  [restaurantApi.reducerPath]: restaurantApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [memberApi.reducerPath]: memberApi.reducer,
  [planApi.reducerPath]: planApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [notificationAPI.reducerPath]: notificationAPI.reducer,
});

export default rootReducer;

setupListeners(
  api.middleware,
  restaurantApi.middleware,
  homeApi.middleware,
  memberApi.middleware,
  planApi.middleware,
  adminApi.middleware,
  companyApi.middleware,
  notificationAPI.middleware
); // Set up listeners for RTK Query
