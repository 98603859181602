import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: () => `company/getCompany`,
      providesTags: ["Company"],
    }),

    createCompany: builder.mutation({
      query: ({ data, adminId }) => ({
        url: `request/for/help/user/${adminId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const { useCreateCompanyMutation, useGetCompanyQuery } = companyApi;
