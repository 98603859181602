import ProtectedRoute from "./extras/ProtectedRoute";
import PublicRoute from "./extras/PublicRoute";
import ErrorPage from "./pages/ErrorPage";
import Members from "./pages/Members";
import Restaurant from "./pages/Restaurant";
import AddRestaurant from "./pages/Restaurant/AddRestaurant";
import Unauthorized from "./pages/Unauthorized";
import Profile from "./pages/User Authentication/Profile";
import SignIn from "./pages/User Authentication/SignIn";
import Verify from "./pages/User Authentication/Verify";
import Home from "./pages/home";
import Plans from "./pages/plans";
import {
  HomeIcon,
  HomeIcon2,
  MembersIcon,
  MembersIcon2,
  PlansIcon,
  PlansIcon2,
  ProfileIcon,
  ProfileIcon2,
  RestaurantIcon,
  RestaurantIcon2,
  helpIcon,
  helpIcon2,
} from "./assets/SVG";
import Signup from "./pages/User Authentication/Signup";
import Help from "./pages/Help";
import Policy from "./pages/Policys";

export const routes = [
  // { path: "signin", element: <SignIn /> },
  { path: "unauthorized", element: <Unauthorized /> },
  { path: "/policy", element: <Policy /> },
  // { path: "add_restaurant", element: <AddRestaurant /> },
  // { path: "register", element: <Register /> },
  // { path: "verify", element: <Verify /> },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Home />,
        icon: HomeIcon,
        icon2: HomeIcon2,
        name: "Home",
        key: "home",
        allowedRoles: ["ADMIN"],
      },
      {
        path: "plans",
        element: <Plans />,
        name: "Plans",
        icon: PlansIcon,
        icon2: PlansIcon2,
        key: "plan",
        allowedRoles: ["ADMIN"],
      },

      {
        path: "members",
        element: <Members />,
        name: "Members",
        icon: MembersIcon,
        icon2: MembersIcon2,
        key: "members",
        allowedRoles: ["ADMIN"],
      },
      {
        path: "restaurant",
        element: <Restaurant />,
        icon: RestaurantIcon,
        icon2: RestaurantIcon2,
        name: "Restaurant",
        key: "restaurant",
        allowedRoles: ["ADMIN"],
      },
      {
        path: "profile",
        element: <Profile />,
        icon: ProfileIcon,
        icon2: ProfileIcon2,
        name: "Profile",
        key: "profile",
        allowedRoles: ["ADMIN"],
      },

      // {
      //   // path: "notification",
      //   // element: <Restaurant />,
      //   icon: NotificationIcon,
      //   icon2: NotificationIcon2,
      //   name: "Notification",
      //   key: "notification",
      //   isSwitch: true,
      //   // allowedRoles: ["ADMIN"],
      // },
      {
        path: "help",
        element: <Help />,
        icon: helpIcon,
        icon2: helpIcon2,
        name: "Help",
        key: "help",
        allowedRoles: ["ADMIN"],
      },

      { path: "*", element: <ErrorPage /> },
      { path: "unauthorized", element: <Unauthorized /> },
    ],
  },
  {
    path: "/",
    element: <PublicRoute />,
    children: [
      { path: "signin", element: <SignIn /> },

      { path: "verify", element: <Verify /> },
      { path: "add_restaurant", element: <AddRestaurant /> },
      { path: "singup", element: <Signup /> },
      { path: "*", element: <ErrorPage /> },
    ],
  },
];
