import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const { auth } = useSelector((state) => state.isAuth);
  const isAuth = localStorage.getItem("auth");
  if (auth || isAuth) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export default PublicRoute;
