import {
  Box,
  Typography,
  Button,
  TextField,
  // Avatar,
  // CircularProgress,
  Container,
  Paper,
  Card,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import React from "react";
import {
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import Heading from "../../examples/Heading";
import { useState } from "react";
import {
  useCreateCompanyMutation,
  useGetCompanyQuery,
} from "../../Apis/reduxRTK/companyApi";
import { sanitize } from "dompurify";
import { handleAlert } from "../../Apis/features/alertSlice";
// import { useNavigate } from "react-router-dom";
import SkLoading from "../../components/SkError/SkLoading";
import { isHandleHelpModalOpen } from "../../Apis/features/modalSlice";
import ScrollDialog from "../../components/ScrollebleDialog";

const Help = () => {
  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: company, isLoading, error, isSuccess } = useGetCompanyQuery();
  //   console.log(company, isLoading, error, isSuccess);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const adminId = localStorage.getItem("authId");

  const [createCompanyData] = useCreateCompanyMutation();
  const handleSubmitMobile = async (values) => {
    // console.log(values);
    setIsSubmitting(true);
    try {
      const data = await createCompanyData({
        data: values,
        adminId: adminId,
      });

      setIsSubmitting(false);
      if (data?.data?.success) {
        // navigate("/");
        dispatch(isHandleHelpModalOpen({ show: true, state: data?.data }));
        if (data?.error) {
          setApiError(data?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } else if (!data?.data?.success) {
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: data?.data?.success,
            type: data?.data?.success ? "success" : "error",
            msg: data?.data?.message,
          })
        );
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),

    fullName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )

      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),

    comment: yup
      .string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Massage is required"),
  });
  const initialValues = {
    phone: "",
    fullName: "",
    comment: "",
  };
  return (
    <>
      <Paper sx={{ minHeight: "100vh" }}>
        <Container maxWidth="lg">
          {isLoading ? (
            <SkLoading />
          ) : error ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="primary" fontWeight={600}>
                {" "}
                something went wrong
              </Typography>
            </Box>
          ) : (
            isSuccess && (
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  pt: 2,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      my: 1.5,
                    }}
                  >
                    Get in touch today
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#545454",
                      fontWeight: 400,
                      fontSize: "1rem",
                      pr: 2.5,
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      //   WebkitLineClamp: "2",
                      //   WebkitBoxOrient: "vertical",
                    }}
                  >
                    {/* If you have any questions, feedback, or need assistance, please
                don't hesitate to reach out to our customer support team at
                apnathali.com. We're here to help! */}
                    Say goodbye to the hassle of keeping paper receipts and
                    cards for attendance! With our app, you can easily mark your
                    attendance at your favorite restaurant every time you dine
                    and earn rewards for being a loyal customer
                  </Typography>
                  <Typography
                    sx={{
                      color: "#545454",
                      fontWeight: 400,
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "2rem",
                      mt: 5,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <EmailOutlined fontSize="small" /> &nbsp;
                    {company?.data?.email}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#545454",
                      fontWeight: 400,
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "2rem",
                    }}
                  >
                    <PhoneOutlined fontSize="small" />
                    &nbsp;{company?.data?.phone}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#545454",
                      fontWeight: 400,
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "2rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      //   display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <LocationOnOutlined fontSize="small" /> &nbsp;
                    <Typography
                      variant="caption"
                      component={"span"}
                      color="initial"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(company?.data?.address),
                      }}
                      sx={{
                        "&:child": {
                          fontSize: "1rem",
                          color: "#545454",
                          fontWeight: 400,
                          // fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "2rem",
                        },
                      }}
                    />
                    {/* &nbsp;{company?.data?.address} */}
                  </Typography>
                </Box>

                <Card
                  elevation={5}
                  sx={{
                    width: "40%",
                    backgroundColor: "primary.main",
                    borderRadius: 2,
                  }}
                >
                  <Formik
                    onSubmit={handleSubmitMobile}
                    initialValues={initialValues}
                    validationSchema={isMobleSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          color: "white.main",
                          p: 2.5,
                          gap: 2.5,
                          overflowY: "scroll",
                          scrollBehavior: "smooth",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                        component="form"
                        role="form"
                        onSubmit={handleSubmit}
                      >
                        <Heading
                          text={"Contact Us"}
                          color="white.main"
                          isColor="#fff"
                          fontSize={"1.8rem"}
                        />

                        <Box
                          sx={{
                            width: "85%",
                            gap: 0.5,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextField
                            InputLabelProps={{
                              style: { color: "#fff" },
                            }}
                            size="small"
                            fullWidth
                            type={"text"}
                            required
                            autoComplete="off"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fullName}
                            name="fullName"
                            error={
                              (!!touched.fullName && !!errors.fullName) ||
                              !!apiError
                            }
                            helperText={
                              (touched.fullName && errors.fullName) || apiError
                            }
                            label="Full Name"
                            sx={{
                              "& .MuiFormHelperText-root.Mui-error": {
                                color: "white.main",
                              },
                              "& .MuiOutlinedInput-input": {
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    "-moz-appearance": "textfield",
                                  },
                              },
                              "& .MuiInputBase-root": {
                                //   height: 40,
                                border: "none",
                                outline: "none",
                                borderColor: "white.main",
                                bgcolor: "rgba(0, 0, 0, 0.2)",
                                color: "white.main",
                              },
                              "& .MuiFormHelperText-root": {
                                mx: 0,
                              },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                            }}
                            InputProps={{
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "85%",
                            gap: 0.5,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextField
                            InputLabelProps={{
                              style: { color: "#fff" },
                            }}
                            size="small"
                            fullWidth
                            type={"number"}
                            required
                            autoComplete="off"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            name="phone"
                            error={
                              (!!touched.phone && !!errors.phone) || !!apiError
                            }
                            helperText={
                              (touched.phone && errors.phone) || apiError
                            }
                            label="Phone Number"
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            sx={{
                              "& .MuiFormHelperText-root.Mui-error": {
                                color: "white.main",
                              },
                              "& .MuiOutlinedInput-input": {
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    "-moz-appearance": "textfield",
                                  },
                              },
                              "& .MuiInputBase-root": {
                                //   height: 40,
                                border: "none",
                                outline: "none",
                                borderColor: "white.main",
                                bgcolor: "rgba(0, 0, 0, 0.2)",
                                color: "white.main",
                              },
                              "& .MuiFormHelperText-root": {
                                mx: 0,
                              },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                            }}
                            InputProps={{
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "85%",
                            gap: 0.5,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <TextField
                            InputLabelProps={{
                              style: { color: "#fff" },
                            }}
                            size="small"
                            fullWidth
                            type={"text"}
                            required
                            autoComplete="off"
                            variant="outlined"
                            multiline
                            rows={4}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.comment}
                            name="comment"
                            error={
                              (!!touched.comment && !!errors.comment) ||
                              !!apiError
                            }
                            helperText={
                              (touched.comment && errors.comment) || apiError
                            }
                            label="Massage"
                            sx={{
                              "& .MuiFormHelperText-root.Mui-error": {
                                color: "white.main",
                              },
                              "& .MuiOutlinedInput-input": {
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    "-webkit-appearance": "none",
                                    "-moz-appearance": "textfield",
                                  },
                              },
                              "& .MuiInputBase-root": {
                                //   height: 40,
                                border: "none",
                                outline: "none",
                                borderColor: "white.main",
                                bgcolor: "rgba(0, 0, 0, 0.2)",
                                color: "white.main",
                              },
                              "& .MuiFormHelperText-root": {
                                mx: 0,
                              },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                              "& .css-19z74ru-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white.main",
                                },
                            }}
                            InputProps={{
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        </Box>

                        <Button
                          type="submit"
                          // // onClick={() => setClick(false)}
                          // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
                          variant="contained"
                          sx={{
                            width: "85%",
                            textTransform: "capitalize",
                            mt: 2,
                            height: "2.8rem",
                            borderRadius: 10,
                            color: "primary.main",
                            bgcolor: "white.main",
                            boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                            "&:hover": {
                              color: "primary.main",
                              bgcolor: "white.main",
                            },
                          }}
                        >
                          {isSubmitting ? "Submitting....." : "Submit"}
                        </Button>
                      </Box>
                    )}
                  </Formik>
                </Card>
              </Container>
            )
          )}
        </Container>
      </Paper>
      <ScrollDialog />
    </>
  );
};

export default Help;
