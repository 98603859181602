import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetFilterByRestIdQuery,
  useGetMemberByRestIdQuery,
} from "../../Apis/reduxRTK/memberApi";
import { Box, Button, Paper, Typography } from "@mui/material";
import SearchInput from "../../components/Search";
import SkLoading from "../../components/SkError/SkLoading";
import MemberCard from "../../components/Cards/MemberCard";
import SkSelector from "../../components/SkSelector";
import { FilterIcon } from "../../assets/SVG";
import SkPagination from "../../components/SkPagination";
import { useDebounce } from "use-debounce";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { handleMemberModel } from "../../Apis/features/memberSlice";

const Members = () => {
  const dispatch = useDispatch();
  const { singleRestaurant, searchName } = useSelector(
    (state) => state.isRestaurant
  );
  const [searchTerm] = useDebounce(searchName, 500);
  const [isSort, setIsSort] = useState("");
  const [isPlan, setIsPlan] = useState("");
  const [isPage, setIsPage] = useState(1);
  const {
    data: homeData,
    isLoading,
    isSuccess,
    error,
  } = useGetMemberByRestIdQuery(
    {
      status: "total",
      restroId: singleRestaurant?._id,
      sort: isSort,
      plan: isPlan,
      search: searchName,
      page: isPage,
    },
    searchTerm || skipToken
  );
  const {
    data: filter,
    isLoading: Loading,
    isFetching: Fetching,
  } = useGetFilterByRestIdQuery({
    restroId: singleRestaurant?._id,
  });

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            p: 1,
            minHeight: 500,
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              gap: 3,
              mb: 1.5,
            }}
          >
            <SearchInput />
            <Box
              sx={{
                width: "35%",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                gap: 1.5,
              }}
            >
              <SkSelector
                disabled={Loading || Fetching}
                handleChange={(e) => setIsSort(e.target.value)}
                values={isSort}
                data={filter?.data?.sorts}
                name={"isSort"}
                multiple={false}
                label={"Sort by"}
                icons={FilterIcon}
              />
              <SkSelector
                disabled={Fetching || Loading}
                handleChange={(e) => setIsPlan(e.target.value)}
                values={isPlan}
                data={filter?.data?.plans}
                name={"isPlan"}
                multiple={true}
                label={"Select Plan"}
                icons={FilterIcon}
              />
            </Box>
          </Box>
          {isLoading ? (
            <SkLoading />
          ) : (
            isSuccess && (
              <Box
                sx={{
                  width: "100%",

                  flexWrap: "wrap",

                  alignItems: "flex-start",
                  display: "flex",
                  justifyContent: "flex-start",

                  gap: 4,
                }}
              >
                {homeData?.data && homeData?.data?.length > 0 ? (
                  homeData?.data?.map((value, i) => (
                    <Box
                      sx={{ width: { sm: "46.5%", md: "48%", xl: "48%" } }}
                      key={i}
                    >
                      <MemberCard value={value} key={i} rate={true} />{" "}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      p: 1,
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={require("../../assets/images/memberNotFound.png")}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      alt="..."
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2, textTransform: "capitalize" }}
                      onClick={() => {
                        dispatch(
                          handleMemberModel({ show: true, title: false })
                        );
                      }}
                    >
                      Add Member
                    </Button>
                  </Box>
                )}

                {error && (
                  <Box
                    sx={{
                      mt: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" color="primary" fontWeight={600}>
                      {" "}
                      something went wrong
                    </Typography>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
        {isSuccess && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <SkPagination
              count={homeData?.totalPage}
              state={isPage}
              setState={setIsPage}
            />
          </Box>
        )}
      </Paper>
    </>
  );
};

export default Members;
