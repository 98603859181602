import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { Store } from "./Apis/Store";
// import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "./theme";
import ScrollToTop from "./components/scroll_hook";
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Provider store={Store}>
        {/* <PersistGate loading={null} persistor={persistStores}> */}
        <ThemeProvider theme={customTheme}>
          <ScrollToTop />
          <CssBaseline />
          <App />
        </ThemeProvider>
        {/* </PersistGate> */}
      </Provider>
    </BrowserRouter>
  </>
);
