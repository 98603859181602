import { useRoutes } from "react-router-dom";

import Layout from "./Layout";
import AlertTost from "./examples/AlertTost";
import { routes } from "./routes";

import MyModals from "./extras/MyModals";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";



function App() {
  const routing = useRoutes(routes);
  const isMobile = useMediaQuery("(max-width:870px)");
  const { company } = useSelector((state) => state.isCompany);
  
  useEffect(() => {
    if (isMobile) {
      window.open('https://play.google.com/store/apps/details?id=com.apnathali.management&pcampaignid=web_share', "_self");
    }
  }, [isMobile]);

  return (
    <>
      <AlertTost />
      <Layout>
        <MyModals />
        {routing}
      </Layout>
    </>
  );
}

export default App;
