import React, { useState } from "react";
import Base from ".";
import {
  Box,
  Typography,
  Button,
  TextField,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";

import { useCheckSendOtpMutation } from "../../Apis/reduxRTK/api";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAlert } from "../../Apis/features/alertSlice";
import Heading from "../../examples/Heading";
import { setOtp } from "../../Apis/features/authSlice";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // console.log(state, "state");

  // const [createUserMutation] = useCreateUserMutation();
  const [checkSendOtp] = useCheckSendOtpMutation();
  const [apiError, setApiError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  // const [showPassword, setShowPassword] = useState(false);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    setImages(file);
    setIsUploading(true);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader.result);
      setIsUploading(false);
    });
    fileReader.readAsDataURL(file);
  };

  const handleSubmitMobile = async (values) => {
    // console.log(values);

    setIsSubmitting(true);
    try {
      const data = await checkSendOtp({
        phone: values?.phone?.toString(),
        check: false,
      });

      setIsSubmitting(false);
      if (data?.data?.success) {
        navigate("/verify", {
          state: {
            data: data?.data?.otp,
            values: { ...values, image: images },
            isLogin: false,
          },
        });
        dispatch(setOtp(""));
      }
      if (data?.error) {
        setApiError(data?.error?.data?.message);
        setTimeout(() => {
          setApiError(null);
        }, 5000);
      } else {
        setApiError(null);
      }
      dispatch(
        handleAlert({
          isOpen: data?.data?.success,
          type: data?.data?.success ? "success" : "error",
          msg: data?.data?.message,
        })
      );
      // console.log(data, "data");

      // if (data?.error) {
      //   setApiError(data?.error?.data?.message);
      //   setTimeout(() => {
      //     setApiError(null);
      //   }, 5000);
      // } else {
      //   setApiError(null);
      // }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const isMobleSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .min(10)
      .max(10)
      .matches(phoneRegex, "Invalid phone")
      .required("Phone is required"),

    fullName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters."
      )

      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name.")
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required("Full name is required"),

    email: yup
      .string()

      // .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email address"
      ),
    // password: yup
    //   .string()

    //   .required("Password is required")
    //   .matches(
    //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     "password must be 8 character example : Example@123"
    //   ),
  });
  const initialValues = {
    phone: state?.data || "",
    fullName: "",
    email: "",
    // password: "",
  };
  return (
    <Base>
      <Formik
        onSubmit={handleSubmitMobile}
        initialValues={initialValues}
        validationSchema={isMobleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              color: "white.main",
              p: 2.5,
              gap: 2.5,
              mt: 1,
              overflowY: "scroll",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
           
            <Box
              sx={{
                mt: 0.6,
                height: "7rem",
                width: "7rem",
                // border: 2,
                // borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {isUploading ? (
                <Avatar
                  sx={{
                    height: "7rem",
                    width: "7rem",
                    border: 2,
                    borderColor: "white.main",
                    background: "transparent",
                  }}
                >
                  <CircularProgress color="white" />
                </Avatar>
              ) : (
                <>
                  <label htmlFor="imageuploader">
                    {" "}
                    <img
                      src={require("../../assets/images/edit.png")}
                      alt="edit button"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        position: "absolute",
                        bottom: "0",
                        right: "0 ",
                        cursor: "pointer",
                        borderRadius: "50%",
                        zIndex: 10,
                      }}
                    />
                  </label>
                  <input
                    // required
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleSelectImage}
                    id={"imageuploader"}
                  />
                  <Avatar
                    sx={{
                      height: "7rem",
                      width: "7rem",
                      border: 2,
                      borderColor: "white.main",
                    }}
                    src={
                      previewImage
                        ? previewImage
                        : // : userData?.image
                          // ? `${process.env.REACT_APP_URI}/${userData?.image}`
                          null
                    }
                  />
                </>
              )}
            </Box>
            <Heading
              text={"Complete Profile"}
              color="white.main"
              isColor="#fff"
              fontSize={"1.5rem"}
            />
            <Box
              sx={{
                width: "85%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="large"
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                type={"text"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={(!!touched.fullName && !!errors.fullName) || !!apiError}
                helperText={(touched.fullName && errors.fullName) || apiError}
                label="Full Name"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "white.main",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "white.main",
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },

                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "85%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="large"
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                type={"email"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={(!!touched.email && !!errors.email) || !!apiError}
                helperText={(touched.email && errors.email) || apiError}
                label=" E-mail "
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "white.main",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "white.main",
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "85%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="large"
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                type={"number"}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={(!!touched.phone && !!errors.phone) || !!apiError}
                helperText={(touched.phone && errors.phone) || apiError}
                label=" Phone Number"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "white.main",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "white.main",
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            {/* <Box
              sx={{
                width: "85%",
                gap: 0.5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "white.main", fontWeight: 600 }}
              >
                Password
                {/* /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ */}
            {/* </Typography>
               <TextField size="large"
                fullWidth
                type={showPassword ? "text" : "password"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={(!!touched.password && !!errors.password) || !!apiError}
                helperText={(touched.password && errors.password) || apiError}
                label="Enter Your Password Number"
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                  //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: "white.main" }}
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword((show) => !show);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box> */}

            <Button
              type="submit"
              // // onClick={() => setClick(false)}
              // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
              variant="contained"
              sx={{
                fontSize: "1rem",
                fontWeight: 800,
                width: "85%",
                textTransform: "capitalize",
                mt: 2,
                height: "2.8rem",
                borderRadius: 2,
                color: "primary.main",
                bgcolor: "white.main",
                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: "white.main",
                },
              }}
            >
              {isSubmitting ? "Submitting....." : "Submit"}
            </Button>
          </Box>
        )}
      </Formik>
    </Base>
  );
};

export default Signup;
