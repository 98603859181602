import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "projects",
  initialState: {
    auth: localStorage?.getItem("auth") || null,
    authToken: null,
    authId: null,
    userType: null,
    otp: null,
    isProfileOpen: false,
    authData : null
  },
  reducers: {
    addAuth: (state, action) => {
      state.auth = action.payload;
      localStorage.setItem("auth", action.payload);
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setAuthId: (state, action) => {
      state.authId = action.payload;
      localStorage.setItem("authId", action.payload);
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
      localStorage.setItem("userType", action.payload);
    },
    removeAuth: (state, action) => {
      state.auth = action.payload;
      state.auth = action.payload;
      state.authToken = action.payload;
      state.authId = action.payload;
      state.userType = action.payload;
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      localStorage.removeItem("authId");
      localStorage.removeItem("userType");
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    handleProfileModel: (state, action) => {
      state.isProfileOpen = action.payload.show;
      state.authData = action.payload.data;
    },
    // Add more reducers for managing projects...
  },
});

export const {
  addAuth,
  setAuthToken,
  setAuthId,
  setUserType,
  removeAuth,
  setOtp,
  handleProfileModel,
} = authSlice.actions;
export default authSlice.reducer;
