import React, { useEffect, useState } from "react";
import Base from ".";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import * as yup from "yup";
// import { Person } from "@mui/icons-material";
import {
  useCheckSendOtpMutation,
  useCreateUserMutation,
  useLogInUserMutation,
} from "../../Apis/reduxRTK/api";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAlert } from "../../Apis/features/alertSlice";
import {
  addAuth,
  setAuthId,
  setAuthToken,
  setOtp,
  setUserType,
} from "../../Apis/features/authSlice";
import Heading from "../../examples/Heading";
import { ArrowLeft, EditOutlined } from "@mui/icons-material";

const Verify = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // console.log(state, "state");
  useEffect(() => {
    if (state) {
      sendOTP();
    }
  }, [state]);
  console.log(location,'location')

  const [apiError, setApiError] = useState(null);
  const { otp } = useSelector((state) => state.isAuth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loggedIn] = useLogInUserMutation();
  const [checkSendOtp] = useCheckSendOtpMutation();
  const [createUser] = useCreateUserMutation();
  const handleSubmitMobile = async (values) => {
    // console.log(values?.otp, "values?.otp", otp);
    try {
      setIsSubmitting(true);
      if (values?.otp === (otp || state?.data)) {
        if (state?.isLogin) {
          const logInUser = await loggedIn({
            data: {
              phone: state?.phone?.toString(),
              hashKey: process.env.REACT_APP_HASHKEY,
            },
          });
          // console.log(logInUser, "loginuser");
          setIsSubmitting(false);
          dispatch(setAuthToken(logInUser?.data?.token));
          if (logInUser?.data?.success && logInUser?.data?.isRestaurent) {
            dispatch(
              handleAlert({
                isOpen: true,
                type: "success",
                msg: "Verify OTP Successfully",
              })
            );
            dispatch(addAuth(true));
            dispatch(setAuthId(logInUser?.data?.data?._id));
            dispatch(setUserType(logInUser?.data?.data?.userType));

            navigate("/");
          } else navigate("/add_restaurant", { state: logInUser?.data });
          // login admin
        } else {
          // register user}
          const formData = new FormData();
          if (state?.values) {
            Object.entries(state?.values)?.map(([key, value]) =>
              formData.append(key, value || "")
            );
          }
          formData.append("hashKey", process.env.REACT_APP_HASHKEY);
          const signInUser = await createUser({ data: formData });
          setIsSubmitting(false);
          dispatch(
            handleAlert({
              isOpen: !signInUser?.error?.data?.success,
              type: "error",
              msg: signInUser?.error?.data?.message,
            })
          );
          // console.log(signInUser, "signIn");
          if (signInUser?.data?.success) {
            dispatch(
              handleAlert({
                isOpen: true,
                type: "success",
                msg: "Verify OTP Successfully",
              })
            );
            navigate("/add_restaurant", { state: signInUser?.data });
          }
        }
      } else {
        setApiError(" invalid OTP");
        setIsSubmitting(false);
        setTimeout(() => {
          setApiError(null);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }

    //       addAuth
    // setAuthToken
    // setAuthId
    // setUserType
    // removeAuth
    // setOtp
    // dispatch(addAuth(true));
    // dispatch(setAuthToken(state?.data?.token));
    // dispatch(setAuthId(state?.data?.data?._id));
    // dispatch(setUserType(state?.data?.data?.userType));
    // if (state?.data?.data?.restaurantId) {
    //   navigate("/", { state: state });
    // } else {
    //   navigate("/add_restaurant", { state: state });
    // }
    // dispatch(
    //   handleAlert({
    //     isOpen: true,
    //     type: "success",
    //     msg: "Verify OTP Successfully",
    //   })
    // );
    // navigate("/add_restaurant", { state: state });
  };
  const isMobleSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^[0-9]{4}$/, "OTP must be a 4-digit number")
      .min(4, "invalid otp!")
      .max(4, "invalid otp!")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .required("otp is required"),
  });
  const initialValues = {
    otp: "",
  };
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const sendOTP = () => {
    setMinutes(1);
    setSeconds(0);
  };
  const handleCheckFunction = async (e) => {
    // console.log(e.target.value, "dksjafhkjsadf");
    const enterOtp = e.target.value;
    if (enterOtp?.length === 4) {
      try {
        setIsSubmitting(true);
        if (parseInt(enterOtp) === (otp || parseInt(state?.data))) {
          if (state?.isLogin) {
            const logInUser = await loggedIn({
              data: {
                phone: state?.phone?.toString(),
                hashKey: process.env.REACT_APP_HASHKEY,
              },
            });
            // console.log(logInUser, "loginuser");
            setIsSubmitting(false);
            dispatch(setAuthToken(logInUser?.data?.token));
            if (logInUser?.data?.success && logInUser?.data?.isRestaurent) {
              dispatch(
                handleAlert({
                  isOpen: true,
                  type: "success",
                  msg: "Verify OTP Successfully",
                })
              );
              dispatch(addAuth(true));
              dispatch(setAuthId(logInUser?.data?.data?._id));
              dispatch(setUserType(logInUser?.data?.data?.userType));

              navigate("/");
            } else navigate("/add_restaurant", { state: logInUser?.data });
            // login admin
          } else {
            // register user}
            const formData = new FormData();
            if (state?.values) {
              Object.entries(state?.values)?.map(([key, value]) =>
                formData.append(key, value || "")
              );
            }
            formData.append("hashKey", process.env.REACT_APP_HASHKEY);
            const signInUser = await createUser({ data: formData });
            setIsSubmitting(false);
            dispatch(
              handleAlert({
                isOpen: !signInUser?.error?.data?.success,
                type: "error",
                msg: signInUser?.error?.data?.message,
              })
            );
            // console.log(signInUser, "signIn");
            if (signInUser?.data?.success) {
              dispatch(
                handleAlert({
                  isOpen: true,
                  type: "success",
                  msg: "Verify OTP Successfully",
                })
              );
              navigate("/add_restaurant", { state: signInUser?.data });
            }
          }
        } else {
          setApiError(" invalid OTP");
          setIsSubmitting(false);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        }
      } catch (error) {
        console.log(error);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    }
  };
  return (
    <Base>
      <Formik
        onSubmit={handleSubmitMobile}
        initialValues={initialValues}
        validationSchema={isMobleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              color: "white.main",
              p: 2.5,
              gap: 3,
            mt: -7,
              overflowY: "scroll",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
        
            <Heading
              text={"Verify Mobile Number"}
              color="white.main"
              isColor="#fff"
              fontSize={"2rem"}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85%",
              }}
            >
              <Typography
                width={"85%"}
                variant="body1"
                sx={{ color: "white.main", fontWeight: 400 }}
              >
                Enter OTP sent to{" "}
                {state?.phone?.toString() || state?.values?.phone?.toString()}
                {/* /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ */}
              </Typography>
              <Button
                variant="text"
                color="white"
                sx={{ textTransform: "capitalize" }}
                onClick={() => navigate("/signin", { state: state })}
              >
              <EditOutlined sx={{fontSize:"20px"}}/> {' '}  Edit
              </Button>
            </Box>
            <Box
              sx={{
                width: "85%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="large"
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                fullWidth
                type={"number"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleCheckFunction(e);
                }}
                value={values.otp}
                name="otp"
                error={(!!touched.otp && !!errors.otp) || !!apiError}
                helperText={(touched.otp && errors.otp) || apiError}
                label="Enter Your OTP "
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "white.main",
                  },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "white.main",
                    bgcolor: "rgba(0, 0, 0, 0.2)",
                    color: "white.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "white.main",
                  },
                  "& .css-1hta2sa-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white.main",
                    },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85%",
              }}
            >
              <Typography
                width={"85%"}
                variant="body1"
                sx={{ color: "white.main", fontWeight: 400 }}
              >
                Resend the OTP in
                {/* /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ */}
              </Typography>
              {seconds > 0 || minutes > 0 ? (
                <Typography
                  variant="body2"
                  sx={{ color: "white.main", fontWeight: 600 }}
                  component="span"
                >
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Typography>
              ) : (
                <Button
                  variant="text"
                  color="white"

                  // onClick={() => setClick(false)}
                  sx={{ textTransform: "capitalize" ,
                  boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                  "&:hover": {
                    color: "primary.main",
                    bgcolor: "white.main",
                  },}}
                  onClick={async () => {
                    try {
                      const res = await checkSendOtp({
                        phone:
                          state?.phone?.toString() ||
                          state?.values?.phone?.toString(),
                        check: state?.isLogin,
                      });
                      sendOTP();
                      // console.log(res);
                      if (res?.data?.success) dispatch(setOtp(res?.data?.otp));
                    } catch (error) {
                      console.log(error);
                    }
                    // setIsLoading(true);
                    // setIsLoading(false);
                  }}
                >
                  resend
                </Button>
              )}
            </Box>
            <Button
              type="submit"
              // // onClick={() => setClick(false)}
              // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
              variant="contained"
              sx={{
                width: "85%",
                textTransform: "capitalize",
                mt: 2,
                height: "2.8rem",
                borderRadius: 2,
                color: "primary.main",
                bgcolor: "white.main",
                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                "&:hover": {
                  color: "primary.main",
                  bgcolor: "white.main",
                },
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting....." : "Submit"}
            </Button>
          </Box>
        )}
      </Formik>
    </Base>
  );
};

export default Verify;
