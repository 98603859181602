import React from "react";

import { Paper, Container, Box, Typography, IconButton } from "@mui/material";
import SkLoading from "../../components/SkError/SkLoading";
import AttendanceTable from "../../examples/Table";
import Heading from "../../examples/Heading";
import { ArrowBackIcon, FilterIcon } from "../../assets/SVG";
import SkSelector from "../../components/SkSelector";
import { useGetAttendanceByMemberIdQuery } from "../../Apis/reduxRTK/memberApi";
import { useState } from "react";
import { ArrowBackIosNew } from "@mui/icons-material";

const Attendance = ({ isOpen, setIsOpen, memberId }) => {
  //   const location = useLocation();

  //   console.log(state);
  const data = ["today", "week", "month", "year", "total", "manually"];
  // console.log(member, "member");
  const [isSort, setIsSort] = useState("total");
  const {
    data: member,
    isLoading,
    isSuccess,
    error,
  } = useGetAttendanceByMemberIdQuery({ memberId: memberId, filter: isSort });
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        p: 2,
        width: "100%",
        minHeight: "80vh",
      }}
    >
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <IconButton
          aria-label="go back"
          onClick={() => {
            setIsOpen(false);
          }}
          color="primary"
        >
          <ArrowBackIosNew />
        </IconButton>
        <Heading
          text="Total Mark Attendance"
          color="primary.main"
          sx={{
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        />
        <SkSelector
          width="150px"
          handleChange={(e) => setIsSort(e.target.value)}
          values={isSort}
          data={data}
          name={"isSort"}
          multiple={false}
          label={"Sort by"}
          icons={FilterIcon}
        />
      </Container>
      {isLoading ? (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // flexWrap: "wrap",
            flexDirection: "column",
            gap: 2,
            p: 2,
            width: "100%",
            minHeight: "80vh",
          }}
        >
          <SkLoading />{" "}
        </Paper>
      ) : isSuccess ? (
        member &&
        isOpen && (
          <Container maxWidth="lg">
            {member?.data && member?.data.length > 0 && (
              <AttendanceTable data={member?.data} rate={true} />
            )}
          </Container>
        )
      ) : (
        error && (
          <Paper
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              // flexWrap: "wrap",
              flexDirection: "column",
              gap: 2,
              p: 2,
              width: "100%",
              minHeight: "80vh",
            }}
          >
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="primary" fontWeight={600}>
                {" "}
                something went wrong
              </Typography>
            </Box>
          </Paper>
        )
      )}
    </Paper>
  );
};

export default Attendance;
