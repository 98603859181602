import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${process.env.REACT_APP_API}`;

export const restaurantApi = createApi({
  reducerPath: "restaurantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Set the "Authorization" header with the token from local storage
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Restaurant"],
  endpoints: (builder) => ({
    // Define your CRUD endpoints here
    getRestaurantByAdminId: builder.query({
      query: (adminId) => `get/all/restaurant/${adminId}`,
      providesTags: ["Restaurant"],
    }),

    createRestaurant: builder.mutation({
      query: ({ data, adminId }) => ({
        url: `create/restaurant/${adminId}`,
        method: "POST",
        body: data,
      }),
    }),

    updateRestaurant: builder.mutation({
      query: ({ restroId, data }) => ({
        url: `update/restaurant/${restroId}/${localStorage.getItem("authId")}`,
        method: "PUT", // or 'PATCH' depending on your API
        body: data,
      }),
      invalidatesTags: ["Restaurant"],
      // async onQueryStarted(args, { queryFulfilled, dispatch }) {
      //   try {
      //     const { data: updatedData } = await queryFulfilled;
      //     console.log("updatedData", updatedData);
      //     dispatch(
      //       restaurantApi.util.updateQueryData(
      //         "getRestaurantByAdminId",
      //         undefined,
      //         (draft) => {
      //           console.log("draft.data", draft.data);
      //           if (draft) {
      //             draft.data = updatedData?.data;
      //           }
      //         }
      //       )
      //     );
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),
  }),
});

export const {
  useGetRestaurantByAdminIdQuery,
  useCreateRestaurantMutation,
  useUpdateRestaurantMutation,
} = restaurantApi;
