import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField, Typography } from "@mui/material";
import { AccessTime } from "@mui/icons-material";

// const fiveAM = dayjs().set("hour", 5).startOf("hour");
// const nineAM = dayjs().set("hour", 9).startOf("hour");

export default function SKTimePicker({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  apiError,
  isName,
  rate,
  isRate,
  label,
}) {
  const handleTimeChange = (newValue) => {
    // console.log(newValue, "newValue");
    if (handleChange) {
      handleChange(formatTime(newValue));
    }
  };
  const formatTime = (time) => {
    return dayjs(time, "HH:mm").format("hh:mm A");
  };

  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <TimePicker
    //     defaultValue={formatTime(values) || ""}
    //     required
    //     autoComplete="off"
    //     variant="outlined"
    //     onBlur={handleBlur}
    //     onChange={handleTimeChange}
    //     name={isName}
    //     error={(touched && Boolean(errors)) || !!apiError}
    //     helperText={(touched && errors) || apiError}
    //     sx={{
    //       "& .MuiOutlinedInput-input": {
    //         "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    //           "-webkit-appearance": "none",
    //           "-moz-appearance": "textfield",
    //         },
    //       },
    //       "& .MuiInputBase-root": {
    //       //   height: 40,
    //         border: "none",
    //         outline: "none",
    //         borderColor: "GrayText",
    //         bgcolor: "rgba(221, 218, 218, 0.15)",
    //         color: "black.main",
    //       },
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         outline: "none",
    //         border: "none",
    //       },
    //     }}
    //   />
    // </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        defaultValue={dayjs(values, "hh:mm A")}
        onChange={handleTimeChange}
        label={`Select ${label} Time`}
        components={{
          OpenPickerIcon: (props) => (
            <AccessTime
              {...props}
              sx={{ color: isRate ? "#000" : "white.main" }}
            />
          ),
        }}
        InputLabelProps={{
          style: { color: "#fff" },
        }}
        size="small"
        //   onBlur={() => setFieldTouched(name, true)}
        renderInput={(params) => (
          <TextField
            size="small"
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            {...params}
            required
            autoComplete="off"
            variant="outlined"
            // label={"label"}
            // onChange={handleChange}
            //   value={values}
            name={isName}
            onBlur={handleBlur}
            error={(!!touched && !!errors) || !!apiError}
            helperText={(touched && errors) || apiError}
            sx={{
              "& .MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
                color: rate ? "#fff" : "black.main",
              },
            }}
          />
        )}
        sx={{
          input: { cursor: "pointer" },
          "& .MuiOutlinedInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              "-moz-appearance": "textfield",
            },
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: rate ? "white.main" : "primary.main",
          },
          "& .MuiOutlinedInput-root": {
            //   height: 40,

            width: "100%",
            border: "none",
            outline: "none",
            borderColor: "GrayText",
            bgcolor: rate ? "rgba(0, 0, 0, 0.2)" : "rgba(221, 218, 218, 0.15)",
            color: rate ? "white.main" : "black.main",
          },
          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
            p: "10px",
          },
          "& .MuiFormHelperText-root": {
            mx: 0,
          },
          // "& .MuiOutlinedInput-notchedOutline": {
          //   outline: "none",
          //   border: "none",
          // },
          "& .css-hppyve-MuiFormLabel-root-MuiInputLabel-root": {
            color: rate ? "#fff" : "#3333ds",
          },
          "& .css-hppyve-MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
            color: rate ? "#fff" : "#3333ds",
          },
        }}
      />
      <Typography variant="caption" className="MuiFormHelperText-root">
        {" "}
        {(touched && errors) || apiError}
      </Typography>
    </LocalizationProvider>
  );
}
