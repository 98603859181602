import React from "react";
import { useSelector } from "react-redux";

const SuccessModal = () => {
  const { helpModalState } = useSelector((state) => state.isModal);
  console.log(helpModalState, "helpModalState");
  return (
    <>
      {" "}
      <div>
        <style
          className="your-container-class"
          dangerouslySetInnerHTML={{
            __html:
              '\n      h1 {\n        color: #88B04B;\n        font-family: "Lexend", sans-serif;\n        font-weight: 900;\n        font-size: 40px;\n        margin-bottom: 10px;\n      }\n      p {\n        color: #404F5E;\n        font-family: "Lexend", sans-serif;\n        font-size: 20px;\n        margin: 0;\n      }\n      i {\n        color: #9ABC66;\n        font-size: 100px;\n        line-height: 200px;\n        margin-left: -15px;\n      }\n      .card {\n        background: white;\n        padding: 60px;\n        border-radius: 4px;\n        box-shadow: 0 2px 3px #C8D0D8;\n        display: inline-block;\n        margin: 0 auto;\n      }\n    ',
          }}
        />
        <div
          className="card"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          }}
        >
          <div
            style={{
              borderRadius: 200,
              height: 200,
              width: 200,
              background: "#F8FAF5",
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
          >
            <i className="checkmark">✓</i>
          </div>
          <h1>Success</h1>
          <p style={{ textAlign: "center"}}>{helpModalState?.message}</p>
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
