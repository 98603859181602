import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NotificationBellIcon } from "../../assets/SVG";
import SkLoading from "../SkError/SkLoading";
import {
  useDeleteNotificationMutation,
  useGetNotificationQuery,
  useUpdateNotificationMutation,
} from "../../Apis/reduxRTK/notificationApi";
import { Delete } from "@mui/icons-material";
import NotificationCard from "../Cards/NotificationCard";

export default function FadeMenu() {
  const adminId = localStorage.getItem("authId");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    data: notifications,
    isLoading,
    isSuccess,
    error,
  } = useGetNotificationQuery({ adminId: adminId });
  // console.log("notifications", notifications);
  const [updateNotification] = useUpdateNotificationMutation();
  React.useEffect(() => {
    if (open && notifications?.count > 0) {
      seenNotification();
    }
  }, [open]);
  const seenNotification = async () => {
    try {
      const res = await updateNotification({
        adminId: adminId,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const [deleteNotification] = useDeleteNotificationMutation();
  const ITEM_HEIGHT = 48;
  const handleDeleteNotification = async () => {
    const res = await deleteNotification({ adminId: adminId });
    if (res?.data?.success) {
      // dispatch(handleNotification({ show: false, data: [] }));
    }
  };
  return (
    <>
      <Tooltip title="Open Notification" arrow>
        <IconButton
          sx={{ position: "relative" }}
          id="fade-button"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Badge
            badgeContent={notifications?.count}
            color="info"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {NotificationBellIcon}
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // TransitionComponent={Fade}

        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: 450,
            maxHeight: "100vh",
            minHeight: "35rem",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 2,
            display: " flex",
            cursor: "pointer",
            border: 0.2,
            borderColor: "gray.gray2",
            flexDirection: "column",
            borderRadius: 3,

            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          },
          "& .MuiList-root": {
            width: "100%",
            height: "100%",
          },
          "& .MuiMenu-root": {
            width: 450,
            maxHeight: "100vh",
            minHeight: "35rem",
          },
        }}
      >
        <Box
          sx={{
            width: `calc(100% - 10px)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
          }}
        >
          <Typography variant="h6" color="initial">
            {" "}
            Notifications
          </Typography>
          <Box sx={{ display: "flex", flex: 1 }} />
          {notifications?.data && notifications?.data?.length > 0 && (
            <IconButton
              aria-label="deleted"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteNotification();
              }}
              size="small"
            >
              <Delete color="primary" />
            </IconButton>
          )}
        </Box>
        <Divider
          sx={{
            mb: 1,
            width: "100%",
          }}
        />
        <Box
          sx={{
            overflowY: "scroll",
            height: "85%",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {isLoading ? (
            <SkLoading />
          ) : isSuccess ? (
            notifications?.data && notifications?.data?.length > 0 ? (
              notifications?.data?.map((notification, index) => (
                // <MenuItem key={index} selected={index === index} maxWidth="100%">
                //   <NotificationCard results={notification} key={index} />
                // </MenuItem>
                <React.Fragment key={index}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      "&:hover": {
                        background: "#f4f2f2",
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt="apna thali"
                        src={`${process.env.REACT_APP_URI}${notification?.icon}`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification?.title}
                      secondary={notification?.description}
                    />
                  </ListItem>
                  {/* </List> */}
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))
            ) : (
              <Box
                sx={{
                  width: "100%",
                  p: 1,
                  height: 250,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={require("../../assets/images/data-search-found-illustration-concept_108061-574.jpg")}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="..."
                />
                <Typography variant="body1" color="initial">
                  No Notification found
                </Typography>
              </Box>
            )
          ) : error ? (
            <Box
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="primary" fontWeight={600}>
                {" "}
                something went wrong
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Menu>
    </>
  );
}
