import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../components/Modal";
import {
  handleMemberModel,
  handleMemberModelDetails,
} from "../Apis/features/memberSlice";
import NewMemeber from "../pages/Members/NewMemeber";
import {
  handlePlanModel,
  handleRenewalPlanModel,
  removeData,
} from "../Apis/features/planSlice";
import NewPlan from "../pages/plans/NewPlan";
import LogoutConfirm from "../components/SkConfirm/LogoutConfirm";
import Member from "../pages/Members/Member";
import UpdateMember from "../pages/Members/UpdateMember";
import {
  handleUpdateModel,
  isHandleHelpModalOpen,
  isHandleModalOpen,
} from "../Apis/features/modalSlice";
import RenewalPlan from "../pages/plans/RenewalPlan";

import AddAmountHolidays from "../examples/AddAmount&Holidays";
import SuccessModal from "../pages/Help/SuccessModal";
import { useNavigate } from "react-router-dom";
// import ScrollDialog from "../components/ScrollebleDialog";

const MyModals = () => {
  const { isPlanShow, isRenewalPlan } = useSelector((state) => state.isPlan);
  const { isMemberShow, isDetails } = useSelector((state) => state.isMember);
  const { isUpdateMemberShow, isOpen, helpModalOpen } = useSelector(
    (state) => state.isModal
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(isMemberShow, "isRenewalPlan");
  return (
    <>
      {/* member create modal */}
      <BasicModal
        show={isMemberShow}
        unShow={() => {
          dispatch(handleMemberModel({ show: false }));
          dispatch(removeData(null));
        }}
        width={500}
        height={"auto"}
        overflowY={true}
        // padding={4}
      >
        <NewMemeber />
      </BasicModal>
      {/* member details modal */}
      <BasicModal
        show={isDetails}
        unShow={() => {
          dispatch(handleMemberModelDetails({ show: false }));
        }}
        width={"80%"}
        height={"auto"}
        overflowY={true}
        // padding={4}
      >
        <Member />
      </BasicModal>
      {/* plans create modal */}
      <BasicModal
        show={isPlanShow}
        unShow={() => dispatch(handlePlanModel({ show: false }))}
        width={500}
        height={"auto"}
        overflowY={true}
        // padding={4}
      >
        <NewPlan />
      </BasicModal>
      {/* logout custom confirm */}
      <LogoutConfirm />

      <BasicModal
        show={isUpdateMemberShow}
        unShow={() => dispatch(handleUpdateModel(false))}
        width={400}
        height={"auto"}
        overflowY={true}
      >
        <UpdateMember />
      </BasicModal>

      <BasicModal
        show={isRenewalPlan}
        unShow={() => dispatch(handleRenewalPlanModel(false))}
        width={400}
        height={"auto"}
        overflowY={true}
      >
        <RenewalPlan />
      </BasicModal>
      <BasicModal
        show={isOpen}
        unShow={() =>
          dispatch(isHandleModalOpen({ show: false, title: false }))
        }
        width={400}
        height={"auto"}
        overflowY={true}
      >
        <AddAmountHolidays />
      </BasicModal>
      <BasicModal
        show={helpModalOpen}
        unShow={() => {
          dispatch(isHandleHelpModalOpen({ show: false }));
          navigate("/");
        }}
        width={"50%"}
        height={"auto"}
        overflowY={true}
      >
        <SuccessModal />
      </BasicModal>

      {/* <ScrollDialog /> */}
    </>
  );
};

export default MyModals;
