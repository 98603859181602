import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "./Heading";
import { Formik } from "formik";
import * as yup from "yup";
import { handleAlert } from "../Apis/features/alertSlice";
import {
  useAddAmountMutation,
  useAddHolidayMutation,
} from "../Apis/reduxRTK/memberApi";
import { isHandleModalOpen } from "../Apis/features/modalSlice";
import { SkPrice } from "../extras/dataFunction";

const AddAmountHolidays = () => {
  const { isMemberId, isTitle, isMemberData } = useSelector(
    (state) => state.isModal
  );
  const dispatch = useDispatch();
  // console.log(isMemberData, "isMemberData");
  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addAmount] = useAddAmountMutation();
  const [addHoliday] = useAddHolidayMutation();

  const calculatePrice = (isPlanData, value) => {
    if (isPlanData >= value) {
      return isPlanData - value;
    } else {
      return 0;
    }
  };
  const initialValues = {
    otp:
      (!isTitle &&
        (isMemberData?.data?.dueAmount ||
          isMemberData?.data?.currentPlanId?.dueAmount)) ||
      "",
  };
  const handleSubmitMobile = async (values) => {
    try {
      setIsSubmitting(true);
      //   isTitle ? "Add holiday" : "Add Amount"}
      if (isTitle) {
        const data = { day: values?.otp };
        const res = await addHoliday({ memberId: isMemberId, data: data });
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message || res?.error?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(
            isHandleModalOpen({
              show: false,
              title: false,
              isMemberId: null,
            })
          );
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      } else {
        const res = await addAmount({
          amount: values?.otp,
          memberId: isMemberData?.data?.currentPlanId?.shallowMemberId,
        });
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message || res?.error?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(
            isHandleModalOpen({
              show: false,
              title: false,
              isMemberId: null,
            })
          );
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: "something went wrong !",
        })
      );
    }

    //       addAuth
    // setAuthToken
    // setAuthId
    // setUserType
    // removeAuth
    // setOtp
    // dispatch(addAuth(true));
    // dispatch(setAuthToken(state?.data?.token));
    // dispatch(setAuthId(state?.data?.data?._id));
    // dispatch(setUserType(state?.data?.data?.userType));
    // if (state?.data?.data?.restaurantId) {
    //   navigate("/", { state: state });
    // } else {
    //   navigate("/add_restaurant", { state: state });
    // }
    // dispatch(
    //   handleAlert({
    //     isOpen: true,
    //     type: "success",
    //     msg: "Verify OTP Successfully",
    //   })
    // );
    // navigate("/add_restaurant", { state: state });
  };
  const isMobleSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special character"
      )
      .required(`${isTitle ? "Holiday" : "Amount"} required`),
  });

  return (
    <Formik
      onSubmit={handleSubmitMobile}
      initialValues={initialValues}
      validationSchema={isMobleSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            color: "white.main",
            p: 2.5,
            gap: 3,
            overflowY: "scroll",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <Heading
            text={isTitle ? "Add Holiday" : "Add Amount"}
            color="primary.main"
            fontSize={"1.5rem"}
          />

          <Box
            sx={{
              width: "85%",
              gap: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Typography
              variant="body1"
              sx={{ color: "primary.main", fontWeight: 600 }}
            >{isTitle ? "Holiday" : "Amount"}
              
            </Typography> */}
            <TextField
              InputLabelProps={{
                style: { color: "#000" },
              }}
              size="small"
              fullWidth
              type={"number"}
              required
              autoComplete="off"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.otp}
              name="otp"
              error={(!!touched.otp && !!errors.otp) || !!apiError}
              helperText={(touched.otp && errors.otp) || apiError}
              label={isTitle ? "Day" : "Amount"}
              sx={{
                "& .MuiOutlinedInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      "-moz-appearance": "textfield",
                    },
                },
                "& .MuiInputBase-root": {
                  //   height: 40,
                  border: "none",
                  outline: "none",
                  borderColor: "GrayText",
                  bgcolor: "rgba(221, 218, 218, 0.15)",
                  color: "black.main",
                },
                "& .MuiFormHelperText-root": {
                  mx: 0,
                },
                // "& .MuiOutlinedInput-notchedOutline": {
                //   outline: "none",
                //   border: "none",
                // },
              }}
              InputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              //   onInput={(e) => {
              //     e.target.value = Math.max(0, parseInt(e.target.value))
              //       .toString()
              //       .slice(0, 10);
              //   }}
            />
            <Typography
              variant="caption"
              color="error"
              fontSize={12}
              fontWeight={600}
            >
              {(isMemberData?.data?.dueAmount ||
                isMemberData?.data?.currentPlanId?.dueAmount) >= values.otp
                ? null
                : !isTitle &&
                  `Due Amount cannot exceed ${
                    isMemberData?.data?.dueAmount ||
                    isMemberData?.data?.currentPlanId?.dueAmount ||
                    0
                  }`}
            </Typography>
            {!isTitle && (
              <Box
                sx={{
                  width: "100%",

                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="caption"
                  color="initial"
                  fontSize={12}
                  fontWeight={600}
                >
                  Total Amount :
                  <Typography
                    variant="caption"
                    color="rgba(21, 224, 29, 1)"
                    fontSize={12}
                    fontWeight={600}
                    mx={1}
                  >
                    {SkPrice(
                      isMemberData?.data?.dueAmount ||
                        isMemberData?.data?.currentPlanId?.dueAmount ||
                        0
                    )}
                  </Typography>
                </Typography>{" "}
                <Typography
                  variant="caption"
                  color="initial"
                  fontSize={12}
                  fontWeight={600}
                >
                  Due Amount :
                  <Typography
                    variant="caption"
                    color="rgba(250, 74, 12, 1)"
                    fontSize={12}
                    fontWeight={600}
                    mx={1}
                  >
                    {SkPrice(
                      calculatePrice(
                        isMemberData?.data?.dueAmount ||
                          isMemberData?.data?.currentPlanId?.dueAmount,
                        values.otp
                      )
                    )}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>

          <Button
            type="submit"
            // // onClick={() => setClick(false)}
            // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
            variant="contained"
            sx={{
              width: "85%",
              textTransform: "capitalize",
              mt: 2,
              height: "2.8rem",
              borderRadius: 10,
              bgcolor: "primary.main",
              color: "white.main",
              boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
              "&:hover": {
                bgcolor: "primary.main",
                color: "white.main",
              },
            }}
            disabled={
              isSubmitting ||
              values.otp >=
                (isMemberData?.data?.dueAmount ||
                  isMemberData?.data?.currentPlanId?.dueAmount) ===
                0
                ? true
                : values.otp >
                  (isMemberData?.data?.dueAmount ||
                    isMemberData?.data?.currentPlanId?.dueAmount)
            }
          >
            {isSubmitting ? "Submitting....." : "Submit"}
          </Button>
        </Box>
      )}
    </Formik>
  );
};

export default AddAmountHolidays;
