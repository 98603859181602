import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Button, Divider, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SkConfirm({
  dialogTitle,
  dialogContent,
  dialogAction,
  open,
  setOpen,
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      // onClose={setOpen}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight={700}>
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <Divider
        sx={{
          borderWidth: 0.5,
          borderColor: "primary.main",
          width: "100%",
        }}
      />
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ textTransform: "capitalize" }}
          >
            {dialogContent}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            sx={{ textTransform: "capitalize" }}
          >
            this {dialogTitle} is permanently deleted !
          </Typography>
        </DialogContentText>
      </DialogContent>
      {/* <DialogTitle></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
      </DialogContent> */}
      <DialogActions>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            color="primary"
            sx={{ width: "50%", textTransform: "capitalize" }}
            onClick={setOpen}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={{ width: "50%", textTransform: "capitalize" }}
            color="info"
            onClick={dialogAction}
          >
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
