import * as React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 14.5,
    top: 13.5,
    border: `2px solid ${theme.palette.background.paper}`,
    color: "#fff",
    height: 20.5,
    width: 20.5,
    borderRadius: "50%",
    fontSize: 10,
  },
}));

export default function index({ children, badgeContent }) {
  return (
    // <IconButton aria-label="cart">
    <StyledBadge
      badgeContent={badgeContent}
      color="primary"
      style={{ color: "#fff" }}
    >
      {children}
    </StyledBadge>
    // </IconButton>
  );
}
