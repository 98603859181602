// projectsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    project: [],
    isImage: null,
  },
  reducers: {
    addProject: (state, action) => {
      state.project.push(action.payload);
    },
    handleSetItems: (state, action) => {
      state.isImage = action.payload;
    },
    // Add more reducers for managing projects...
  },
});

export const { addProject, handleSetItems } = projectsSlice.actions;
export default projectsSlice.reducer;
