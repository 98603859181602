import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
// import { ConstructionOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreatePlanMutation,
  useUpdatePlanMutation,
} from "../../Apis/reduxRTK/planApi";
import { handleAlert } from "../../Apis/features/alertSlice";
import { handlePlanModel } from "../../Apis/features/planSlice";
import Heading from "../../examples/Heading";

const NewPlan = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { plan, title } = useSelector((state) => state.isPlan);
  const { singleRestaurant } = useSelector((state) => state.isRestaurant);
  const dispatch = useDispatch();

  const [createPlans] = useCreatePlanMutation();
  const [updatePlans] = useUpdatePlanMutation();

  const handleSubmitMobile = async (values) => {
    // console.log(values);
    setIsSubmitting(true);
    if (title) {
      // update
      try {
        const res = await updatePlans({
          data: values,
          planId: plan?._id,
          restroId: plan?.restaurantId,
        });
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(handlePlanModel({ show: false }));
        }
        if (res?.error) {
          setApiError(res?.error?.data?.message);
          setTimeout(() => {
            setApiError(null);
          }, 5000);
        } else {
          setApiError(null);
        }
        // console.log(res, "update");
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    } else {
      // create

      try {
        const res = await createPlans({
          data: values,
          adminId: localStorage.getItem("authId"),
          restroId: singleRestaurant?._id,
        });
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: res?.data?.success ? "success" : "error",
            msg: res?.data?.message,
          })
        );
        if (res?.data?.success) {
          dispatch(handlePlanModel({ show: false }));
        }
        // console.log(res, "create");
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: "something went wrong !",
          })
        );
      }
    }
  };
  const isMobleSchema = yup.object().shape({
    planName: yup
      .string()
      .min(2, "Too Short!")
      .max(256, "Too Long!")
      .required(" plan Name is required"),
    expiry: yup
      .string()
      .matches(
        /^[1-9]\d{0,2}(?:,\d{1,3})?$/,
        "Plan expiry days must be under 999 days"
      )
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special characters"
      )
      .required("Plan Expiry days is required"),
    thaliCount: yup
      .string()
      .matches(
        /^[1-9]\d{0,2}(?:,\d{1,3})?$/,
        "Total thali must be under 999 thali"
      )
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special characters"
      )
      .required("Plan Total thali is required"),
    amount: yup
      .string()
      .matches(/^[1-9]\d{0,4}(?:,\d{1,5})?$/, "Amount must be under ₹ 99,999")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "* This field cannot contain white space and special characters"
      )
      .required("Plan Total Amount is required"),
  });
  const initialValues = {
    planName: title ? plan?.planName : "",
    expiry: title ? plan?.expiry : "",
    thaliCount: title ? plan?.thaliCount : "",
    amount: title ? plan?.amount : "",
  };
  return (
    <>
      <Formik
        onSubmit={handleSubmitMobile}
        initialValues={initialValues}
        validationSchema={isMobleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              color: "primary.main",
              //   p: 2.5,
              gap: 2,
              overflowY: "scroll",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Heading
              text={title ? "Update Plan" : "Add Plan"}
              color="primary.main"
              fontSize={"1.5rem"}
            />
            <Box
              sx={{
                width: "95%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { color: "#000" },
                }}
                type={"text"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.planName}
                name="planName"
                error={(!!touched.planName && !!errors.planName) || !!apiError}
                helperText={(touched.planName && errors.planName) || apiError}
                label="Plan Name"
                sx={{
                  //   "& .MuiFormHelperText-root.Mui-error": {
                  //     color: "primary.main",
                  //   },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "95%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                InputLabelProps={{
                  style: { color: "#000" },
                }}
                fullWidth
                type={"number"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.thaliCount}
                name="thaliCount"
                error={
                  (!!touched.thaliCount && !!errors.thaliCount) || !!apiError
                }
                helperText={
                  (touched.thaliCount && errors.thaliCount) || apiError
                }
                label="Total number’s of thali"
                sx={{
                  //   "& .MuiFormHelperText-root.Mui-error": {
                  //     color: "primary.main",
                  //   },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
              />
            </Box>
            <Box
              sx={{
                width: "95%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { color: "#000" },
                }}
                type={"number"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expiry}
                name="expiry"
                error={(!!touched.expiry && !!errors.expiry) || !!apiError}
                helperText={(touched.expiry && errors.expiry) || apiError}
                label="Expiry days "
                sx={{
                  //   "& .MuiFormHelperText-root.Mui-error": {
                  //     color: "primary.main",
                  //   },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                // onInput={(e) => {
                //   e.target.value = Math.max(0, parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 10);
                // }}
              />
            </Box>
            <Box
              sx={{
                width: "95%",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                size="small"
                InputLabelProps={{
                  style: { color: "#000" },
                }}
                fullWidth
                type={"number"}
                required
                autoComplete="off"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={(!!touched.amount && !!errors.amount) || !!apiError}
                helperText={(touched.amount && errors.amount) || apiError}
                label="Plan Amount "
                sx={{
                  //   "& .MuiFormHelperText-root.Mui-error": {
                  //     color: "primary.main",
                  //   },
                  "& .MuiOutlinedInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    //   height: 40,
                    border: "none",
                    outline: "none",
                    borderColor: "GrayText",
                    bgcolor: "rgba(221, 218, 218, 0.15)",
                    color: "black.main",
                  },
                  "& .MuiFormHelperText-root": {
                    mx: 0,
                  },
                  // "& .MuiOutlinedInput-notchedOutline": {
                  //   outline: "none",
                  //   border: "none",
                  // },
                }}
                InputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>

            <Button
              type="submit"
              // // onClick={() => setClick(false)}
              // disabled={!!touched.phoneNumber && !!errors.phoneNumber}
              variant="contained"
              sx={{
                width: "95%",
                textTransform: "capitalize",
                mt: 2,
                height: "2.8rem",
                borderRadius: 10,
                bgcolor: "primary.main",
                color: "white.main",
                boxShadow: "3px 3px 5px 1px rgba(0, 0, 0, 0.25)",
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white.main",
                },
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting....." : "Submit"}
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default NewPlan;
