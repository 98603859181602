import { createSlice } from "@reduxjs/toolkit";
import http from "../../extras/Api";
import axios from "axios";

export const getSinglePlanById = async ({ id, dispatch }) => {
  try {
    const res = await http.get(`/getPlan/${id}`);
    const data = res?.data?.data;
    dispatch(handlePlanModel({ data: data }));
  } catch (error) {
    console.log(error, "planByID");
  }
};
export const getMobileDetails = async ({ id, dispatch, phone }) => {
  dispatch(getLoading(true));
};
const planSlice = createSlice({
  name: "plan",
  initialState: {
    Loading: false,
    isPlanShow: false,
    isPlanDelete: false,
    planId: false,
    title: false,
    plans: null,
    plan: null,
    isPlanData: null,
    isPhoneData: null,
    isMemberPlanData: null,
    isRenewalPlan: false,
  },
  reducers: {
    handlePlanModel: (state, action) => {
      state.isPlanShow = action.payload.show;
      state.title = action.payload.title;
      state.isPlanDelete = action.payload.delete;
      state.planId = action.payload.planId;
      state.isPlanData = action.payload.data;
      state.isPhoneData = action.payload.phoneData;
    },
    getSinglePlan: (state, action) => {
      state.plan = action.payload;
    },
    getLoading: (state, action) => {
      state.Loading = action.payload;
    },
    removeData: (state, action) => {
      state.isPlanData = action.payload;
    },
    handleRenewalPlanModel: (state, action) => {
      state.isRenewalPlan = action.payload;
    },
    handleIsMemberPlanData: (state, action) => {
      state.isMemberPlanData = action.payload;
    },
  },
});

export const {
  handlePlanModel,
  getSinglePlan,
  getLoading,
  removeData,
  handleRenewalPlanModel,
  handleIsMemberPlanData,
} = planSlice.actions;
export default planSlice.reducer;
