import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { ChevronRight } from "@mui/icons-material";
import { LogoutIcon } from "../assets/SVG";
import { handleModalOpenLogout } from "../Apis/features/LoginSlice";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const isMobile2 = useMediaQuery("(max-width:1100px)");
  const location = useLocation();
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.isCompany);
  // console.log(location.pathname )
  const renderRoutes =
    routes &&
    routes
      ?.at(2)
      ?.children?.slice(0, 6)
      ?.map(({ name, icon, key, path, icon2, isSwitch }) => {
        let returnValue;

        returnValue = (
          <NavLink key={key} to={path || "/empty"}>
            <SideNavTabs
              name={name}
              icon={icon}
              icon2={icon2}
              active={
                path === location.pathname?.split("/")[1] ||
                (location.pathname.endsWith("/") && path === "/")
              }
              isSwitch={isSwitch}
            />
          </NavLink>
        );

        return returnValue;
      });

  // console.log(routes?.at(1)?.children);
  return (
    <>
      <Card
        sx={{
          width: isMobile2 ? "auto" : 260,
          height: "100vh",
          p: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1.5,
          flexDirection: "column",
          position: "sticky",
          top: 0,
          color: "info.main",
        }}
        elevation={6}
      >
        <Box px={isMobile2 ? "auto" : 5} textAlign="center">
          <Box
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              component="img"
              src={
                company?.footer_logo?.startsWith("https")
                  ? company?.footer_logo
                  : `${process.env.REACT_APP_URI}/${company?.footer_logo}`
              }
              alt="Brand"
              height="3.8rem"
              borderRadius="50%"
              onError={(e) => {
                e.onError = null;
                e.target.src = require("../assets/images/LOGO APNA THALI 2 conflict-02 1.png");
              }}
            />

            {/* <Box width={"100%"} mt={1}>
              <Typography
                component="h5"
                fontWeight={800}
                color={"primary.main"}
              >
                {`Apna Thali`}
              </Typography>
            </Box> */}
          </Box>
        </Box>
        <Divider width="100%" />
        {isMobile2 ? (
          <List
            p={1}
            width="100%"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1.5,
              flexDirection: "column",
            }}
          >
            {renderRoutes}
          </List>
        ) : (
          <List p={1} width="100%">
            {renderRoutes}
          </List>
        )}
        <Box p={1} mt="auto"></Box>
        <Box
          sx={{ cursor: "pointer", p: 1, width: "100%" }}
          onClick={() => {
            dispatch(handleModalOpenLogout(true));
          }}
        >
          <SideNavTabs
            name={"Logout"}
            icon={LogoutIcon}
            icon2={""}
            active={true}
          />
        </Box>
      </Card>
    </>
  );
};

export default Sidebar;

const SideNavTabs = ({ icon, name, active, icon2, isSwitch }) => {
  const isMobile2 = useMediaQuery("(max-width:1100px)");
  const [hoverActive, setHoverActive] = useState(false);
  return isMobile2 ? (
    <Box
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
      sx={{
        p: 1.2,
        color: active ? "white.main" : "info.main",
        bgcolor: active ? "primary.main" : "white.main",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        transition: active && "background-color 0.3s, color 0.3s",
        "&:hover": {
          color: "white.main",
          borderRadius: "50%",
          bgcolor: "primary.main",
        },
      }}
    >
      {active ? icon : hoverActive ? icon : icon2}
    </Box>
  ) : (
    <ListItem
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
      width={"100%"}
      sx={{
        color: active ? "white.main" : "info.main",
        bgcolor: active ? "primary.main" : "white.main",
        borderRadius: 6,
        mb: 1,
        cursor: "pointer",
        transition: active && "background-color 0.3s, color 0.3s",
        "&:hover": {
          color: "white.main",
          borderRadius: 6,
          bgcolor: "primary.main",
        },
      }}
    >
      <ListItemIcon>{active ? icon : hoverActive ? icon : icon2}</ListItemIcon>
      <ListItemText
        sx={{
          color: active ? "white.main" : "inherit",
        }}
        primary={name}
      />
      <Box sx={{ p: 2 }} />
      {isSwitch ? (
        <Switch
          size="small"
          sx={{
            color: !active ? "white.main" : "inherit",

            "&:hover": {
              color: "white.main",
            },
          }}
        />
      ) : name !== "Logout" ? (
        <ChevronRight
          mr={2}
          sx={{
            color: active ? "white.main" : "inherit",

            "&:hover": {
              color: "white.main",
            },
          }}
        />
      ) : null}
    </ListItem>
  );
};
