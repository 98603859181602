import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { PickersDay } from "@mui/x-date-pickers";
import { Badge, Paper } from "@mui/material";

const Calendar = ({ data }) => {
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const matchingDate = data?.find((dateObj) =>
      day.isSame(dayjs(dateObj.date), "day")
    );

    let backgroundColor;

    if (matchingDate) {
      if (matchingDate.count === 1) {
        backgroundColor = "#FFB297";
      } else if (matchingDate.count === 2) {
        backgroundColor = "#ff6d5f";
      }
    }

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        className="MuiButtonBase-root-MuiPickersDay-root.Mui-selected"
        key={props.day.toString()}
        overlap="circular"
        // badgeContent={isSelected ? "🌚" : undefined}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: "100%",
          height: "36px",
          width: "36px",
          margin: "0 2px",
        }}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  const [highlightedDays, setHighlightDays] = useState([]);
  useEffect(() => {
    if (data) {
      const isdate = data?.map((date) => new Date(date?.date).getUTCDate());
      setHighlightDays(isdate);
    }
  }, [data]);
  // console.log(highlightedDays);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} component={Paper}>
      <DateCalendar
        showDaysOutsideCurrentMonth
        fixedWeekNumber={5}
        readOnly
        disableHighlightToday
        disableRipple
        disabled
        sx={{
          "& .MuiButtonBase-root": {
            // color: "black.main",
          },
          "& .MuiPickersSlideTransition-root": {
            minHeight: 230,
            // color: "black.main",
          },
          "& .css-1q9zrw7-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled:not(.Mui-selected)":
            {
              color: "#232323",
              // color: "black.main",
            },
        }}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;
