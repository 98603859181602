import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import RestaurantCard from "../../components/Cards/RestaurantCard";
import { useGetRestaurantByAdminIdQuery } from "../../Apis/reduxRTK/restaurantApi";
import SkLoading from "../../components/SkError/SkLoading";
import BasicModal from "../../components/Modal";
import NewRestaurant from "./NewRestaurant";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleRestaurant,
  handleRestaurantModel,
} from "../../Apis/features/restaurantSlice";

const Restaurant = () => {
  const { isRestaurantShow } = useSelector((state) => state.isRestaurant);
  const dispatch = useDispatch();
  const {
    data: restaurant,
    isLoading,
    isSuccess,
    error,
  } = useGetRestaurantByAdminIdQuery(localStorage.getItem("authId"));
  // console.log(restaurant);
  // console.log(typeof []);
  if (restaurant?.data && isSuccess) {
    dispatch(getSingleRestaurant(restaurant?.data));
  }
  if (error) {
    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="primary" fontWeight={600}>
            {" "}
            something went wrong
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (isLoading) {
    return (
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          width: "100%",
          minHeight: "80vh",
        }}
      >
        <SkLoading />
      </Paper>
    );
  }
  return (
    isSuccess && (
      <>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: 3,
            p: 2,
            width: "100%",
            minHeight: "80vh",
          }}
        >
          <NewRestaurant />
          {/* {restaurant?.data &&
          restaurant?.data?.at(0) &&
          restaurant?.data?.length > 0 ? (
            restaurant?.data?.map((value, i) => <RestaurantCard />)
          ) : (
            <Box
              sx={{
                width: "100%",
                p: 1,
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../assets/images/restaurantNotFound.png")}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="..."
              />
            </Box>
          )} */}

          {/* {restaurant && restaurant?.data ? (
            <RestaurantCard value={restaurant?.data} />
          ) : (
            <Box
              sx={{
                width: "100%",
                p: 1,
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../assets/images/restaurantNotFound.png")}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="..."
              />
            </Box>
          )} */}
          <BasicModal
            show={isRestaurantShow}
            unShow={() => dispatch(handleRestaurantModel({ show: false }))}
            width={500}
            height={"auto"}
            overflowY={true}
            // padding={4}
          >
            <NewRestaurant />
          </BasicModal>
        </Paper>
      </>
    )
  );
};

export default Restaurant;
