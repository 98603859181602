import {
  Box,
  Card,
  CardActions,
  Typography,
  IconButton,
  CardContent,
  Divider,
} from "@mui/material";
import React from "react";
import { DeleteIcon, EditIcon } from "../../assets/SVG";
import { SkPrice } from "../../extras/dataFunction";
import { getSinglePlan, handlePlanModel } from "../../Apis/features/planSlice";
import { useDispatch } from "react-redux";

const PlanCard = ({ value }) => {
  const dispatch = useDispatch();
  return (
    <Card
      sx={{
        width: "49%",
        borderRadius: 3,
        border: 0.2,
        borderColor: "gray.gray2",

        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          backgroundColor: "primary.main",
          color: "white.main",
          px: 1.5,
          py: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            maxWidth: "70%",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value?.planName}
        </Typography>
        <CardActions style={{ padding: 0 }}>
          <IconButton
            aria-label="edit-plan"
            onClick={() => {
              dispatch(handlePlanModel({ show: true, title: true }));
              dispatch(getSinglePlan(value));
              //   alert("...");
            }}
          >
            {EditIcon}
          </IconButton>
          <IconButton
            aria-label="delete-plan"
            onClick={() => {
              dispatch(handlePlanModel({ delete: true, planId: value?._id }));
            }}
          >
            {DeleteIcon}
          </IconButton>
        </CardActions>
      </Box>
      <CardContent
        sx={{
          width: "100%",
          p: 0,
          px: 1.5,
          pt: 1,

          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 1,
          "&:last-child": { pb: 1 },
        }}
      >
        <Typography variant="h6" fontWeight={600} color="primary">
          Plan Thali’s - {value?.thaliCount}
        </Typography>
        <Typography variant="body1" fontWeight={500} color="initial">
          Plan Amount - {SkPrice(value?.amount)}
        </Typography>
        <Divider sx={{ width: "100%", mt: 1 }} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" fontWeight={400} color="initial">
            Plan Day's
          </Typography>
          <Typography variant="body2" fontWeight={400} color="initial">
            {value?.expiry} / day’s
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlanCard;
